import React, { useState, useEffect, useCallback } from "react";

const AuthContext = React.createContext({
    token: "",
    isLoggedIn: false,
    isAdmin: false,
    login: (token) => {}, 
    logout: () => {},
    parse: (token) => {},
});

let logoutTimer;
let initialToken;
let initialRoleToken;

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();
    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

const retrieveStoredToken = () => {
    const storedToken = localStorage.getItem("brakorn-token");
    const storedExpirationDate = localStorage.getItem("expiration-time");

    let remainingTime = localStorage.getItem("expiration-time") ? calculateRemainingTime(storedExpirationDate) : 0;

    if (remainingTime <= 3600) {
        localStorage.removeItem("brakorn-token");
        localStorage.removeItem("expiration-time");

        return null;
    } else {
        return {
            token: storedToken,
            duration: remainingTime
        };
    }
}; 

export const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem("brakorn-token"));
    const tokenData = retrieveStoredToken();

    if (tokenData) {
        initialToken = tokenData.token;
        const roleCheck = parseJwt(initialToken);
        initialRoleToken = roleCheck.authorities[0].authority;
    }

    const userIsLoggedIn = !!token && tokenData?.duration > 0;
    const adminAccess = !!initialRoleToken && initialRoleToken === "ROLE_ADMIN";
    
    const logoutHandler = useCallback(() => {
        setToken(null);

        localStorage.removeItem("brakorn-token");
        localStorage.removeItem("expiration-time");
        initialRoleToken = "";
        initialToken = "";

        if (logoutTimer) {
            clearTimeout(logoutTimer);
        }
    }, []);

    const loginHandler = (token) => {
        const tokenData = parseJwt(token);
        const tokenUnixTimestamp = tokenData.exp;
        const tokenExpTime = new Date(tokenUnixTimestamp * 1e3); 

        setToken(token);

        localStorage.setItem("brakorn-token", token);
        localStorage.setItem("expiration-time", tokenExpTime);

        const remainingTime = calculateRemainingTime(tokenExpTime);
        logoutTimer = setTimeout(logoutHandler, remainingTime);
    };

    useEffect(() => {
        if (tokenData) {
            logoutTimer = setTimeout(logoutHandler, tokenData.duration);
        }
    }, [tokenData, logoutHandler]);

    const contextValue = {
        isAdmin: adminAccess,
        token: userIsLoggedIn ? token : null,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logout: logoutHandler,
        parse: parseJwt
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
