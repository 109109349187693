import React from "react";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";

const CustomerCredentials = (props) => {
    const tokenData = getUserToken()

    const { data: credentials, loading, error} = useFetch(`customers/${props.user.username}/credentials`, {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    return (<>
        {loading && 
            <tr className="table__row table__row--inputs">
                <td></td>
                <td><span>Henter brukerdata ...</span></td>
                <td colSpan={4}></td>
            </tr>}
            
        {error && 
            <tr className="table__row table__row--inputs">
                <td></td>
                <td><span>{"Kunne ikke hente data for kunder, vennligst last inn siden på nytt."}</span></td>
                <td colSpan={4}></td>
            </tr>}

        {(!loading && !error) && credentials ? 
            <tr className="table__row table__row--inputs">
                <td></td>
                <td>
                    <span style={{marginRight: "14px"}}>Brukernavn: <span className="bold">{props.user.producerNr}</span></span>
                    <span>Passord: <span className="bold">{credentials}</span></span>
                </td>
                <td colSpan={4}></td>
            </tr>: ""}
    </>);
};

export default CustomerCredentials;
