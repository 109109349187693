import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { useState, useEffect} from "react";
import { padWithZero } from "../components/access/admin/manage/appointments/CreateAppointmentsHelper";
import LeafLoader from "./LoadingSpinner";
import { findGrainTypeName, findVarietyName } from "../components/access/user/UserService";
import { groupAllAppointments } from "./GroupAppointments";
import { formatDate, formatTime, formatDay  } from "./DateFormatOptions";
import { Feedback } from "./Feedback";
import Button from "../components/UI/Button";

const ExportUserAppointments = ({data, user, grains, onHide}) => {
    const [userData, setUserData] = useState([]);
    const [filter, setFilter] = useState(""); 

    const fileName = `Timeliste_${user}_`;

    let validData = false;
    let appointmentArray = [];

    useEffect(() => {
        if (data.length > 0) {
            const groupAppointments = groupAllAppointments(data);
            const customerFirstAppointment = groupAppointments.slice(0).sort((b,a)=> a.day.localeCompare(b.day));

            setFilter(customerFirstAppointment[0].day)
            setUserData(customerFirstAppointment);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const changeFilterHandler = (event) => {
        event.persist();
        const value = event.target.value;
        
        if (value) {
            setFilter(value);
        } 
    };

    if (data && userData.length > 0 && filter) validData = true;

    if (userData.length > 0 && filter) { 
        let sortedByFilter;
        
        if (filter !== "allAppointments") {
            sortedByFilter = userData.filter((day) => { 
                return day.day === filter
            }).map((a) => a.appointments).flatMap((app) => app);
    
        } else {
            sortedByFilter = userData.map((a) => a.appointments).flatMap((app) => app);
        }

        const customExport = sortedByFilter.map((item) => ([{
            "scheduledAt": `${filter === "allAppointments" ? formatDate(item.scheduledAt) + " - " : ""} ${formatTime(item.scheduledAt, {hour: '2-digit', minute: '2-digit'})}`,
            "warehouseRental": item.warehouseRental ? "Ja" : "",
            "amount": item.amount ? item.amount : "",
            "grainTypeId": item.grainTypeId ? findGrainTypeName(grains, item.grainTypeId) : "",
            "varietyTypeId": item.varietyTypeId ? findVarietyName(grains, item.grainTypeId, item.varietyTypeId) : "",
            "comment": item.comment ? "'"+ item.comment +"'" : "",
        }])).flatMap((app) => app)

        appointmentArray = customExport;
    }
    
    return (<>
        <div className="modal__form">
            <div className="modal__form__header">
                <h1>Eksporter timedata </h1>
            </div>

            <div className="modal__form__content">   
               {validData ? <>
                    <h2>Timer for {user}</h2>
                    <p className="modal__form__content--desc">Om du ikke velger en dag ifra listen nedenfor så vil den første dagen i listen automatisk være valgt.</p>

                    <div className="modal__form__item modal__form__item--xl modal__form__item--topped">    
                        <label htmlFor="userAppointmentDay">Velg dag</label>   
                        <div> 
                            <select id="userAppointmentDay" name="userAppointmentDay" onChange={changeFilterHandler} className="form__item__step--select">
                                {userData.map((date, index) => (
                                    <option key={index} value={date.day}>{formatDate(date.day)} - {formatDay(date.day, {weekday: 'long'})}  ({date.appointments.length} {date.appointments.length === 1 ? "time" : "timer"})</option>
                                ))}
                                <option value={"allAppointments"}>Velg alle dager ({data.length} {data.length === 1 ? "time" : "timer"})</option>
                            </select>  
                        </div> 
                    </div> 

                    <div className="modal__form__feedback">
                        <Export className="confirm button__mr" disabled={appointmentArray.length < 1} type="button" apiData={appointmentArray} 
                            fileName={fileName} username={user} appointments={appointmentArray} date={filter === "allAppointments" ? "" : new Date(filter).toLocaleDateString()}/>
                        <Button className="plain" type="button" title="Avbryt utskrift" onClick={onHide}>Avbryt</Button> 
                    </div>
                </> : <></>}
                {!validData && <div className="topped"><LeafLoader loadingMessage="Laster inn timedata ..."/></div>}
                {userData.length === 0 && <div className="modal__form__content--centered topped"><Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto et problem ved henting av data, vennligst last inn siden på nytt."}/></div>}
            </div>
        </div>
    </>);
};

export default ExportUserAppointments;

const Export = ({ disabled, handleClick, apiData, fileName, username, appointments, date}) => {
    const todaysAppointments = parseInt(appointments.length);
    
    const createDownloadData = () => {
        const todaysTime = padWithZero(new Date().getHours()) + "" + padWithZero(new Date().getMinutes());
        const fullFilename = fileName + `eksportert_kl-${todaysTime}`;

        handleExport().then((url) => {
            const fileExtension = ".xlsx";
            const downloadAnchorNode = document.createElement("a");

            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute("download", fullFilename + fileExtension);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    };

    const fileObject = (filedata) => {
        const fileSpecs = { bookType: "xlsx", bookSST: false, type: "binary"};
        const wbout = XLSX.write(filedata, fileSpecs);
        const blob = new Blob([createArrayBufferObjects(wbout)], {
            type: "application/octet-stream",
        });

        return blob;
    };

    const createArrayBufferObjects = (size) => {
        const buffer = new ArrayBuffer(size.length);

        const view = new Uint8Array(buffer);

        for (let i = 0; i !== size.length; ++i) {
            view[i] = size.charCodeAt(i);
        }

        return buffer;
    };

    const handleExport = () => {
        const tableTitle = [{ A: "TIMELISTE" }, {}];

        const tableSubHeading = [{ A: `Timeliste til ${username}${date ? " - gjelder " + date : ""}. Antall timer i listen: ${todaysAppointments} stk`}, {}];

        const tableHeading = [{
            A: "Leveringstid",
            B: "Lagerleie",
            C: "Tonn",
            D: "Kornart",
            E: "Kornsort",
            F: "Kommentar"
        }];

        const tableData = apiData.map((data) => {
            return {
                A: data.scheduledAt,
                B: data.warehouseRental,
                C: data.amount,
                D: data.grainTypeId,
                E: data.varietyTypeId,
                F: data.comment,
            };
        });

        const finalData = [...tableTitle, ...tableSubHeading, ...tableHeading, ...tableData,];

        const wb = XLSX.utils.book_new();

        const sheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });

        XLSX.utils.book_append_sheet(wb, sheet, "Timelevering");

        const workbookBlob = fileObject(wb);

        let headerIndexes = [];

        finalData.forEach((apiData, index) =>
            apiData["A"] === "Leveringstid" ? headerIndexes.push(index) : null
        );

        const totalRecords = apiData.length;

        const tableConfig = {
            titleRange: "A1:F2",
            subHeaderRange: "A3:F3",
            tbodyRange: `A6:F${finalData.length}`,
            theadRange:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
                    : null,
            tLastColumnRange:
                headerIndexes?.length >= 1
                    ? `F6:F${
                            totalRecords + headerIndexes[0] + 1
                        }`
                    : null,
            timeColumn:
                headerIndexes?.length >= 1
                    ? `A6:A${
                        totalRecords + headerIndexes[0] + 1
                    }`
                    : null,
            firstColumnHeader:
                headerIndexes?.length >= 1
                    ? `A5:A5`
                    : null
        };

        return addStyle(workbookBlob, tableConfig, totalRecords);
    };

    const addStyle = (workbookBlob, tableConfig, totalRecords) => {
        return XlsxPopulate.fromDataAsync(workbookBlob).then((filedata) => {
            filedata.sheets().forEach((sheet) => {
                sheet.usedRange().style({
                    fontFamily: "Arial",
                    verticalAlignment: "center",
                });

                sheet.column("A").width(16);
                sheet.column("B").width(9);
                sheet.column("C").width(5);
                sheet.column("D").width(9);
                sheet.column("E").width(11);
                sheet.column("F").width(22);

                sheet.row(1).height(28);
                sheet.row(3).height(26);
                sheet.row(4).height(20);
                sheet.row(5).height(26);

                for (let i = 6; i < totalRecords + 6; i++) {
                    sheet.row(i).height(24); 
                }

                sheet.range(tableConfig.titleRange).merged(true).style({
                    bold: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 18,
                    wrapText: true,
                });

                sheet.range(tableConfig.subHeaderRange).merged(true).style({
                    italic: true,
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                    fontSize: 12,
                    wrapText: true,
                });

                sheet.range(tableConfig.theadRange).style({
                    fill: "f2c139",
                    bold: true,
                    fontSize: 10,
                    borderStyle: { right: "medium"},
                    borderColor: "f2c139",
                    horizontalAlignment: "center",
                    wrapText: true,
                });

                if (tableConfig.tbodyRange) {
                    sheet.range(tableConfig.tbodyRange).style({
                        fontColor: "000000",
                        bold: false,
                        italic: false,
                        horizontalAlignment: "center",
                        borderStyle: { right: "dotted", bottom: "thin"},
                        borderColor: "3b454e",
                        fontSize: 10,
                        wrapText: true,
                    });
                }

                if (tableConfig.tLastColumnRange) {
                    sheet.range(tableConfig.tLastColumnRange).style({
                        italic: true,
                        wrapText: true,
                    });
                }

                if (tableConfig.timeColumn) {
                    sheet.range(tableConfig.timeColumn).style({
                        bold: true,
                        fontSize: 10,
                        fill: "f9f6b6",
                        borderStyle: { bottom: "thin", left: "thin"},
                        borderColor: "3b454e",
                        wrapText: true,
                    });
                }

                if (tableConfig.firstColumnHeader) {
                    sheet.range(tableConfig.firstColumnHeader).style({
                        borderStyle: { left: "thin", right: "medium"},
                        leftBorderColor: "3b454e",
                        rightBorderColor: "f2c139",
                        wrapText: true,
                    });
                }
            });

            return filedata
                .outputAsync()
                .then((workbookBlob) => URL.createObjectURL(workbookBlob));
        });
    };

    return (
        <button className="confirm button__mr" title="Last ned timeliste"  
            disabled={disabled} type="button" onClick={() => createDownloadData() || {handleClick}} >
            Last ned timeliste
        </button>
    );
}; 