import useFetch from "../../../../hooks/useFetch";
import { getUserToken } from "../../../../data/token";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Legend, Label, ResponsiveContainer} from "recharts";
import { capitalizeStr } from "../../../../assets/Capitalize";

function HourlySummary ({appointments}) {
    const tokenData = getUserToken();
    
    const { data: grains, grainLoading, grainError} = useFetch("grains", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`, }
    });

    let displayChart = false;
    let hoursInADay = [];

    if (appointments?.length > 0 && grains && !grainLoading && !grainError) {
        for (let i = 0; i < 24; i++) {
            hoursInADay[i] = {
                hour: i,
            };
    
            grains.forEach((grain) => hoursInADay[i][capitalizeStr(grain.name)] = 0);
        }

        const lookup = new Map();
        grains.forEach((grain) => lookup.set(grain.id, capitalizeStr(grain.name)));

        appointments
            .filter((d) => d.reservedBy)
            .forEach(appointment => {
                const hour = new Date(appointment.scheduledAt).getHours();
                hoursInADay[hour][lookup.get(appointment.grainTypeId)] += appointment.amount; // <- map
            });

        displayChart = true;
    }

    const getTotalTonnage = (array) => {
        return array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    const timeIncrement = (time) => {
        return time += 1;
    }
    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && getTotalTonnage(payload.map((a) => a.value)) > 0) {
            return (
                <div className="stacked__bar__tooltip">
                    <p className="stacked__bar__tooltip__label">Fra {label}:00 - {timeIncrement(label)}:00 </p>
                    <div className="stacked__bar__tooltip__content">
                        <ul>
                            {payload.map((a) => (
                                a.value > 0 && <li key={a.name}>{a.name}: {a.value} tonn</li>
                            ))}
                        </ul>
                    </div>
                    <p className="stacked__bar__tooltip__footer">Totalt: {getTotalTonnage(payload.map((a) => a.value))} tonn</p>
                </div>
            );
        }
    
        return null;
    };

    const renderLabel = (value) => {
        return value !== 0 ? value : "";
    };
    
    return ( <>
        {displayChart && <>
            <div className="graph">
                <h4>Oversikt for kornlevering - time for time</h4>
                <div className="graph--wrapper diagram__backdrop">
                    <div style={{ width: '100%', height: 420 }}>
                        <ResponsiveContainer>
                            <BarChart width={1400} data={hoursInADay} margin={{ top: 15, right: 0, left: 0, bottom: 5}} padding={{top: 20}}>
                                <CartesianGrid strokeDasharray="2 1" opacity={0.4} />
                                <Tooltip cursor={{fill: '#ebebeb85'}} content={<CustomTooltip />}/>
                                <Legend iconType={"square"} verticalAlign="top" height={45}/>
                                
                                <Bar dataKey="Bygg" stackId="a" fill="#f29e0d" barSize={50}>
                                    <LabelList dataKey="Bygg" fill="#3b454e" formatter={renderLabel} />
                                </Bar>
                                <Bar dataKey="Havre" stackId="a" fill="#5da7ae" barSize={50}>
                                    <LabelList dataKey="Havre" fill="#3b454e" formatter={renderLabel} />
                                </Bar>
                                <Bar dataKey="Hvete" stackId="a" fill="#1a2634" barSize={50}>
                                    <LabelList dataKey="Hvete" fill="#3b454e" formatter={renderLabel} />
                                </Bar>
                                <Bar dataKey="Rug" stackId="a" fill="#ca6144" barSize={50}>
                                    <LabelList dataKey="Rug" fill="#3b454e" formatter={renderLabel}  />
                                </Bar>
                                <XAxis dataKey="hour" interval={0} stroke={"#3b454e"} height={60} padding={{ top: 10 }}>
                                    <Label position={"insideBottom"} offset={8} style={{ fontSize: "13", fill: "#3b454e", fontWeight: "600"}} value={"Klokkeslett"} />
                                </XAxis>
                                <YAxis stroke={"#3b454e"} width={80}>
                                    <Label position={"insideLeft"} offset={25} angle={270} style={{ fontSize: "13", fill: "#3b454e", fontWeight: "600"}} value={"Tonn"} />
                                </YAxis>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </>}    
    </>);
}

export default HourlySummary;

