import { Fragment, useEffect } from "react";
import ReactDOM from "react-dom";

const Backdrop = props => {
    return <div className="backdrop" onClick={props.onHide} />;
};

const ModalOverlay = props => {
    return (
        <div className="login__modal">
            <div className="login__modal__content">{props.children}</div>
        </div>
    );
};

const ActionModalOverlay = props => {
    return (
        <div className="admin__modal">
            <div className="admin__modal__content">{props.children}</div>
        </div>
    );
};

const portalElement = document.getElementById("modalOverlay");

const Modal = (props) => {
    const handleEscape = event => { if (event.key === 'Escape') props.onHide();}

    const keyListenersMap = new Map([[27, handleEscape],]);

    const handleKeydown = event => {
        const listener = keyListenersMap.get(event.keyCode)

        return listener && listener(event);
    }
    useEffect(() => {   
        document.addEventListener('keydown', handleKeydown)
        return () => { document.removeEventListener('keydown', handleKeydown) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop onHide={props.onHide} />, portalElement)}
            {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>,portalElement)}
        </Fragment>
    );
};

export default Modal;

export const ActionModal = (props) => {
    const handleEscape = event => { if (event.key === 'Escape') props.onHide();}

    const keyListenersMap = new Map([[27, handleEscape],]);

    const handleKeydown = event => {
        const listener = keyListenersMap.get(event.keyCode)

        return listener && listener(event);
    }

    useEffect(() => {   
        document.addEventListener('keydown', handleKeydown)
        return () => { document.removeEventListener('keydown', handleKeydown) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            {ReactDOM.createPortal(<Backdrop onHide={props.onHide} />, portalElement)}
            {ReactDOM.createPortal(<ActionModalOverlay>{props.children}</ActionModalOverlay>,portalElement)}
        </Fragment>
    );
};