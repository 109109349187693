import { getTimeBetween } from "../../../../../assets/DateFormatOptions";

export const calculateRemainingTime = (date) => {
    const time = getTimeBetween(date);

    const day = Math.floor(time/(1000*60*60*24));
    const hour = Math.floor((time%(1000*60*60*24))/(1000*60*60));
    const minute = Math.floor((time%(1000*60*60))/(1000*60));
    const seconds = Math.floor((time % (1000*60))/1000); 

    if (day === 0 && hour === 0 && minute === 0 && seconds < 59) {
        return <h4 className="now">{minute === 1 ? minute + " minutt " : minute + " minutter "} og {seconds === 1 ? seconds + " sekund" : seconds + " sekunder"}</h4>
    } else if (day < 0 && hour < 0 && minute < 0 && seconds < 0) {
        return <h4>Timen er utgått.</h4>        
    } else if (day === 0 && hour === 0) {
        return <h4>{minute === 1 ? minute + " minutt" : minute + " minutter"} og {seconds === 1 ? seconds + " sekund" : seconds + " sekunder"}</h4>        
    } else if (day === 0) {
        return <h4>{hour === 1 ? hour + " time " : hour + " timer "} og {}
            {minute === 1 ? minute + " minutt" : minute + " minutter"}</h4>        
    } else {
        return <h4>{day === 1 ? day + " dag, " : day + " dager, "}{hour === 1 ? hour + " time, " : hour + " timer, "} og {}
            {minute === 1 ? minute + " minutt" : minute + " minutter"}</h4>
    }
}

export const calculateUpcomingClass = (date) => {
    const time = getTimeBetween(date);

    const day = Math.floor(time/(1000*60*60*24));
    const hour = Math.floor((time%(1000*60*60*24))/(1000*60*60));
    const minute = Math.floor((time%(1000*60*60))/(1000*60));
    const seconds = Math.floor((time % (1000*60))/1000);

    if (day < 0 && hour < 0 && minute <= 1 && seconds <= 59) {
        return "current__delivery"
    } else if (day < 0 && hour < 0 && minute <= 0 && seconds <= 0) {
        return "past__delivery"
    } else {
        return "upcoming__delivery"
    }
}
