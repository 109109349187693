import { useState, useEffect } from "react";
import { apiUrl } from "../../../../../data/API";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import { Feedback, TimeoutFeedback } from "../../../../../assets/Feedback";
import useConfirm from "../../../../../hooks/useConfirm";
import CreateTimespanTemplates from "./CreateTimespanTemplates";
import DayTemplatesPage from "./DayTemplatesPage";
import Pagination from "../../../../UI/Pagination";
import { padWithZero } from "../appointments/CreateAppointmentsHelper";
import { addWhiteSpace } from "./CreateTimespanTemplates";
import attentionIcon from "../../../../../assets/icons/error_FILL1_wght500_GRAD0_opsz40.svg";

export const formatTimeString = (time) => { return time.slice(0, 2) + ":" + time.slice(2,4); }
export const itemsPerPage = 6;

const TimespanTemplatesPage = () => {
    const [templateData, setTemplateData] = useState([]);
    const [actionError, setActionError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [onCreate, setOnCreate] = useState("");
    const [formError, setFormError] = useState("");
    const [deleteError, setDeleteError] = useState("");
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);

    const tokenData = getUserToken();
    const {confirm} = useConfirm();

    const { data: timespans, loading, error } = useFetch("timespans", {
        method: 'GET',
        headers: {'Authorization': `Bearer ${tokenData}`}
    });

    let paginationData;
    let numberOfPages;
    let readyAfterLoad = false;

    useEffect(() => {
        setTemplateData(timespans);
    },[timespans])

    const setValid = (state) => {
        if (state === "false") {
            setFormError("Skjemaet har feiler eller felter som ikke er fylt ut.");
        } 
    }
    const saveTemplate = (timespanData) => {
        setFormError("");
        setActionError("");
        setDeleteError("");
        setOnCreate("Oppretter mal ...");
        fetch(apiUrl + `timespans?name=${timespanData.name}&minutesBetween=${timespanData.minutesBetween}&start=${timespanData.start}&end=${timespanData.end}`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${tokenData}`,
                "Content-Type": "application/json",
            },
        }).then((response)=> {
            setOnCreate("");
            if (response.ok) {
                setIsSuccess("Timemal ble opprettet!");
                return response.json().then((data) => {
                    setTemplateData((templateData) => {
                        const pickedDay = templateData.find((item) => item.name === timespanData.name);
                        
                        if (pickedDay) { 
                            return;
                        } else { 
                            return [...templateData, timespanData = { ...timespanData, id: data}]
                        };
                    });
                })
            } else {
                setIsSuccess("")
                return response.json().then((data) => {
                    setActionError("Det har oppstått en feil ved oppretting av timemal, se over skjemaet og prøv igjen.");
                });
            }
        }).catch((error) => {
            setActionError("Det har oppstått en feil ved oppretting av timemal, se over skjemaet og prøv igjen.");
        });
    };

    const deleteTimespan = async (event, id) => {
        event.preventDefault();
        const isConfirmed = await confirm("Er du sikker på at du vil slette denne timemalen?");

        setFormError("");
        setActionError("");
        setIsSuccess("");
        if (isConfirmed) {
            return fetch(apiUrl + "timespans/" + id, {
                method: "DELETE",
                headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json", }
            }).then((response)=> {
                if (response.ok) {
                    setDeleteError("");
                    setTemplateData(templateData.filter(item => item.id !== id));
                } else {
                    return response.json().then(() => {
                        setDeleteError("Det har oppstått en feil ved sletting av denne timemalen. Last inn siden på nytt eller prøv igjen senere.");
                    });
                }
            }).catch(() => {
                setDeleteError("Det har oppstått en feil ved sletting av denne timemalen. Last inn siden på nytt eller prøv igjen senere.");
            });
        } 
    };

    const clearSuccessState = () => {
        setIsSuccess("");
        setFormError("");
    }

    if (!loading && templateData && templateData.length > 0) {
        const sortedData = templateData.sort((a,b) => b.id - a.id);
        const indexOfLastRecord = currentPaginationPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        paginationData = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);
        numberOfPages = Math.ceil(templateData.length / itemsPerPage)
        readyAfterLoad = true;
    }

    return (
        <section className="contents__container">
            <h1 className="no-mt">Dag- og timemaler</h1>
            <p>Opprett timemaler for å kunne opprette dagsmaler. Forhåndslagde maler kan benyttes ved opprettelse av ledige timer for å spare tid.</p>
            <p>Her kan du både lage nye maler, se over de eksisterende og eventuelt slette maler.</p>

            <section className="equal templates">            
                <section className="equal__part">
                    <h2>Opprett ny timemal</h2>
                    <div className="templates__form"> 
                        <p>Velg et beskrivende navn for malen, sett start og slutt-klokkeslett og velg minutter mellom timene.</p>
                        
                        <CreateTimespanTemplates templates={templateData} onSave={saveTemplate} onValid={(state) => setValid(state)}/>

                        {formError && <TimeoutFeedback class="topped" feedbackClass="feedback--error" clearMessage={clearSuccessState} message={formError}/>}
                        {onCreate && <Feedback class="topped" feedbackClass="feedback--warning" message={onCreate}/>}
                        {isSuccess && <TimeoutFeedback class="topped" feedbackClass="feedback--success" clearMessage={clearSuccessState} message={isSuccess}/>}
                        {actionError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={actionError}/>}
                    </div>
                </section>
                <section className="equal__part">
                    <h2>Eksisterende maler ({templateData?.length})</h2>

                    {!loading && templateData ?
                        <>
                            <div className="templates__table">
                                {(!error && templateData?.length > 0 ) && 
                                    <table style={{width: "100%"}} className="templates__list table">
                                        <thead>
                                            <tr className="table__head">
                                                <th style={{width:"45%"}}>Malnavn</th>
                                                <th style={{width:"14%"}}>Start</th>
                                                <th style={{width:"14%"}}>Slutt</th>
                                                <th style={{width:"14%"}}>Minutter</th>
                                                <th style={{width:"10%"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {readyAfterLoad && paginationData.map((timespan, index) => (
                                                <tr className="table__row" key={index}>
                                                    <td>{addWhiteSpace(timespan.name)}</td>
                                                    <td>{timespan.startHour ? padWithZero(timespan.startHour) + ":" + padWithZero(timespan.startMinute) : formatTimeString(timespan.start)}</td>
                                                    <td>{timespan.endHour ? padWithZero(timespan.endHour) + ":" + padWithZero(timespan.endMinute) : formatTimeString(timespan.end)}</td>
                                                    <td>{timespan.minutesBetween} min.</td>
                                                    <td><button className="text__btn" onClick={(e) => deleteTimespan(e, timespan.id)} title="Slett denne malen">Slett</button></td>
                                                </tr>
                                            ))} 
                                        </tbody>
                                    </table>
                                }
                                {(!error && templateData && templateData.length === 0) && <p className="templates__list--feedback">Ingen timemaler er opprettet enda.</p>}
                            </div> 
                            {itemsPerPage >= templateData.length ? "" :
                                <Pagination numberOfPages={numberOfPages} currentPaginationPage={currentPaginationPage} setCurrentPaginationPage={setCurrentPaginationPage}/>
                            }
                            {deleteError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={deleteError}/>}
                            {error && <Feedback class="feedback--center templates__list--feedback" feedbackClass="feedback--error" message={"Det oppsto en feil ved henting av maler. Forsøk å last inn siden på nytt."}/>} 
                        </> 
                        : 
                        <div className="loading__container topped">
                            <LeafLoader loadingMessage="Laster inn maler ..."/>
                        </div>
                    }
                </section>
            </section>
    
            <DayTemplatesPage timespans={templateData}/> 
        
            <section className="notice">
                <img src={attentionIcon} alt="Attention icon"/> 
                <div>
                    <p>"Minutter mellom" tilsier hvor lenge det skal være mellom hver bestillingstime. </p>
                    <p>
                        For eksempel så vil én klokketime fra 12:00 til 13:00 med 30 minutter mellom vil tilsi tre timer (kl 12:00, kl 12:30 og kl 13:00). 
                        Bruk tid mellom timene for å bedre planlegge effektiv levering av korn basert på tiden det tar å levere. 
                    </p>
                </div>
            </section>
        </section>
    );
};

export default TimespanTemplatesPage;
