import { useContext, useEffect, useState, lazy, Suspense } from "react";
import BookingContext from "../../context/booking-context";
import { getUserToken } from "../../data/token";
import useFetch from "../../hooks/useFetch";
import { getConfiguration, getGrainData,} from "./BookingService";
import LeafLoader from "../../assets/LoadingSpinner";
import { ErrorFeedback } from "../../assets/Feedback";
import BookingSelectAppointments from "./BookingSelectAppointments";

const BookingOrderAppointments = lazy(() => import("./BookingOrderAppointments"));
const RestrictedAdminAccess = lazy(() => import("../UI/RestrictedAdminAccess"));

const BookingPage = ({access}) => {
    const [appointmentData, setAppointmentData] = useState([]);
    const [fetchError, setFetchError] = useState("");
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [maxTapped, setMaxTapped] = useState(null);
    const [maxReservations, setMaxDailyReservations] = useState(null);
    const [tapTime, setTapTime] = useState(null);
    const [activeStep, setActiveStep ] = useState(0);
    const [pastTapCheck, setPastTapCheck] = useState(true);

    const bookingCtx = useContext(BookingContext);
    const tokenData = getUserToken();

    const { data: appointments, loading, error } = useFetch("appointments", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    let fetchErrorFeedback = "Det oppsto en feil ved henting av ledige timer, vennligst last inn siden på nytt. Kontakt oss om problemet vedvarer.";
    let renderState = false;
    let errorState = false;

    useEffect(() => {
        getConfiguration(tokenData).then((response) => { setMaxTapped(response.maxTapped); setMaxDailyReservations(response.maxDailyReservations); setTapTime(response.tapDuration) })
            .catch((error) => { setFetchError("Det har oppstått en feil ved henting av antall timer timer som kan bestilles. Last inn siden på nytt, kontakt oss om problemet vedvarer.");}
        );

        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setFetchError("Det har oppstått en feil ved henting av kornarter og kornsorter. Last inn siden på nytt, kontakt oss om problemet vedvarer.");}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenData]); 

    useEffect(() => {
        setAppointmentData(appointments);

        if (appointments && tapTime) { 
            bookingCtx.appointmenStoredCheck(appointments, tapTime);
            setPastTapCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments, tapTime]); 

    const stepForwards = () => { setActiveStep(activeStep + 1)}

    const stepBackwards = () => { setActiveStep(activeStep - 1)}

    const getSectionComponent = () => {
        switch (activeStep) {
            case 0:
                return <BookingSelectAppointments appointments={appointmentData} tapTime={tapTime} maxTapped={maxTapped} maxReservations={maxReservations} onProceed={stepForwards}/>
            case 1:
                return <Suspense fallback={""}><BookingOrderAppointments grains={grainVarieties} onReturn={stepBackwards} tapTime={tapTime}/></Suspense>
            default:
                return null;
        }
    }

    if (!loading && !errorState && grainVarieties.length > 0 && maxTapped !== null && maxReservations !== null && tapTime !== null && !pastTapCheck) renderState = true;
    
    if (fetchError || error) errorState = true;

    return (
        <div className="main__section">
            <div className="main__section__body content">
                {access === "adminUser" ?
                    <> 
                        <div className="content__header">
                            <h1>Timebestilling for brukere</h1>
                        </div>
                        
                        <Suspense fallback={<LeafLoader loadingMessage="Laster ..."/>}><RestrictedAdminAccess message="kan ikke bestille timer for brukere via dette skjemaet" status="order"/></Suspense>
                    </>
                    : 
                    <>
                        <div className="content__header">
                            <h1>Bestill timer for kornlevering</h1>
                        </div>
                        <div className="content__container">
                            {(loading && !renderState) && <LeafLoader loadingMessage="Laster inn siden ..."/>}
                            {errorState && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={fetchErrorFeedback}/>}

                            {renderState && <> 
                                <div className="content__container__subheader">
                                    <ul className="breadcrumbs">
                                        <li className={`breadcrumbs__item ${activeStep === 0 && "is-active"}`} title="Velg dato(er) du vil bestille timer på">
                                            <span>1</span>Velg timer
                                        </li>
                                        <li className={`breadcrumbs__item ${activeStep === 1 && "is-active"}`} title="Velg hva du vil levere (tonn, kornart osv)">
                                            <span>2</span>Velg levering
                                        </li>
                                        <li className={`breadcrumbs__item ${activeStep === 2 && "is-active"}`} title="Bekreftelse på bestilling">
                                            &#10095; Kvittering
                                        </li>
                                    </ul>
                                </div>
                                <div className="content__container__body">
                                    {getSectionComponent()}
                                </div>
                            </>}
                        </div>
                    </>
                } 
            </div>
        </div>
    );
};

export default BookingPage;
