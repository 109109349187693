import React, { useState } from "react";
import { getUserToken } from "../../../../../data/token";
import { Link } from "react-router-dom";
import { apiUrl } from "../../../../../data/API";
import Button from "../../../../UI/Button";
import { Feedback } from "../../../../../assets/Feedback";
import { delayPromise } from "../../../../../assets/DelayPromise";
import { capitalizeStr } from "../../../../../assets/Capitalize";

const CustomerEdit = (props) => {
    const [category, setCategory] = useState("");
    const [onSave, setOnSave] = useState("");
    const [error, setActionError] = useState("");
    
    const tokenData = getUserToken();
    const customers = props.customers;
    const categories = props.userCategories;
    const customersToUpdate = props.customerChecked;

    const updateCustomerCategory = (event) => {
        event.preventDefault();
        setOnSave("Oppdaterer kundeinformasjon..");
        fetch(apiUrl + `customers/category`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${tokenData}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                categoryId: parseInt(category),
                usernames: customersToUpdate,
            }),
        }).then(delayPromise(2000))
        .then((response) => {
            if (response.ok) {
                setOnSave("");
                setActionError("");
                props.updateChangesToState(customersToUpdate, parseInt(category)); 
                props.onHide();
                return response.text();
            } else {
                setOnSave("");
                setActionError("Det oppsto en feil, last inn siden på nytt og prøv igjen.");
            }                
        }).catch((error) => {
            setOnSave("");
            setActionError("Det oppsto en feil, last inn siden på nytt og prøv igjen.");
        });
    };

    return (<>
        {customers && categories ?
            <div className="modal__form">
                <div className="modal__form__header">
                    <h1>Rediger kunde</h1>
                </div>

                <div className="modal__form__content">
                    <h2>Endre eller slett kategori på kunde(r)</h2>

                    {categories.length > 0 ? <>
                        <div className="modal__form__item modal__form__item--xl modal__form__item--topped">
                            <label htmlFor="categorylist">Ny kategori</label>
                            <div>
                                <select id="categorylist" name="categorylist" onChange={(event) => setCategory(event.target.value)} className="form__item__step--select">
                                        <option value="" disabled="disabled">- Velg kategori -</option> 
                                        <option value="0">Ingen</option> 
                                        
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {capitalizeStr(category.name)} - {category.maxDailyReservationsOverride} reservasjoner per dag
                                            </option>
                                        ))}
                                </select>
                            </div> 
                        </div>

                        <div className="modal__form__feedback">
                            <Button onClick={(e) => updateCustomerCategory(e)} disabled={!customersToUpdate} title="Lagre endring og oppdater kategori på kunde(r)" className="confirm button__mr">
                                Lagre endringer
                            </Button>
                            <Button className="plain" type="button" title="Lukk visning" onClick={props.onHide}>Avbryt</Button>

                            {onSave && <Feedback class="feedback--center topped" feedbackClass="feedback--loading" message={onSave}/>}
                            {error && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={error}/>} 
                        </div></>
                    : <p>Det finnes ingen kategorier enda; opprett kategorier <Link title="Opprett nye kategorier" to={"/administrator/kategorier"}>her</Link>.</p>}
                </div>

            </div>
        : "Det oppsto et problem ved henting av data."} 
    </>);
};

export default CustomerEdit;
