import React from "react";
import ReactDOM from "react-dom/client";
import "../src/sass/style.scss";
import App from "./App";
import { AuthContextProvider } from "./context/auth-context";
import { ConfirmContextProvider } from "./context/confirm-context";
import ConfirmDialog from "./components/UI/ConfirmDialog";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <AuthContextProvider>
        <ConfirmContextProvider>
                <App />
                <ConfirmDialog/>
        </ConfirmContextProvider>
        </AuthContextProvider>
    </React.StrictMode>
);
