import { useEffect, useState } from "react"

export const Feedback = (props) => {
    return (
        <div className={`feedback ${props.class}`} onClick={props.onClick} title={props.title}>
            <div className={props.feedbackClass}>
                <span></span><p>{props.message}</p>
            </div>
            <span title={props.symbolTitle} onClick={() => props.function()}>{props.symbol}</span>
        </div>
    );
}

export const TimeoutFeedback = (props) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(!props.message){
            setVisible(false);
            return
        }

        setVisible(true);
        
        const timer = setTimeout(() => {
            props.clearMessage("");
            setVisible(false);
        }, props.timeout ? props.timeout : 4000);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.message]); 

    return (<>
        {visible &&
            <div className={`feedback ${props.class}`}>
                <div className={props.feedbackClass}>
                    <span></span><p>{props.message}</p>
                </div>
            </div>
        }
    </>);
}

export const ErrorFeedback = (props) => {
    return (
        <div className={`error__container ${props.class}`} onClick={props.onClick} title={props.title}>
            <p className={`error__container__text ${props.feedbackClass}`}>
                {props.message}
            </p>
        </div>
    );
}
