import { Fragment, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../../data/API";
import AuthContext from "../../context/auth-context";
import Button from "../UI/Button";
import { Feedback, ErrorFeedback } from "../../assets/Feedback";
import useInput, { inputNotEmpty } from "../../hooks/useInput";
import logo from "../../assets/icons/braskereidfosslogo.png";

const Login = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [error, setError] = useState("");

    const userCtx = useContext(AuthContext);
    const navigate = useNavigate();

    const usernameInputRef = useRef();
    const passwordInputRef = useRef();

    const { isValid: userNameIsValid, hasError: userNameHasError, valueChangeHandler: userNameChangeHandler, inputBlurHandler: userNameBlurHandler } = useInput(inputNotEmpty);
    const { isValid: passwordIsValid, hasError: passwordHasError, valueChangeHandler: passwordChangeHandler, inputBlurHandler: passwordBlurHandler } = useInput(inputNotEmpty);

    let formIsValid = false;

    if (userNameIsValid && passwordIsValid) {
        formIsValid = true;
    }

    const delayRedirect = (e, path, time) => {
        e.preventDefault();

        setTimeout(() => {
            navigate(path, { replace: true });
            navigate(0);
        }, time);
    };

    const submitHandler = (event) => {
        event.preventDefault();
        const enteredUsername = usernameInputRef.current.value;
        const enteredPassword = passwordInputRef.current.value;

        setError("");
        setIsLoading(true);

        fetch(apiUrl + "auth/login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                username: enteredUsername,
                password: enteredPassword,
            }),
        }).then((response) => {
            setIsLoading(false);
            if (response.ok) {
                setError("");
                return response.json();
            } else {
                return response.json().then((data) => {
                    setError("Innlogging mislyktes, vennligst prøv igjen.");
                });
            }
        }).then((data) => {
            userCtx.login(data.token);
            setIsLoggingIn(true);
            setTimeout(() => setIsLoggingIn(false), 3000);
            setTimeout(() => props.onHide(), 3300);

            if (data.roles[0] === "ROLE_ADMIN") {
                delayRedirect(event, "/administrator/timeovervoker", 3500);                
            } else {
                delayRedirect(event, "/timebestilling", 3500);
            }
        })
        .catch((error) => {
            setError("Innlogging mislyktes, vennligst prøv igjen. Om problemet vedvarer kontakt oss på e-post.");
        });
    };
    
    return (
        <Fragment>
            <div className="login__modal__content--header">
                <img className="nav__section__icon nav__section__icon--logo" src={logo} alt="Braskereidfoss Kornsilo logo"/>
                <h1>Logg inn</h1>
                <p>Du vil ha mottatt brukerdata i posten som kan brukes for innlogging.</p>
                <p>Dersom du ikke har mottatt dette må du kontakte kornsiloen direkte.</p>
            </div>

            <form className="form" onSubmit={submitHandler}>
                <div className="form__item">
                    <div className="form__item__label form__item__label--lg">
                        <label htmlFor="username">Brukernavn</label> 
                    </div>
                    <div className="form__item__step form__item__step--lg">
                        <input name="username" autoFocus type="text" id="username" required ref={usernameInputRef} onChange={(e) => userNameChangeHandler(e)}
                         className={`form__item__step--input ${userNameHasError ? "invalid" : "" }`} autoComplete="off" onBlur={userNameBlurHandler}/>
                    </div> 
                </div> 

                <div className="form__item">
                    <div className="form__item__label form__item__label--lg">
                        <label htmlFor="password">Passord</label> 
                    </div>
                    <div className="form__item__step form__item__step--lg">
                        <input name="password" type="password" id="password" required ref={passwordInputRef} onChange={(e) => passwordChangeHandler(e)} 
                         className={`form__item__step--input ${passwordHasError ? "invalid" : "" }`} autoComplete="off" onBlur={passwordBlurHandler}/>
                    </div> 
                </div> 
                <div className="feedback">
                    <Button className="plain button__mr" type="button" title="Lukk innlogging" onClick={props.onHide}>Lukk</Button>
                    <Button className="confirm" type="submit" title={!formIsValid ? "Fyll ut både brukernavn og passord" : "Logg inn"} disabled={!formIsValid}> 
                        {isLoading ? "Logger inn ..." : "Logg inn"}
                    </Button>
                </div>
            </form>
            <div className="login__modal__content--footer">
                {error && <ErrorFeedback class="error__container--centered error__container--padded" feedbackClass="" message={error}/>}
                {
                    (userCtx.isAdmin && <Feedback class="feedback--center" feedbackClass="feedback--loading" message={"Innlogging lykkes, du blir nå redirigert til administrator siden"}/>) 
                 || 
                    (isLoggingIn && <Feedback class="feedback--center" feedbackClass="feedback--loading" message={"Innlogging lykkes, du blir nå redirigert til `timebestilling`"}/>) 
                }
            </div>
        </Fragment>
    );
};
 
export default Login; 
