import { apiUrl } from "../../data/API";

export const getConfiguration = (token) => {
    return fetch(apiUrl + "configuration", {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            return data;
        });
};

export const getGrainData = (token) => {
    return fetch(apiUrl + "grains", {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json()) 
        .then((data) => {
            return data;
        });
};

export const STATUS = {
    START: "START",
    SUBMITTED: "SUBMITTED",
    SUBMITTING: "SUBMITTING",
    CONFIRMED: "CONFIRMED",
};

export const guidGenerator = () => {
    const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + "-" + S4();
};

export const TonnageErrorFeedback = (props) => {
    if (!props.amount || !props.type) {
        return null;
    } else {
        return (
            <span className="form__item__step--error">
                For øyeblikket kan du ikke levere mer enn {props.amount} tonn{" "}
                {props.type} per time.
            </span>
        );
    }
};

export const filterOutId = (array, ids) => {
    return array.filter((e) => !ids.includes(e.id));
}

export const removePastAndSortDates = (array) => {
    return array
        .filter((a) => new Date(a.scheduledAt).getTime() > new Date().getTime())
        .slice(0)
        .sort((a, b) => a.scheduledAt.localeCompare(b.scheduledAt));
};

export const untapApp = (event, id, tokenData, confirm) => {
    event.preventDefault();

    fetch(apiUrl + `appointments/${id}/untap`, {
        method: "POST", headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
    }).then((response) => {
        if (response.ok) {
            return response.text();
        } else {
            return response.json().then((data) => {
                if (data.message === "APPOINTMENT_DOES_NOT_EXIST") {
                    confirm("Denne timen finnes ikke lengere, det kan være en feil hos oss eller at noen andre har tatt den før deg.");
                } else if (data.message === "APPOINTMENT_ALREADY_RESERVED") {
                    confirm("Denne timen er allerede bestilt av noen andre.");
                } else if (data.message === "APPOINTMENT_ALREADY_TAPPED_BY_ANOTHER)") {
                    confirm("Denne timen er allerede valgt av noen andre, men ikke bestilt enda.");
                } else {
                    confirm("Det oppsto en feil ved avvelging av time, vennligst last inn siden på nytt og prøv igjen.");
                }
            });
        }                
    }).catch((error) => {
        confirm("Det oppsto en feil ved avvelging av time, vennligst last inn siden på nytt og prøv igjen.");
    });
}

export const checkForError = (appointment, validationState, grain) => {
    const result = {};
    
    validationState((errors) => {
        const errorsExists = errors.find((err) => err.id === appointment.id)

        result.id = appointment.id;

        if (!appointment.grainTypeId || appointment.grainTypeId === "" ) result.grainTypeId = "Velg kornarten du ønsker å levere.";
        if ((appointment.grainTypeId && grain.find((g) => g.id === appointment.grainTypeId).varieties.length >= 1)
                && (appointment.varietyTypeId === "" || appointment.grainTypeId === " " || !appointment.grainTypeId)) result.varietyTypeId = "Velg kornsorten du ønsker å levere.";
        if (!appointment.amount || appointment.amount <= 0 ) result.amount = "Du må oppgi antall tonn du ønker å levere.";


        if (errorsExists) {
            const otherAppointmentErrors = errors.filter((e) => e.id !== appointment.id);
            return [...otherAppointmentErrors, result ];
        } else {
            return [ ...errors, result ];
        }
    });
};

export const handleBlur = (event, appointment, touchedArray, touchedState) => {
    const touchedAppointment = touchedArray.find((existing) => existing.id === appointment.id);

    if (touchedAppointment) {
        touchedAppointment[event.target.id] = true;
    } else {
        const newlyTouched = {
            id: appointment.id,
            grainTypeId: false,
            varietyTypeId: false,
            amount: false
        };

        newlyTouched[event.target.id] = true;
        touchedArray.push(newlyTouched);
    }

    const clone = touchedArray.map(item => { return { ...item };});
    touchedState(clone);
}