import { useState } from "react";

const useInput = (validateValue, value) => {
    const [enteredValue, setEnteredValue] = useState("");
    const [isTouched, setIsTouched] = useState(false);

    const valueIsValid = validateValue(enteredValue);
    const valuIsThere = value;
    const hasError = !valueIsValid && isTouched;
    const minutesBetweenLimit = 120;
    const invalidMinutesBetween = (enteredValue > minutesBetweenLimit) && isTouched;

    const valueChangeHandler = (event) => {
        setEnteredValue(event.target.value);
    };

    const inputBlurHandler = (event) => {
        setIsTouched(true);
    };

    const reset = () => {
        setEnteredValue("");
        setIsTouched(false);
    };

    const inputBlurValue = (event) => {
        if (event.target.value.trim() === "") {
            setIsTouched(true);
            setEnteredValue(event.target.value)
        } else {
            setEnteredValue(event.target.value)
        }
    };

    return {
        value: enteredValue,
        valid: valuIsThere,
        isValid: valueIsValid,
        hasError,
        invalidMinutesBetween,
        inputBlurValue,
        valueChangeHandler,
        inputBlurHandler,
        reset,
    };
};

export default useInput;

export const inputNotEmpty = (value) => value.trim() !== "";

export const clearInput = (id) => {
    document.getElementById(id).value = "";
}
