import { Fragment } from "react";

const BoardLayout = (props) => {
    return (
        <Fragment>
            <main className="board">{props.children}</main>
        </Fragment> 
    );
};

export default BoardLayout;
