import { useEffect, useState, Fragment, useContext } from "react";
import BookingContext from "../../context/booking-context";
import { apiUrl } from "../../data/API";
import { getUserToken } from "../../data/token";
import useFetch from "../../hooks/useFetch";
import useConfirm from "../../hooks/useConfirm";
import { untapApp, removePastAndSortDates, filterOutId} from "./BookingService";
import { ErrorFeedback } from "../../assets/Feedback";
import { groupForTodayForward} from "../../assets/GroupAppointments";
import { capitalizeStr } from "../../assets/Capitalize";
import { formatDate, formatDay, formatTime } from "../../assets/DateFormatOptions";
import { getErrorStatusMessage } from "../../assets/ResponseFeedback";
import LeafLoader from "../../assets/LoadingSpinner";
import Button from "../UI/Button";

const BookingSelectAppointments = ({appointments, tapTime, maxTapped, maxReservations, onProceed}) => {
    const [availableAppointments, setAvailableAppointments] = useState([]);
    const [tappable, setTappable] = useState(null);
    const [numberOfReservationsLeft, setNumberOfReservationsLeft] = useState("");
    const [tapDisabled, setTapDisabled] = useState([]);

    const tokenData = getUserToken();
    const bookingCtx = useContext(BookingContext);
    const {confirm} = useConfirm();
    const getStoredTaps = JSON.parse(localStorage.getItem("taps") || "[]");
    
    const { data: reserved, reservedLoading, reservedError} = useFetch("appointments/reserved", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });
    
    const { data: archived, archivedLoading, archivedError} = useFetch("appointments/archived", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });
    
    let fetchErrorFeedback = "Det oppsto en feil ved henting av ledige timer, vennligst last inn siden på nytt. Kontakt oss om problemet vedvarer.";  
    let filteredDates;
    let validDaySelection = false;
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    useEffect(() => {
        setAvailableAppointments(appointments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments]); 

    useEffect(() => {
        const todaysDate = new Date().toISOString().split("T")[0];

        if (maxTapped && maxReservations && tapTime) {
            if (reserved && archived) {
                const appointmentsReservedToday = reserved?.filter((a) => new Date(a.scheduledAt).getTime() > new Date().getTime())
                    .slice(0)
                    .sort((a,b)=> b.reservedAt.localeCompare(a.reservedAt))
                    .filter((f) => f.reservedAt.split("T")[0] === todaysDate).length; 

                const pastReservedAppointments = archived?.filter((f) => f.reservedAt.split("T")[0] === todaysDate).length; 
                setNumberOfReservationsLeft(maxReservations - (+appointmentsReservedToday + +pastReservedAppointments));
                setTappable(bookingCtx.tappedAppointments.length !== Math.min(maxTapped, numberOfReservationsLeft) ? true : false); 
            }
        } else {
            setNumberOfReservationsLeft(maxReservations);
            setTappable(bookingCtx.tappedAppointments.length !== Math.min(maxTapped, numberOfReservationsLeft) ? true : false);
        }

        const fetchInterval = setInterval(() => {
            if (getStoredTaps.length > 0) {
                bookingCtx.checkForExpiredAppointments();
                
                const expiredTaps = getStoredTaps.filter((tap) => tap.expiration < (new Date().getTime())).map((t) => t.id);
                const updateLocalStorage = filterOutId(getStoredTaps, expiredTaps); 
                localStorage.setItem("taps", JSON.stringify(updateLocalStorage));
            }
        }, 20000);

        return () => clearTimeout(fetchInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[bookingCtx.tappedAppointments, archived, reserved, maxReservations, maxTapped, numberOfReservationsLeft]);

    const onAddAppointment = async (event, app) => {
        event.preventDefault();
        setTapDisabled((taps) => [...taps, app.id]);

        await fetch(apiUrl + `appointments/${app.id}/tap`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
        }).then((response) => {
            if (response.ok) {
                if (tappable) {
                    const tapExpiresIn = new Date().getTime() + ((1000 * 60) * tapTime);
                    getStoredTaps.push({id: app.id, expiration: tapExpiresIn});
                    localStorage.setItem("taps", JSON.stringify(getStoredTaps));

                    setTapDisabled((taps) => { return taps.filter((a) => a !== app.id)}); 

                    bookingCtx.addAppointment({id: app.id, scheduledAt: app.scheduledAt, tapped: true, grainTypeId: "", varietyTypeId: "", amount: "", warehouseRental: false, comment: "", sprayed: false});
                } 
        
                setTappable(bookingCtx.tappedAppointments.length !== Math.min(maxTapped, numberOfReservationsLeft) ? true : false);

                return response.text();
            } else {
                setTapDisabled((taps) => {
                    return taps.filter((a) => a !== app.id);
                }); 

                return response.json().then(async (data) => {
                    const feedback  = getErrorStatusMessage(data.message)
                    const confirmDeleteMultiple = await confirm(feedback);

                    if (confirmDeleteMultiple) {
                        return setAvailableAppointments((appointments) => appointments.filter((a) => a.id !== app.id))
                    }
                })
            }                
        }).catch((error) => {
            confirm("Det oppsto en feil ved valg av time, vennligst last inn siden på nytt og prøv igjen.");
        });
    }

    const onRemoveAppointment = (id) => {
        const storedTapIds = getStoredTaps.filter((u) => u.id !== id);
        localStorage.setItem('taps', JSON.stringify(storedTapIds));

        bookingCtx.removeAppointment(id);

        setTappable(bookingCtx.tappedAppointments.length !== Math.min(maxTapped, numberOfReservationsLeft) ? true : false);
    };  

    if (appointments && availableAppointments) {
        filteredDates = groupForTodayForward(availableAppointments).slice(0).sort((a,b)=> new Date(a.day) - new Date(b.day));
    }

    if (archivedLoading || reservedLoading) loadingState = true;
   
    if ((reservedError || archivedError)) errorState = true;

    if (!loadingState && !errorState && appointments && availableAppointments && (maxTapped && tapTime && maxReservations && tappable !== null)) {
        renderState = true;
    }

    if (bookingCtx.selectedAppointments.length > 0) {
        validDaySelection = true;
    }

    return (
        <>
            {errorState && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="topped" message={fetchErrorFeedback}/>}
            {(loadingState || !renderState) && <LeafLoader loadingMessage="Laster inn ledige timer ..."/>}
            
            {renderState && <>
                <div className="content__container__body--text">
                    <div>
                        <p> Nedenfor finner du alle våre foreløpige datoer med ledige timer. Du velger selv de timene du ønsker å levere på ved å trykke på de, og her kan du velge timer over flere dager om ønsket.
                            Om du har valgt en dato men ønsker å angre på valget så trykker du bare på den igjen før du går videre.
                        </p>
                    </div>

                    <div>
                        <p> Du har muligheten til å bestille{" "}<span className="bold">{maxReservations} timer hver dag </span>{" "}
                            og opptil{" "}<span className="bold">{" "} {maxTapped} timer per bestilling</span>.
                        </p>
                    </div>

                    <div>
                        <p className="d-block-center important">
                            <i> Du har{" "}{numberOfReservationsLeft >= 1 ? numberOfReservationsLeft : "0"}{" "} timer igjen som du kan bestille i dag.</i>
                        </p>
                    </div>
                </div>

                <div className="content__container__body--details">
                    <div className="booking"> 
                        <h2>Dager med ledige timer</h2>

                        {filteredDates.length > 0 ? 
                            filteredDates.map((date) => (
                                <div key={date.id} className="booking__list">
                                    <div className="booking__list--header">
                                        <p>{formatDate(date.day)}</p>
                                        <p>{capitalizeStr(formatDay(date.day, {weekday: 'long'}))} </p>
                                        <p>({date.appointments.length} {date.appointments.length !== 1 ? "timer" : "time"})</p>
                                    </div>
                                    <ul className="booking__list booking__list--md">
                                        {removePastAndSortDates(date.appointments).map((app) => { return (
                                            <Fragment key={app.id}>
                                                {!bookingCtx.tappedAppointments.find((a) => a === app.id) ? (
                                                    <li title={tappable ? "Velg time" : "Maks antall timer valgt"} key={app.id} disabled={!tappable || (tapDisabled.length > 0 && tapDisabled.find((a) => a !== app.id))} 
                                                        className={`booking__list__item ${!tappable && "disabled"} ${tapDisabled.length > 0 && tapDisabled.find((a) => a !== app.id) && "not-available"} ${tappable && tapDisabled.find((a) => a === app.id) && "selecting"}`} 
                                                        onClick={(e) => {(tappable && tapDisabled.length === 0) && onAddAppointment(e, app)}}>
                                                        <p className="trimmed">{formatTime(app.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</p>
                                                        <p>+ Velg</p>
                                                    </li>
                                                ) : (
                                                    <li title="Fjern time" key={app.id} className="booking__list__item item-added" onClick={(e) => {onRemoveAppointment(app.id); untapApp(e, app.id, tokenData, confirm)}}>
                                                    <p className="trimmed">{formatTime(app.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</p>
                                                        <p>Fjern</p>
                                                    </li>
                                                )}
                                            </Fragment>
                                        );})}
                                    </ul>
                                </div>
                            ))
                        : "Det finnes ingen dager med ledige timer for øyeblikket, sjekk igjen senere." }
                    
             
                    </div>
                </div>

                <div className="feedback__container topped">
                    <Button className="confirm" type="submit" title={!validDaySelection ? "Du har ikke valgt noen timer enda" : "Fortsett til neste steg"}
                        disabled={!validDaySelection || tapDisabled.length > 0} onClick={()=> onProceed()}>
                        Gå til levering
                    </Button>
                </div>
            </>}
        </>
    );
};

export default BookingSelectAppointments;