import { Link, useRouteError } from "react-router-dom";
import Button from "../components/UI/Button";

export function ErrorPage() {
    const error = useRouteError();

    let title = "Det oppsto en feil.";
    let message = "Det har beklageligvis oppstått noe rot i systemet, vennligst ta kontakt om dette vedvarer.";

    if (error && error.status === 500) {
        message = error.data.message;
    }

    if (error.status === 404) {
        title = "Ops!";
        message = "Vi kunne ikke finne siden du så etter.";
    }

    return (
        <main className="fullPage__error__container">
            <section className="fullPage__error__container--content">
                <h1>{title}</h1>
                <p>{message}</p>
                <Link to=".." relative="path"> 
                    <Button className="plain topped" type="button" title="Gå tilbake til sist besøkte side">Gå tilbake</Button> 
                </Link>
            </section>
            <p className="dedication" title="Photo by Polina Rytova on Unsplash">Photo by Polina Rytova on Unsplash</p>
        </main>
    );
}

export default ErrorPage;
