import { useState } from "react";
import { Link } from "react-router-dom";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import { delayPromise } from "../../../../../assets/DelayPromise";
import { apiUrl } from "../../../../../data/API";
import useInput, { inputNotEmpty, clearInput } from "../../../../../hooks/useInput";
import {TimespanInputs, TimespanList, padWithZero, addPremadeTemplateToList, getDryRunData, resetForm} from "./CreateAppointmentsHelper";
import Button from "../../../../UI/Button";
import { formatDate, formatDay, formatTime, formatTimeString} from "../../../../../assets/DateFormatOptions";
import { ErrorFeedback, Feedback, TimeoutFeedback} from "../../../../../assets/Feedback";
import Pagination from "../../../../UI/Pagination";
import { onFocusShowPicker } from "../../../../UI/Input";
import { sortTimespans } from "../templates/DayTemplatesPage";
import { capitalizeStr } from "../../../../../assets/Capitalize";
import { addWhiteSpace } from "../templates/CreateTimespanTemplates";
import attentionIcon from "../../../../../assets/icons/error_FILL1_wght500_GRAD0_opsz40.svg";
import addItem from "../../../../../assets/icons/add_circle_black_24dp.svg";
import removeItem from "../../../../../assets/icons/remove_circle_outline_black_24dp.svg";
import RadioButton from "../../../../UI/RadioButton";

const itemsPerPage = 6;

const CreateAppointments = () => {
    const [isLoading, setIsLoading] = useState(null);
    const [error, setError] = useState("");
    const [warning, setWarning] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [timespans, setTimespans] = useState([]);
    const [dayspans, setDayspans] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [formatEndTime, setFormatEndTime] = useState(null)
    const [publishDate, setPublishDate] = useState(null);
    const [dryRun, setDryRun] = useState(false);
    const [dryRunData, setDryRunData] = useState([]);
    const [dryRunWarning, setDryRunWarning] = useState("");
    const [dryRunLoading, setDryRunLoading] = useState(false);
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [selectedPublishOption, setSelectedPublishOption] = useState("publishNow");
    const [frequencyOfSkipsValue, setFrequencyOfSkipsValue] = useState("1");
    const [numberOfSkipsValue, setNumberOfSkips] = useState("0");
    const [tab, setTab] = useState("dayspans");

    const tokenData = getUserToken();
    const minimumDateValue = new Date().toISOString().split("T")[0];

    const { data: premadeTemplates, loadingPremadeTemplates, premadeTemplatesError} = useFetch("timespans", {
        method: 'GET', headers: { 'Authorization': `Bearer ${tokenData}`}
    });

    const { data: premadeDayTemplates, loadingPremadeDayTemplates, premadeDayTemplatesError} = useFetch("days", {
        method: 'GET', headers: { 'Authorization': `Bearer ${tokenData}`}
    });

    const { value: startTimeValue, isValid: startDateIsValid, hasError: startDateHasError, valueChangeHandler: startDateChangeHandler, inputBlurHandler: startDateBlurHandler, reset: resetStartValue } = useInput(inputNotEmpty);
    const { hasError: endDateHasError, valueChangeHandler: endDateChangeHandler, inputBlurHandler: endDateBlurHandler, reset : resetEndValue } = useInput(inputNotEmpty);
    const { value: publishDataValue, isValid: publishDataIsValid, hasError: publishDataHasError, valueChangeHandler: publishDataChangeHandler, inputBlurHandler: publishDataBlurHandler, reset: resetPublishData } = useInput(inputNotEmpty);

    let formIsValid = false;
    let validTimeInputs = false;
    let proceedInForm = false;
    let paginationData;
    let numberOfPages;
    let touched = false;
    let timespanList = <div className="summary__table__row"><p>-</p><p>-</p></div>; 

    const addStartDate = (event, state) => {
        const getTime = new Date(`${event.target.value}`).getTime();
        state(() => { return getTime; });
        setDryRunData([]);
        setDryRun(false);
    }

    const copyStartDateToEnd = (event) => {
        setEndTime(event.target.value);
        setFormatEndTime(new Date(`${event.target.value}`).getTime());
    }

    const addSpans = (item, updateState) => {
        updateState((items) => {
            const itemExists = items.find((a) => a.id === item.id);
    
            if (itemExists) {
                return items.filter((a) => a.id !== item.id);
            } else {
                return [...items, item];
            }
        });
    }

    const addTimespanHandler = (item) => {
        addSpans(item, setTimespans);
        setDryRunData([]);
        setDryRun(false);
    };

    const addDayspanHandler = (item) => {
        addSpans(item, setDayspans);

        const getTimespans = item.timespans.map((timespan) => timespan);

        setTimespans((checkedItems) => {
            const filterNewItems = getTimespans.filter((newAppointment) => !checkedItems.find((checked) => checked.id === newAppointment.id))
                .map((timespan) => {
                    const concatTemplateTime = '' + padWithZero(timespan.startHour) + padWithZero(timespan.startMinute) + padWithZero(timespan.endHour) + padWithZero(timespan.endMinute);
                    return {
                        ...timespan, 
                        time: concatTemplateTime
                    }
            })

            if (filterNewItems.length <= 0) {  
                return [...checkedItems]
            } else {
                return [...checkedItems, ...filterNewItems]
            }
        });
        
        setDryRunData([]);
        setDryRun(false);
    };

    const removeDayspanHandler = (item) => {
        const getTimespans = item.timespans.map((timespan) => timespan);

        setDayspans((items) => {
            return items.filter((a) => a.id !== item.id);
        });

        setTimespans((checkedItems) => {
            const removeExistingItems = checkedItems.filter((n) => !getTimespans.find((b) => b.id === n.id));
            return [...removeExistingItems]
        });

        setDryRunData([]);
        setDryRun(false);
    };

    const deleteTimespanHandler = (id) => {
        setTimespans((timespans) => {
            return timespans.filter((timespan) => timespan.id !== id);
        });

        setDryRunData([]);
        setDryRun(false);
    };

    const checkIfDayspanIsPicked = (dayId, dayTimespans) => {
        let picked;

        const checkIfDayInList = dayspans.find((day) => day.id === dayId);
        const dayspanTimespans = dayTimespans.map((a) => a.id);
        const timespanIds = timespans?.map((a) => a.id);

        if (checkIfDayInList) {
            if (timespanIds && dayspanTimespans) {
                let matchingTimespans = timespanIds.some((r) => dayspanTimespans.includes(r))

                if (matchingTimespans) {
                    picked = true;
                } else {
                    picked = false;

                    setDayspans((items) => {
                        return items.filter((a) => a.id !== dayId);
                    });
                }
            } else {
                picked = true;
            }
        } else {
            picked = false;
        }
        return picked;
    }

    const clearSaveState = () => {
        setIsSuccess("");
    }

    const clearFields = (e) => {
        resetForm(e, setTimespans, setDayspans, setStartTime, setEndTime, setPublishDate, setDryRunData, resetStartValue, resetEndValue, resetPublishData, setTab, setSelectedPublishOption, setFrequencyOfSkipsValue, setNumberOfSkips);
    }

    if (timespans.length > 0) {
        timespanList = <TimespanList items={timespans} onDeleteTimespan={deleteTimespanHandler} />;
    }

    if ((startDateIsValid && startTime) && endTime && formatEndTime && timespans.length >= 1 && frequencyOfSkipsValue > "0" && numberOfSkipsValue) {
        formIsValid = true;
    }

    if ((startTime && formatEndTime) && formatEndTime < startTime) {
        formIsValid = false;
        validTimeInputs = true;
    }

    if (startTime && endTime && !validTimeInputs) {
        proceedInForm = true;
    }

    if (startDateIsValid || startTime || timespans.length > 0 || publishDataIsValid) {
        touched = true;
    }

    if (!loadingPremadeDayTemplates && (premadeDayTemplates && premadeDayTemplates.length > 0)) {
        const sortedData = premadeDayTemplates.sort((a,b) => b.id - a.id);
        const indexOfLastRecord = currentPaginationPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        paginationData = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);
        numberOfPages = Math.ceil(premadeDayTemplates.length / itemsPerPage);
    }

    const minutesBetweenValues = timespans.map((m) => m.minutesBetween);
    const timespanValues = timespans.map((t) => t.time);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const apiUrlCreateAppointments = `appointments?appointmentsToPublish=${frequencyOfSkipsValue}&appointmentsToSkip=${numberOfSkipsValue}&minutesBetween=${minutesBetweenValues}&start=${startTime}&end=${formatEndTime}&timespans=${timespanValues}&timeZone=${timezone}${publishDate ? `&publishAt=${publishDate}` : ``}`;

    const onCreateAppointments = (event) => {
        event.preventDefault();

        if (formIsValid) {
            setError("");
            setIsSuccess("");
            setWarning("");
            setIsLoading("Oppretter timer ...");                            
            fetch(apiUrl + apiUrlCreateAppointments, {
                method: "POST",
                headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json",},
            }).then(delayPromise(2000))
            .then((response) => {
                setDryRun(false);
                if (response.ok) {
                    response.json().then((data) => {
                        if (data.length > 0 ) {
                            setIsLoading("");
                            setIsSuccess("Timer ble opprettet!");
                            clearFields(event); 
                        } else {
                            setIsLoading("");
                            setError("");
                            setWarning("Ingen timer ble opprettet. Du forsøker å opprette timer for et tidsrom der det allerede er opprettet ledige timer."); 
                            setTimespans([]);
                        }
                    })
                } else {
                    setIsLoading("");
                    setIsSuccess("");
                    return response.json().then((data) => {
                        if (data.message === "INTERVAL_CANNOT_START_IN_THE_PAST" ) {
                            setError("Det oppsto en feil ved opprettelse av timer, du prøver å lage timer i fortiden. Vennligst endre på dato og eller klokkeslett og prøv igjen.");
                        } else if (data.message === "ILLEGAL_START_OF_INTERVAL") {
                            setError("Det oppsto en feil ved opprettelse av timer, du prøver å lage timer som slutter før de starter. Vennligst endre på dato og eller klokkeslett og prøv igjen.");
                        } else if (data.message === "CANNOT_PUBLISH_AFTER_START_OF_INTERVAL") {
                            setError("Det oppsto en feil ved opprettelse av timer, du kan ikke velge å publisere timer etter at de skal starte. Vennligst endre på publiseringsdatoen, slik at den finner sted før timenes dato / starttid.");
                        } else if (data.message === "MINUTES_BETWEEN_AND_TIMESPAN_MISMATCH") {
                            setError("Det oppsto en feil ved opprettelse av timer, start og slutt tidspunkt mangler minutter mellom - eller motsatt. Vennligst endre på oppstart/slutt av timer og minuttene mellom, og prøv igjen.");
                        } else {
                            setIsLoading("");
                            setError("Det oppsto en feil ved opprettelse av timer. Last inn siden på nytt og forsøk igjen");
                        }
                    });
                }
            }).catch((error) => {
                setIsSuccess("");
                setIsLoading("");
                setError("Det oppsto en feil ved opprettelse av timer. Last inn siden på nytt og forsøk igjen");
            });
        } else {
            setIsSuccess("");
            setIsLoading("");
            setWarning("");
        }
    };
    
    return (
        <section className="contents__container">
            <h1 className="no-mt">Opprett timer</h1>
            <p>Du oppretter timer ved å velge dato og sette tidsrom for ønsket tidsintervall.</p>

            <section className="split topped">
                <section className="split__left">
                    <form noValidate className="create">
                        <div className="create__step">
                            <div className="create__step__header">
                                <span>1</span>
                                <h2>Velg start- og sluttdato</h2>
                            </div>

                            <div className="create__step__body">       
                                <div>
                                    <label htmlFor="startDate">Start</label>                           
                                    <input type="date" id="startDate" name="startDate" required value={startTimeValue} min={minimumDateValue} onBlur={startDateBlurHandler} onFocus={(e) => onFocusShowPicker(e)}
                                        onChange={(e) => {startDateChangeHandler(e); addStartDate(e, setStartTime); copyStartDateToEnd(e)}} className={`${(startDateHasError ? "input--error" : null)}`} 
                                    />
                                </div>

                                <div>
                                    <label htmlFor="endDate">Slutt</label>
                                    <input type="date" name="endDate" id="endDate" disabled={!startTime} value={endTime} min={minimumDateValue} onFocus={(e) => onFocusShowPicker(e)}
                                        onChange={(e) => {endDateChangeHandler(e); setEndTime(e.target.value); setFormatEndTime(new Date(`${e.target.value}`).getTime()); setDryRunData([]); setDryRun(false);}} onBlur={endDateBlurHandler} required
                                        className={`${((endDateHasError && !endTime) || validTimeInputs ? "input--error" : null)}`}
                                    /> 
                                    {validTimeInputs && (<p>Sluttdato kan ikke komme før startdato</p>)}                                    
                                </div>
                            </div>
                        </div>

                        <div className="create__step">
                            <div className="create__step__header">
                                <span>2</span>
                                <h2>Sett opp tidsrom og tid mellom timer</h2>
                            </div>
                            <section className="tabs tabs--indent">
                                <div className="tabs__nav">
                                    <span className={`tabs__nav__link ${tab === "dayspans" && "tabs__nav__link--active"}`} title="Åpne" onClick={() => setTab("dayspans")}>
                                        Dagsmaler {premadeDayTemplates && "(" + premadeDayTemplates.length + ")"}
                                    </span>
                                    <span className={`tabs__nav__link ${tab === "timespans" && "tabs__nav__link--active"}`} title="Åpne" onClick={() => setTab("timespans")}>
                                        Timemaler {premadeTemplates && "(" + premadeTemplates.length + ")"}
                                    </span>
                                    <span className={`tabs__nav__link ${tab === "createspan" && "tabs__nav__link--active"}`} title="Åpne" onClick={() => setTab("createspan")}>
                                        Opprett midlertidig mal
                                    </span>
                                </div>

                                <div className="tabs__body">
                                    {tab === "dayspans" &&
                                        <div className="tabs__body__content pager">
                                            {!loadingPremadeDayTemplates && premadeDayTemplates ? 
                                                <div name="templateList" className="templates__form__container__list">
                                                    {(premadeDayTemplates.length > 0 && paginationData.length > 0) && paginationData.map((day) => (
                                                        <div onClick={() => dayspans.find((d) => d.id === day.id) ? removeDayspanHandler(day) : addDayspanHandler(day)} className={`templates__form__container__list--item ${checkIfDayspanIsPicked(day.id, day.timespans) && "selected"}`} key={day.id}>
                                                            <p>{addWhiteSpace(day.name)}</p>
                                                         
                                                            <span>{checkIfDayspanIsPicked(day.id, day.timespans) ? <img src={removeItem} alt="Remove icon" title="Fjern"/> : <img src={addItem} alt="Add icon" title="Velg"/> }</span>
                                                            {sortTimespans(day.timespans).map((timespan) => (
                                                                <div key={timespan.id}>
                                                                    <p>{timespan.name}</p>
                                                                    <p>
                                                                        {timespan.startHour ? padWithZero(timespan.startHour) + ":" + padWithZero(timespan.startMinute) : formatTimeString(timespan.start)}
                                                                        -
                                                                        {timespan.endHour ?  padWithZero(timespan.endHour) + ":" +  padWithZero(timespan.endMinute) : formatTimeString(timespan.end)}
                                                                    </p>
                                                                    <p> ({timespan.minutesBetween} min.) </p>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                    {premadeDayTemplates.length === 0 && <p>Det finnes ingen dagsmaler som kan benyttes enda. Du kan lage maler <Link to={"/administrator/maler"}>her</Link></p>}
                                                </div> 
                                                : <Feedback class="feedback--center" feedbackClass="feedback--loading borderless" message={"Laster inn dagsmaler ..."}/>
                                            }
                                            {(premadeDayTemplates && premadeDayTemplates.length > 0) && itemsPerPage <= premadeDayTemplates.length ? 
                                                <Pagination numberOfPages={numberOfPages} currentPaginationPage={currentPaginationPage} setCurrentPaginationPage={setCurrentPaginationPage}/>
                                                :"" 
                                            }
                                            {premadeDayTemplatesError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto en feil ved henting av dagsmaler, vennligst last inn siden på nytt."}/>}
                                        </div>
                                    }

                                    {tab === "timespans" &&
                                        <div className="tabs__body__content">
                                            {!loadingPremadeTemplates && premadeTemplates ?
                                                <div name="templateList" className="templates__form__container__list">
                                                    {premadeTemplates.length > 0 ? sortTimespans(premadeTemplates).map((time) => (
                                                        <div onClick={(e) => timespans.find((timespan) => timespan.id === time.id) ? addTimespanHandler(time) : addPremadeTemplateToList(e, timespans, time, setTimespans, setDryRunData)} 
                                                            className={`templates__form__container__list--item ${timespans.find((timespan) => timespan.id === time.id) && "selected"}`} key={time.id}
                                                        >
                                                            <p>{addWhiteSpace(time.name)}</p>
                                                            <span>{timespans.find((timespan) => timespan.id === time.id) ? <img src={removeItem} alt="Remove icon"/> : <img src={addItem} alt="Add icon"/> }</span>
                                                            
                                                            <div>
                                                                <p>{time.name}</p>
                                                                <p>
                                                                    {time.startHour ? padWithZero(time.startHour) + ":" + padWithZero(time.startMinute) : formatTimeString(time.start)}
                                                                    -
                                                                    {time.endHour ?  padWithZero(time.endHour) + ":" +  padWithZero(time.endMinute) : formatTimeString(time.end)}
                                                                </p>
                                                                <p> ({time.minutesBetween} min.) </p>
                                                            </div>
                                                        </div>

                                                        )) 
                                                        : <p>Det finnes ingen timemaler som kan benyttes enda. Du kan lage maler <Link to={"/administrator/maler"}>her</Link></p>
                                                    }
                                                </div> : <Feedback class="feedback--center" feedbackClass="feedback--loading borderless" message={"Laster inn timemaler ..."}/>
                                            }
                                            {premadeTemplatesError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto en feil ved henting av timemaler, vennligst last inn siden på nytt."}/>}
                                        </div>
                                    }

                                    {tab === "createspan" &&
                                        <div className="tabs__body__content">
                                            <p style={{margin: "0 0 18px 0"}}>Opprett en midlertidig timemal for bruk denne ene gangen. Velg start- og sluttid, samt minutter mellom timene.</p>
                                            <TimespanInputs onAddTimespan={addTimespanHandler} item={proceedInForm ? "validDates" : undefined}/>
                                        </div>
                                    }
                                </div>
                            </section>
                            {timespans && 
                                <div className="tabs__body__footer">
                                    <p className="tabs__body__footer--link" title="Se alle maler"><Link to={"/administrator/maler"}>Til oversiktsiden for alle maler</Link></p>
                                    <p>{`${timespans.length} ${timespans.length !== 1 ? " timemaler valgt" : " timemal valgt"}`}</p>
                                </div>
                            }
                        </div>

                        <div className="create__step">
                            <div className="create__step__header">
                                <span>3</span>
                                <h2>Velg publiseringsmetode</h2>
                            </div> 
                            <div className="create__step__body">     
                                <div className="create__step__body--radio">
                                    <RadioButton htmlFor="publishAt" title="Publiser nå" id="publishAt" name="publishAt" onChange={() => setSelectedPublishOption("publishNow")}
                                        className={`${selectedPublishOption === "publishNow" && "checked"}`} checked={selectedPublishOption === "publishNow"} value="publishNow" 
                                        onClick={() => { resetPublishData(); clearInput("publishData"); setPublishDate(null)}}/>
                                    <RadioButton htmlFor="publishOnDate" title="Publiser på dato" id="publishOnDate" name="publishOnDate" onChange={() => setSelectedPublishOption("publishOnDate")}
                                        className={`${selectedPublishOption === "publishOnDate" && "checked"}`} checked={selectedPublishOption === "publishOnDate"} value="publishOnDate"/>
                                    
                                    <div>
                                        <p>
                                            Hver     
                                            <input name="frequencyOfSkips" type="number" id="frequencyOfSkips" className={`sm-number-input ${frequencyOfSkipsValue === "0" || !frequencyOfSkipsValue ? "input--error" : undefined}`}
                                                onChange={(e) => setFrequencyOfSkipsValue(e.target.value)} value={frequencyOfSkipsValue} autoComplete="off" min="1"/>
                                            time(r) vil
                                            {
                                                selectedPublishOption === "publishNow"
                                                    ? " publiseres nå"
                                                    : <> publiseres på {""}
                                                        <input type="datetime-local" min={new Date().toISOString().substring(0, 16)} className={`${(publishDataHasError ? "input--error" : null)}`} 
                                                            id="publishData" value={publishDataValue} onBlur={publishDataBlurHandler} onFocus={(e) => onFocusShowPicker(e)} name="publishData"
                                                            onChange={(e) => {publishDataChangeHandler(e); setPublishDate(new Date(e.target.value).getTime());}} 
                                                            />                                                           </>
                                            }
                                            , men i tillegg oppretter vi og holder tilbake hver
                                            <input name="numberOfSkips" type="number" id="numberOfSkips" className={`sm-number-input ${!numberOfSkipsValue || numberOfSkipsValue < 0 ? "input--error" : undefined}`}
                                                onChange={(e) => setNumberOfSkips(e.target.value)} min="0" value={numberOfSkipsValue}  autoComplete="off" />
                                                time(r) i dette tidsrommet.
                                        </p>

                                        {(frequencyOfSkipsValue === "0" || !frequencyOfSkipsValue) && <p className="form--error">Ugyldig verdi, feltet kan ikke ha en verdi lavere enn ' 1 '.</p>}
                                        {(!numberOfSkipsValue || numberOfSkipsValue < 0)&& <p className="form--error">Ugyldig verdi for timer som holdes tilbake, feltet mangler data. Legg til ' 0 ' om du ønsker å publisere alle.</p>}
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div className="create__step">
                            <div className="create__step__header">
                                <span>4</span>
                                <h2>Fullfør timeopprettelse</h2>
                                <div className="create__step__body">  
                                    <p style={{width: "85%"}}>
                                        I oversiktsfeltet øverst til høyre kan du se hvilke timer dette resultere i ved å trykke "Se hvilke timer dette gir".
                                        Du har enda muligheten til å endre alle feltene, fjerne og/eller legge til tidsrom, før du fullfører opprettelsen av timer. 
                                    </p>
                                </div>
                            </div>
                            <div className="create__step__body">   
                                <Button className="confirm button__mr" type="submit" title={!formIsValid ? "Fyll ut alle feltene først" : "Opprett timer"}
                                    disabled={!formIsValid || (formIsValid && selectedPublishOption === "publishOnDate" && !publishDataIsValid)} onClick={onCreateAppointments}>Opprett timer
                                </Button>
                                <Button className="plain" type="submit" title="Tøm alle feltene ovenfor" onClick={(e) => {clearFields(e); setDryRun(false)}}>
                                    Tøm skjema
                                </Button>  
                            </div>

                            <div className="create__step__indent">
                                {warning && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={warning}/>}
                                {error && <ErrorFeedback class="error__container--inline error__container--topped" feedbackClass="" message={error}/>}
                                {isLoading && <Feedback class="feedback--center topped" feedbackClass="feedback--loading" message={isLoading}/>}
                                {isSuccess && <TimeoutFeedback class="topped" clearMessage={clearSaveState} feedbackClass="feedback--success" message={isSuccess}/>}
                            </div>
                        </div>
                    </form>
                </section>
                <section className="split__right">
                    <div className="split__right__container">
                        <h2>Oversikt</h2>
                        {!touched ? "Fyll inn feltene for å se en oversikt." : <div className="summary__table">
                            <div className="summary__table__row summary__table__row--header">  
                                <p>Start</p>
                                <p>Slutt</p>
                                <p></p>
                            </div>
                            <div className="summary__table__row">  
                                <p>{startTime ? formatDate(new Date(startTime)) : "-"}</p>
                                <p>{endTime ? formatDate(new Date(endTime)) : "-"}</p>
                                <p></p>
                            </div>

                            <div className="summary__table__row summary__table__row--header">  
                                <p>Klokkeslett</p>
                                <p>Tid</p>
                                <p></p>
                            </div>

                            {timespanList} 

                            <div className="summary__table__row summary__table__row--header">  
                                <p>Publiseringsstatus</p>
                            </div>
                            <div className="summary__table__row">  
                                <p style={{width: "100%"}}>{publishDate ? "Publiseres ved oppgitt tidspunkt." : "Publiseres umiddelbart."}</p>
                            </div>

                            <div className="summary__table__dryRun">  
                                <div className="summary__table__dryRun--header">
                                    {!dryRun && !dryRunData.length > 0 &&
                                        <Button className="confirm topped" disabled={!formIsValid} onClick={(e) => getDryRunData(e, formIsValid, setError, apiUrl, apiUrlCreateAppointments, tokenData, setDryRun, setDryRunData, setDryRunWarning, setDryRunLoading)}
                                            title={!formIsValid ? "Fyll ut alle feltene først" : "Se hvilke timer som blir opprettet"}>
                                            Se hvilke timer dette gir
                                        </Button>
                                       
                                    }
                                </div>
                                {dryRun && <>
                                    <div className="summary__table__dryRun--banner">
                                        <p>Timeresultat</p>
                                        <Button disabled={!dryRunData} className="text__btn summary__table__dryRun--btn" title="Lukk visning" onClick={() => {setDryRunData([]); setDryRun(false)}}>
                                            Lukk visning
                                        </Button>
                                    </div>
                                    <div className="summary__table__dryRun--content">
                                        {dryRunLoading && <Feedback class="feedback--center" feedbackClass="feedback--loading borderless" message={"Laster inn resultat ..."}/>}
                                        {dryRunData.length > 0 && !dryRunLoading && <div className="container">
                                            <p style={{width: "100%"}}><span>{dryRunData.length} {dryRunData.length > 1 ? "timer" : "time"}</span></p>
                                            {dryRunData.map((data, index) => (
                                                <div key={index}>
                                                    <p>{formatDate(data)}</p>
                                                    <p>{capitalizeStr(formatDay(data, {weekday: 'long'}))}</p>
                                                    <p>{formatTime(data, {hour: '2-digit', minute: '2-digit'})}</p>
                                                </div>
                                            ))}
                                        </div>}
                                    </div> 
                                    {dryRunWarning && <ErrorFeedback class="error__container--inline" feedbackClass="" message={dryRunWarning}/>}
                                </>}
                            </div>
                        </div>}
                    </div>
                    <div className="split__right__notice">
                        <img src={attentionIcon} alt="Attention icon"/> 
                        <div>
                            <p>Timer kan opprettes over flere ulike tidspunkt</p>
                            <p>Én dag kan ha timer fra kl 09:00 til 11:00 med 30 minutters mellomrom (5 timer), samt timer fra kl 12:00 til 15:00 med 10 minutters mellomrom (18 timer), osv. </p>
                        </div>
                    </div>
                </section>
            </section> 
        </section>
    );
};

export default CreateAppointments;
