import { Fragment } from "react";
import AdminNavigation from "./AdminNavigation";
import { Outlet } from "react-router-dom";

const AdminLayout = () => {
    return (
        <Fragment>
            <AdminNavigation/>
            <main className="contents">
                <Outlet/>
            </main>
        </Fragment>
    );
};

export default AdminLayout;

