export const getErrorStatusMessage = (status) => {
    const errors = {
        AMOUNT_EXCEEDS_THRESHOLD:
            "Denne timen kunne ikke bestilles, ulovelig stor mengde med korn per klokketime.",
        MOUNT_MUST_BE_GREATER_THAN_ZERO:
            "Denne timen kunne ikke bestilles, mengde var satt til 0 eller manglet.",
        GRAIN_DOES_NOT_EXIST:
            "Denne timen kunne ikke bestilles, du har valgt en kornart som ikke eksisterer.",
        VARIETY_DOES_NOT_EXIST:
            "Denne timen kunne ikke bestilles, du har valgt en kornsort som ikke eksisterer.",
        VARIETY_IS_REQUIRED:
            "Denne timen kunne ikke bestilles, valgt kortnart krevde en kornsort som den manglet.",
        APPOINTMENT_ALREADY_RESERVED:
            "Denne timen er allerede bestilt av noen andre.",
        APPOINTMENT_DOES_NOT_EXIST:
            "Denne timen finnes ikke lengere, det kan ha oppstått en feil eller at noen andre har tatt den før deg.",
        APPOINTMENT_ALREADY_TAPPED:
            "Denne timen er allerede valgt av noen andre, men ikke bestilt enda.",
        APPOINTMENT_ALREADY_TAPPED_BY_ANOTHER:
            "Denne timen er allerede valgt av noen andre, men ikke bestilt enda.",
        APPOINTMENT_HAS_PASSED:
            "Denne timen er nå utgått, og ble dermed ikke valgt.",
        APPOINTMENT_TAPPED_MAXIMUM_REACHED:
            "Du har allerede valgt maks antall timer du kan bestille per dag.",
    };

    return errors[status] ?? "Ukjent feil, vennligst last inn siden på nytt og prøv igjen.";
};