import { useEffect, useState } from "react";
import { getUserToken } from "../../../../../data/token";
import { apiUrl } from "../../../../../data/API";
import { getConfiguration } from "../../../../booking/BookingService";
import GrainUpdate from "./GrainUpdate";
import { capitalizeName } from "../../../../../assets/Capitalize";
import LeafLoader from "../../../../../assets/LoadingSpinner"
import { ErrorFeedback, Feedback } from "../../../../../assets/Feedback";
import Button from "../../../../UI/Button";

const GrainPage = () => {
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [grainError, setGrainError] = useState("");
    const [maxHourlyGrain, setMaxHourlyGrain] = useState("");
    const [updateGrain, setUpdateGrain] = useState("");
    const [loading, setLoading] = useState(true);
    const tokenData = getUserToken();

    useEffect(() => {
        fetchGrainData();
        getConfiguration(tokenData).then((response) => setMaxHourlyGrain(response.maxHourlyGrain)).catch((error) => { 
            setGrainError("Det oppsto et problem ved henting av kornverdier. Forsøk å laste inn siden på nytt.");}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tokenData]);

    const handleGrainDataUpdate = (updatedGrain) => {
        const updateGrain = grainVarieties.map((variety) => {
            if (variety.id === updatedGrain.id) {
                return {...variety, hourlyMax: updatedGrain.hourlyMax, appointmentMax: updatedGrain.appointmentMax};
            } else {
                return variety;
            }
        });

        setGrainVarieties(updateGrain);
        setUpdateGrain("");
    }; 

    const fetchGrainData = () => {
        fetch(apiUrl + `grains`, {
            method: "GET", headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
        }).then((response) => {
            if (response.ok) {
                return response.json().then((data) => {
                    setGrainVarieties(data);
                    setLoading(false);
                })
            } else {
                return response.json().then((data) => {
                    setGrainError("Det har oppstått en feil ved henting av kornarter og kornsorter. Vennligst forsøk å laste inn siden på nytt.")
                })
            }                
        }).catch((error) => {
            setGrainError("Det har oppstått en feil ved henting av kornarter og kornsorter. Vennligst forsøk å laste inn siden på nytt.")
        });
    }

    return (
        <section className="contents__container">
            <h1 className="no-mt">Korn</h1>
            {grainError && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={grainError}/>}

            {!loading && grainVarieties && maxHourlyGrain ? 
                <>
                    <p>Oppdater maks tonnasje-verdier for hver enkelt kornart.</p>
                    {grainVarieties.length > 0 ?
                        <section className="graintable">
                            {grainVarieties.map((grain) => ( 
                                <div className="graintable__item" key={grain.id}>
                                    <div className="graintable__item--header">
                                        <h2>{capitalizeName(grain.name)}</h2>
                                    </div>
                                    <div className="graintable__item--body">
                                        {updateGrain === grain.id ? <GrainUpdate grainTreshold={maxHourlyGrain} grain={grain} updateChangesToState={handleGrainDataUpdate}/> :
                                            <form>
                                                <div>
                                                    <span>Maks tonn per time: </span> <p>{grain.appointmentMax} tonn</p>
                                                </div>
                                                <div>
                                                    <span>Maks tonn per klokketime: </span> <p>{grain.hourlyMax} tonn</p>
                                                </div>
                                            </form> 
                                        }
                                        <Button className="plain topped" onClick={() => setUpdateGrain(updateGrain === grain.id ? "" : grain.id)} >{updateGrain !== grain.id ? "Endre" : "Avbryt endring"}</Button>
                                    </div>
                                </div>
                            ))}
                        </section>
                        :  <Feedback class="feedback--center" feedbackClass="feedback--warning" message={"Fant ingen kornarter."}/>
                    }
                </>
                :
                <LeafLoader loadingMessage="Laster inn kornarter ..."/>
            }
        </section>
    );
};

export default GrainPage;
