import { useState, useEffect } from "react";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import { Link } from "react-router-dom";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import { formatTime, formatDate, getUniqueYears } from "../../../../../assets/DateFormatOptions";
import { ErrorFeedback } from "../../../../../assets/Feedback";
import { capitalizeName } from "../../../../../assets/Capitalize";
import { getUserName } from "../../../../../assets/GetUserCredentials";
import searchIcon from "../../../../../assets/icons/search_black_24dp.svg";
import Button from "../../../../UI/Button";

const CancelledAppointments = () => {
    const [search, setSearch] = useState(""); 
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [uniqueYears, setUniqueYears] = useState([]);
    const tokenData = getUserToken();

    const { data: cancellations, loading, error } = useFetch("appointments/cancelled", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`},
    });

    const { data: customers, customerLoading, customerError} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    useEffect(() => {
        if (cancellations) {
            setUniqueYears(getUniqueYears(cancellations, "cancelledAt"));
        }
    }, [cancellations]);

    let groupedCancellations; 
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    if (cancellations && customers) {
        const filterForThresholdViolation = cancellations.filter((t) => t.thresholdViolation);
        const sortCancellationsByDesc = filterForThresholdViolation.slice(0).sort((a,b)=> b.cancelledAt.localeCompare(a.cancelledAt));
        const filteredCancellations = selectedYear ? sortCancellationsByDesc.filter(cancelled => new Date(cancelled.cancelledAt).getFullYear() === parseInt(selectedYear)) : sortCancellationsByDesc;

        const groupCancellations = filteredCancellations.reduce((groupCancellations, data) => {
            const leadBy = getUserName(customers, data.cancelledBy.toString());
            (groupCancellations[leadBy] = groupCancellations[leadBy] || []).push(data);
    
            return groupCancellations;
        }, {});
    
        const groupByUser = Object.keys(groupCancellations).map((user) => { 
            return { user, cancellations: groupCancellations[user] };
        }); 

        groupedCancellations = search ? groupByUser.filter((u) => u.user.toLowerCase().includes(search.toLowerCase())) : groupByUser;
    } 

    const calculateCancellationTime = (scheduledAt, cancelledAt) => {
        const variableTime = new Date(scheduledAt).getTime();
        const todaysTime = new Date(cancelledAt).getTime();
        const remainingTime = variableTime - todaysTime;

        return (remainingTime / (1000 * 60)).toFixed(0);
    }

    if ((loading && customerLoading) || customerLoading || loading) loadingState = true;
    
    if ((error || customerError) || error || customerError ) errorState = true;

    if (cancellations && (customers && customers.length > 0)) renderState = true;

    return (
        <section className="contents__container">
            <h1 className="no-mt">Kansellerte timer</h1>

            {(loadingState || !renderState) && <LeafLoader loadingMessage="Laster inn kunder og timer ..."/>}
            {(errorState && !renderState) && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for kansellerte timer, vennligst last inn siden på nytt."}/>} 
            
            {renderState && <>
                <p>Nedenfor finner du en liste over kansellerte timer som har møtt daværende kanselleringsterskel. Denne terskelen kan endres <Link title="Opprett nye timer" to={"/administrator/innstillinger"}>her</Link>, under <span className="bold">kanselleringsterskel</span>.</p>
                <p>Timer er automatisk sortert per år, og viser det nærmeste året. Du kan se alle år ved å velge "Alle år" ifra nedtrekkslisten ved søkefeltet. Timene er gruppert per kunde, sortert fra nyest til eldst.  </p>                                
                <div className="list">       
                    <section className="list__form">
                        <section className="list__form__header">
                            <h2>Gruppert på Kunder</h2> 
                            <div className="list__form__header--group">
                                <div className="list__form__header--year">
                                    <label htmlFor="selectYear">Velg år:</label>
                                    <select id="selectYear" name="selectYear" value={selectedYear} onChange={(event) => setSelectedYear(event.target.value)}>
                                        {uniqueYears.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                        <option value="">Alle år</option>
                                    </select>
                                </div>                                      
                                <div className="list__form__header--search">
                                    <div className="searchbar">
                                        <input placeholder="Søk etter kunder ..." disabled={!search && groupedCancellations.length <= 0} autoComplete="off" type="text" id="searchCustomer" name="searchCustomer" value={search ? search : ""} onChange={(event) => setSearch(event.target.value)} />
                                        <img src={searchIcon} alt="Search icon"/>
                                    </div>
                                    <Button type="button" className="text__btn emptyFilter emptySearch" title="Tøm søk" onClick={() => setSearch("")}>&#10006;</Button>
                                </div>
                            </div>
                        </section>
                        {groupedCancellations.length >= 1 && 
                            <section className="list__content">
                                <p>Viser alle kunder som har møtt kanselleringsterskelen. </p>
                                {groupedCancellations.map((canceled, index) => (
                                    <div key={index} className="booking__section--table">
                                        <h3>{capitalizeName(canceled.user)}</h3>
                                        <p>Antall kanselleringer som har møtt grensen: <span className="bold">{canceled.cancellations.map((t) => t).filter((t) => t.thresholdViolation).length}</span></p>
                                        <table style={{width: "100%"}} className="table">
                                            <thead>
                                                <tr className="table__head">
                                                    <th style={{width:"32%"}}>Time</th>
                                                    <th style={{width:"32%"}}>Kansellert</th>
                                                    <th style={{width:"36%"}}>Daværende terskel (min)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {canceled.cancellations.map((cancelled) => { return (
                                                    <tr className="table__row" key={cancelled.id}>
                                                        <td><span style={{fontWeight: "600"}}>{formatDate(cancelled.scheduledAt)}</span> - {formatTime(cancelled.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</td>
                                                        <td>Kl {formatTime(cancelled.cancelledAt, {hour: '2-digit', minute: '2-digit'})} ({calculateCancellationTime(cancelled.scheduledAt, cancelled.cancelledAt)} min før time) </td>
                                                        <td>{cancelled.threshold} minutter</td>
                                                    </tr>
                                                );})}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </section>
                        }
                        {groupedCancellations.length <= 0 && search && <p className="empty-result empty-result--lg empty-result--positive">Fant ingen kunder med det navnet.</p>}
                        {groupedCancellations.length <= 0 && !search && <p className="empty-result empty-result--lg empty-result--positive">Det finnes ingen kansellerte timer.</p>}
                    </section>
                </div>
            </>}
        </section>
    );
};

export default CancelledAppointments;