import { useState, useEffect } from "react";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import { apiUrl } from "../../../../../data/API";
import useConfirm from "../../../../../hooks/useConfirm";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import { formatTime, formatDate, getUniqueYears } from "../../../../../assets/DateFormatOptions";
import { ErrorFeedback } from "../../../../../assets/Feedback";
import { capitalizeName } from "../../../../../assets/Capitalize";
import { getUserName } from "../../../../../assets/GetUserCredentials";
import searchIcon from "../../../../../assets/icons/search_black_24dp.svg";
import editIcon from "../../../../../assets/icons/mode_edit_black_24dp.svg";
import Button from "../../../../UI/Button";

const AppointmentAttendance = () => {
    const [markAttendanceLoading, setMarkAttendanceLoading] = useState(false);
    const [markedAppointments, setMarkedAppointments] = useState([]); 
    const [search, setSearch] = useState(""); 
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [uniqueYears, setUniqueYears] = useState([]);
    const [editError, setEditError] = useState(""); 

    const tokenData = getUserToken();
    const {confirm} = useConfirm();

    const { data: customers, customerLoading, customerError} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const { data: marks, loading, error } = useFetch("appointments/unattended", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`},
    });

    let groupedAppointments; 
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    useEffect(() => {
        setMarkedAppointments(marks);
        if (marks) {
            setUniqueYears(getUniqueYears(marks, "scheduledAt"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[marks]);

    const handleChangeAttendance = async (event, id, key) => {
        event.preventDefault();

        const isConfirmed = await confirm("Er du sikker på at du vil fjerne markert fravær på denne timen?");
        setMarkAttendanceLoading(true);
        setEditError("");

        if (isConfirmed) {
            await fetch(apiUrl + `appointments/${id}/attended`, {
                method: "PATCH",
                headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
            }).then((response) => {
                if (response.ok) {
                    setMarkAttendanceLoading(false);
                    setMarkedAppointments((marks) => {
                        return marks.filter((mark) => mark.id !== id);
                    });
                    
                    return response.text();
                } else {
                    setMarkAttendanceLoading(false);
                    setEditError("Det oppsto en feil ved fjerning av fravær på en time.");
                }                
            }).catch((error) => {
                setEditError("Det oppsto en feil ved fjerning av fravær på en time.");
            });
        }
    }

    if (markedAppointments && customers) {
        const sortAppointmentsByDesc = markedAppointments.slice(0).sort((a,b)=> b.scheduledAt.localeCompare(a.scheduledAt));
        const filteredCancellations = selectedYear ? sortAppointmentsByDesc.filter(mark => new Date(mark.scheduledAt).getFullYear() === parseInt(selectedYear)) : sortAppointmentsByDesc;

        const groupAppointments = filteredCancellations.reduce((groupAppointments, data) => {
            const leadBy = getUserName(customers, data.reservedBy.toString());
            (groupAppointments[leadBy] = groupAppointments[leadBy] || []).push(data);
    
            return groupAppointments;
        }, {});
    
        const groupByUser = Object.keys(groupAppointments).map((user) => { 
            return { user, appointments: groupAppointments[user] };
        }); 

        groupedAppointments = groupByUser;

        groupedAppointments = search ? groupByUser.filter((u) => u.user.toLowerCase().includes(search.toLowerCase())) : groupByUser;
    } 

    if ((loading && customerLoading) || customerLoading || loading) loadingState = true;
    
    if ((error || customerError) || error || customerError ) errorState = true;

    if (markedAppointments && (customers && customers.length > 0)) renderState = true; 

    return (
        <section className="contents__container">
            <h1 className="no-mt">Fraværstimer</h1>

            {(loadingState || !renderState) && <LeafLoader loadingMessage="Laster inn kunder og timer ..."/>}
            {(errorState && !renderState) && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for fraværstimer, vennligst last inn siden på nytt."}/>} 
            {editError && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Det oppsto en feil ved fjerning av fravær på en time."}/>}

            {renderState && <>
                <p>Dette er en liste av timer som er markert som fravær grunnet manglende oppmøte til avtalt time, eller ved manglende kansellering av time(r) fra kunders side. Timer er automatisk sortert per år, og viser det nærmeste året. Du kan se alle år ved å velge "Alle år" ifra nedtrekkslisten ved søkefeltet.</p>
                <p>Timene er gruppert per kunde, sortert fra nyest til eldst, og du kan søke på kundenavn for å finne kunder enklere.</p>                                
                <div className="list">       
                    <section className="list__form">
                        <section className="list__form__header">
                            <h2>Gruppert på Kunder</h2> 
                            <div className="list__form__header--group">
                                <div className="list__form__header--year">
                                    <label htmlFor="selectYear">Velg år:</label>
                                    <select id="selectYear" name="selectYear" value={selectedYear} onChange={(event) => setSelectedYear(event.target.value)}>
                                        {uniqueYears.map(year => (
                                            <option key={year} value={year}>{year}</option>
                                        ))}
                                        <option value="">Alle år</option>
                                    </select>
                                </div>    
                                <div className="list__form__header--search">
                                    <div className="searchbar">
                                        <input placeholder="Søk etter kunder ..." disabled={!search && groupedAppointments.length <= 0} autoComplete="off" type="text" id="searchCustomer" name="searchCustomer" value={search ? search : ""} onChange={(event) => setSearch(event.target.value)} />
                                        <img src={searchIcon} alt="Search icon"/>
                                    </div>
                                    <Button type="button" className="text__btn emptyFilter emptySearch" title="Tøm søk" onClick={() => setSearch("")}>&#10006;</Button>
                                </div>
                            </div>  
                        </section>
                        
                        {groupedAppointments.length >= 1 && 
                            <section className="list__content">
                                <p>Viser alle kunder som har fravær. </p>
                                {groupedAppointments.map((customer, index) => (
                                    <div key={index} className="booking__section--table">
                                        <h3>{capitalizeName(customer.user)}</h3>
                                        <p>Antall fravær: <span className="bold">{customer.appointments?.map((t) => t).length}</span></p>
                                        <table style={{width: "100%"}} className="table">
                                            <thead>
                                                <tr className="table__head">
                                                    <th style={{width:"32%"}}>Avtalt tid</th>
                                                    <th style={{width:"32%"}}>Bestilt</th>
                                                    <th className="table__head--action td__center" style={{width: "8%"}}>Fjern fravær</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customer.appointments.map((appointment) => { return (
                                                    <tr className="table__row" key={appointment.id}>
                                                        <td><span style={{fontWeight: "600"}}>{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</span> - {formatDate(appointment.scheduledAt)}</td>
                                                        <td><span style={{fontWeight: "600"}}>{formatTime(appointment.reservedAt, {hour: '2-digit', minute: '2-digit'})}</span> - {formatDate(appointment.reservedAt)}</td>
                                                        <td className="td__center">
                                                            <Button className="action mass__actions--btn" onClick={(e) => handleChangeAttendance(e, appointment.id)} title="Fjern fravær" disabled={markAttendanceLoading}>
                                                                <img src={editIcon} alt="Edit icon"/>
                                                            </Button>  
                                                        </td>
                                                    </tr>
                                                );})}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </section>
                        }
                        {groupedAppointments.length <= 0 && search && <p className="empty-result empty-result--lg empty-result--positive">Fant ingen kunder med det navnet.</p>}
                        {groupedAppointments.length <= 0 && !search && <p className="empty-result empty-result--lg empty-result--positive">Det finnes ingen fraværstimer.</p>}
                    </section>
                </div>
            </>}
        </section>
    );
};
 
export default AppointmentAttendance; 