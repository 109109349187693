import { useState, useEffect, useContext} from "react";
import { apiUrl } from "../../../../../../data/API";
import { getUserToken } from "../../../../../../data/token";
import AuthContext from "../../../../../../context/auth-context";
import useInput, { inputNotEmpty } from "../../../../../../hooks/useInput";
import { delayPromise } from "../../../../../../assets/DelayPromise";
import { TonnageErrorFeedback, getGrainData } from "../../../../../booking/BookingService";
import LeafLoader from "../../../../../../assets/LoadingSpinner";
import { checkIfUserExists } from "../../../../../../assets/GetUserCredentials";
import { Feedback, TimeoutFeedback } from "../../../../../../assets/Feedback";
import { formatDate, formatDay, formatTime } from "../../../../../../assets/DateFormatOptions";
import { capitalizeStr } from "../../../../../../assets/Capitalize";
import { getUserName } from "../../../../../../assets/GetUserCredentials";
import Button from "../../../../../UI/Button";

const EditAppointment = (props) => {
    const [app, setAppointment] = useState({});
    const [appointments, setAppointments] = useState([]);
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [grainId, setGrainId] = useState();
    const [grainError, setGrainError] = useState("");
    const [onSave, setOnSave] = useState("");
    const [onSuccess, setOnSuccess] = useState("");
    const [actionError, setActionError] = useState("");
    const [onSuccessDate, setOnSuccessDate] = useState("");
    const [onFailedDate, setOnFailedDate] = useState("");
    const [isChecked, setIsChecked] = useState();
    const [userNotFound, setUserNotFound] = useState(false);
    const [emptyCustomerName, setEmptyCustomerName] = useState(false);
    const [editData, setEditData] = useState(true);
    const [selectedDay, setSelectedDay] = useState("");
    const [publish, setPublish] = useState(false);

    const { hasError: userNameHasError, valueChangeHandler: userNameChangeHandler, reset: resetName } = useInput(inputNotEmpty);
    const { hasError: amountHasError, valueChangeHandler: amountValueChangeHandler, inputBlurValue: amountInputBlurValue } = useInput(inputNotEmpty);
    const { hasError: grainTypeIdHasError, valueChangeHandler: grainTypeValueChangeHandler, inputBlurValue: grainTypeInputBlurValue } = useInput(inputNotEmpty);
    const { hasError: varietyTypeIdHasError, valueChangeHandler: varietyTypeValueChangeHandler, inputBlurValue: grainVarietyInputBlurValue } = useInput(inputNotEmpty);
    const { valueChangeHandler: warehouseValueChangeHandler, inputBlurValue: warehouseInputBlurValue } = useInput(inputNotEmpty);
    const { valueChangeHandler: sprayedValueChangeHandler, inputBlurValue: sprayedInputBlurValue } = useInput(inputNotEmpty);
    const { valueChangeHandler: commentValueChangeHandler, inputBlurValue: commentInputBlurValue } = useInput(inputNotEmpty);
    const { value: changeDateValue, isValid: changeDateValid, hasError: changeDateHasError, valueChangeHandler: changeDateChangeHandler, inputBlurHandler: changeDateBlurHandler, reset: resetChangeDate } = useInput(inputNotEmpty);
    const { value: changeTimeValue, isValid: changeTimeValid, hasError: changeTimeHasError, valueChangeHandler: changeTimeChangeHandler, inputBlurHandler: changeTimeBlurHandler, reset: resetChangeTime } = useInput(inputNotEmpty);

    const userCtx = useContext(AuthContext);
    const tokenData = getUserToken();
    const appointment = props.app;
    const appointmentArray = props.appointments ? props.appointments : [];
    const extendedEdit = props.movable ? true : false;
    const appointmentMap = new Map();

    let customers;
    let setIsValid = false;
    let tonnageLimitReached = false; 
    
    if (props.customers) { customers = props.customers;}

    useEffect(() => {
        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setGrainError("Det oppsto en feil ved henting av korndata, vennligst prøv igjen.");}
        );
        setAppointment(appointment);
        setAppointments(appointmentArray);
        setIsChecked(appointment.warehouseRental);
        setGrainId(parseInt(appointment.grainTypeId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment, tokenData]);

    const handleReservedByChange = (event, app) => {
        event.persist();
        const { id, value } = event.target;

        let user = "";
        let updateCustomer; 

        if (value.length > 3 ) {
            const userExists = customers.find((n) => n.name === value);
            user = userExists !== undefined ? userExists : "";
            setEmptyCustomerName(false);
        } else {
            setEmptyCustomerName(true);
        }

        if (user) {
            updateCustomer = {...app, [id] : user.producerNr.toString()};
        } else { 
            updateCustomer = {...app, [id] : null};
        }   

        setAppointment(updateCustomer);
    }

    const handleChange = (event, app) => {
        event.persist(); 
        const { id, value } = event.target;

        let updateApp;

        if (event.target.type === 'checkbox')  {  
            updateApp = {...app, [id] : value === "false" ? false : true}; 
        } else {
            updateApp = {...app, [id] : value};
        }
        
        setAppointment(updateApp);
    }

    const handleGrain = (event, app) => {
        event.persist(); 
        const { id, value } = event.target;
        const isOats = grainMap.get(value.toString()).grainName.toLowerCase() === "havre";

        let updateGrainType;
        
        if (isOats) { 
            updateGrainType = {...app, [id] : parseInt(value), "varietyTypeId" : "", sprayed: true}
        }  else { 
            updateGrainType = {...app, [id] : parseInt(value), "varietyTypeId" : ""};
        } 

        setAppointment(updateGrainType);
        setGrainId(value);
    }

    const handleVariety = (event, app) => {
        event.persist(); 
        const { id, value } = event.target;
        const updateVariety = {...app, [id] : parseInt(value)};
        setAppointment(updateVariety);
    }

    if (appointments.length > 0) {
        appointments.forEach(appointmentDay => {
            appointmentMap.set(appointmentDay.day.toString(), {
                day: appointmentDay.day,
                options: <>
                    <option value={""}>Velg et ledig klokkeslett</option>
                    {appointmentDay.appointments.filter((a) => !a.reservedBy).map((appointment) => 
                        <option key={appointment.id} value={appointment.id}>{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</option>
                    )}
                </>
            });  
        });
    }

    const grainMap = new Map();
    grainVarieties.forEach(kornart => {
        grainMap.set(kornart.id.toString(), {
            grainName: kornart.name,
            hourlyMax: kornart.hourlyMax,
            appointmentLimit: kornart.appointmentMax,
            options: <>
                    <option value={""}>Velg kornsort</option>
                    {kornart.varieties.map((variety) => 
                        <option key={variety.id} value={variety.id}>{capitalizeStr(variety.name)}</option>
                    )}
                </>
        });  
    });

    const isTonnageOverAppointmentLimit = (app) => {
        tonnageLimitReached = app.grainTypeId && grainMap.get(grainId.toString()).appointmentLimit < app.amount;

        if (tonnageLimitReached) { setIsValid = false; }

        return tonnageLimitReached;
    }
 
    const updateAppointemnt = (event) => {
        event.preventDefault();
        let putUrl;

        if (grainId !== null && grainMap.get(grainId.toString()).grainName.toLowerCase() === "havre") {
            const value = app.sprayed ? true : false;
            const sprayedValue = value;
            putUrl = `?optionalAmount=${parseInt(app.amount)}&optionalComment=${app.comment ? app.comment : ""}&optionalGrainTypeId=${parseInt(app.grainTypeId)}${app.varietyTypeId ? `&optionalVarietyTypeId=${parseInt(app.varietyTypeId)}` : ``}&optionalWarehouseRental=${app.warehouseRental}&optionalSprayed=${sprayedValue}${userCtx.isAdmin ? `&optionalUsername=${app.reservedBy}` : ``}`
        } else {
            putUrl = `?optionalAmount=${parseInt(app.amount)}&optionalComment=${app.comment ? app.comment : ""}&optionalGrainTypeId=${parseInt(app.grainTypeId)}${app.varietyTypeId ? `&optionalVarietyTypeId=${parseInt(app.varietyTypeId)}` : ``}&optionalWarehouseRental=${app.warehouseRental}${userCtx.isAdmin ? `&optionalUsername=${app.reservedBy}` : ``}`
        }

        if (setIsValid) {
            setOnSave("Oppdaterer time ...");
            setOnSuccess("");
            fetch(apiUrl + `appointments/${app.id}` + putUrl, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${tokenData}`,
                    "Content-Type": "application/json"
                }
            }).then(delayPromise(2000))
            .then((response) => {
                setOnSave("");
                if (response.ok) {
                    setOnSuccess("Timen ble oppdatert!");
                    setActionError("");
                    props.updateChangesToState(app); 
                    props.onHide();
                    return response.text();
                } else {
                    setOnSuccess("");
                    return response.json().then((data) => {
                        if (data.message === "APPOINTMENT_HAS_PASSED") { 
                            setActionError("Det oppsto en feil ved oppdatering av timen; denne timen er utgått og kan ikke endres.");
                        } else if (data.message === "GRAIN_DOES_NOT_EXIST") {
                            setActionError("Det oppsto en feil ved oppdatering av timen, kornarten du har valgt eksisterer ikke. Vennligst last inn siden på nytt og prøv igjen.");
                        } else if (data.message === "VARIETY_DOES_NOT_EXIST") {
                            setActionError("Det oppsto en feil ved oppdatering av timen, kornsorten du har valgt eksisterer ikke. Vennligst last inn siden på nytt og prøv igjen.");
                        } else if (data.message === "AMOUNT_EXCEEDS_THRESHOLD") {
                            setActionError("Det oppsto en feil ved oppdatering av timen, mengden du har satt har overskredet grensen - eller så er en ugyldig mengde oppgitt (symboler/tekst).");
                        } else if (data.message === "ILLEGAL_WAREHOUSE_RENTAL") {
                            setActionError("Du har ikke mulighet til å bestille lagerleie. Ta kontakt med oss for å signere avtale om lagerleie.");
                        } else {
                            setActionError("Det oppsto en feil ved oppdatering av timen, last inn siden på nytt og prøv igjen.");
                        }
                    });
                };                
            }).catch(() => {
                setOnSave("");
                setOnSuccess("");
                setActionError("Det oppsto en feil ved oppdatering av timen, last inn siden på nytt og prøv igjen.");
            });
        } else {
            return;
        };
    };

    const updateAppointmentTime = (event) => {
        event.preventDefault();
        
        const newId = parseInt(changeTimeValue);
        setOnSuccessDate("");
        setOnFailedDate("");

        if (changeDateValid && changeTimeValid) {
            setOnSave("Oppdaterer time ...");
            fetch(apiUrl + `appointments/${app.id}/move-to?destinationAppointmentId=${newId}&unpublishSourceAppointment=${publish}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${tokenData}`,
                    "Content-Type": "application/json"
                }
            }).then(delayPromise(2000))
            .then((response) => {
                setOnSave("");
                if (response.ok) {
                    setOnSuccessDate("Timen ble oppdatert!");
                    props.moveAppointment(app, newId, changeDateValue, publish); 
                    props.onHide();
                    resetChangeDate();
                    resetChangeTime();
                    return response.text();
                } else {
                    setOnSuccessDate("");
                    return response.json().then((data) => {
                        if (data.message === "APPOINTMENT_HAS_PASSED") { 
                            setOnFailedDate("Det oppsto en feil ved oppdatering av timen; denne timen er utgått og kan ikke endres.");
                        } else if (data.message === "APPOINTMENT_NOT_RESERVED") {
                            setOnFailedDate("Du kan ikke flytte en time som ikke er reservert.");
                        } else if (data.message === "APPOINTMENT_ALREADY_RESERVED") {
                            setOnFailedDate("Kunne ikke flytte reservert time til valgt time - den er allerede reservert.");
                        } else if (data.message === "APPOINTMENT_ALREADY_TAPPED") {
                            setOnFailedDate("Kunne ikke flytte reservert time til valgt time - den er valgt av noen andre.");
                        } else if (data.message === "AMOUNT_EXCEEDS_THRESHOLD") {
                            setActionError("Det oppsto en feil ved oppdatering av timen, mengden du har satt har overskredet grensen - eller så er en ugyldig mengde oppgitt (symboler/tekst).");
                        } else {
                            setOnFailedDate("Det oppsto en feil ved oppdatering av timen, last inn siden på nytt og prøv igjen.");
                        };
                    });
                };                
            }).catch(() => {
                setOnSave("");
                setOnSuccessDate("");
                setOnFailedDate("Det oppsto en feil ved oppdatering av timen, last inn siden på nytt og prøv igjen.");
            });
        } else {
            return;
        };
    }

    const clearSuccessState = () => {
        setOnSuccess("");
    }

    if (app && grainVarieties.length >= 1) {
        const updatingZeroVarieties = grainVarieties.find((g) => g.id === app.grainTypeId).varieties.length <= 0; 
        
        if (updatingZeroVarieties ? (!amountHasError && !grainTypeIdHasError) : (!amountHasError && !grainTypeIdHasError && !varietyTypeIdHasError)) {
            setIsValid = true;
        }

        if (updatingZeroVarieties ? (app.grainTypeId === " " || app.amount === 0 || app.amount === "") : (app.varietyTypeId === 0 || app.varietyTypeId === "" || app.grainTypeId === " " || app.amount === 0 || app.amount === "")) {
            setIsValid = false;
        }

        if (userCtx.isAdmin && (app.reservedBy === "" || app.reservedBy <= 0 || userNotFound)) {
            setIsValid = false;
        }
    } 

    const clearReservedByInput = (event) => {
        event.preventDefault();

        setEmptyCustomerName(true);
        resetName();
        setAppointment((app) => { return {...app, reservedBy : "" }})
        setUserNotFound(false);
        document.getElementById("reservedBy").value = "";
    }

    return (<>
        <div className="modal__form">
            <div className={`modal__form__header ${userCtx.isAdmin ? " modal__form__header--centered" : undefined}`}>
                <h1>Endre time</h1>
            </div>

            {(userCtx.isAdmin && extendedEdit) && 
                <div className="modal__form__choice">
                    <Button className={`text__btn ${editData ? "active" : ""}`} onClick={() => setEditData(true)}>Endre informasjon</Button>
                    {" / "}
                    <Button className={`text__btn ${!editData ? "active" : ""}`} onClick={() => setEditData(false)}>Endre tid</Button>
                </div>    
            }

            {(userCtx.isAdmin && !editData && extendedEdit) &&
                <div className="modal__form__content">   
                    {app && grainId && (grainVarieties && grainVarieties.length >= 1) && <>
                        <h2>Flytt time til nytt tidspunkt</h2>
                        <h3>Gjelder time: <span>{formatTime(app.scheduledAt, {hour: '2-digit', minute: '2-digit'})} {capitalizeStr(formatDay(app.scheduledAt, {weekday: 'long'}))} ({formatDate(app.scheduledAt)})</span></h3>
                        
                        <div className="modal__form__item">    
                            <label htmlFor="day">Velg dag<span className="required" title="Påkrevd felt">*</span></label>    
                            <div> 
                                <select id="day" name="day" required value={changeDateValue} 
                                    onChange={(e) => {changeDateChangeHandler(e); setSelectedDay(e.target.value)}} onBlur={changeDateBlurHandler}
                                    className={`form__item__step--select ${changeDateHasError ? "invalid" : ""}`}>
                                    <option value="">Velg en dag</option>
                                    {appointments.map((a) => (
                                        <option key={a.day} value={a.day}>
                                            {formatDate(a.day)}
                                        </option>
                                    ))}
                                </select>
                                {changeDateHasError && <span className="form__item__step--error">Dag mangler.</span>}
                            </div> 
                        </div> 

                        <div className="modal__form__item">    
                            <label htmlFor="time">Velg ny tid<span className="required" title="Påkrevd felt">*</span></label>    
                            <div> 
                                <select id="time" name="time" required value={changeTimeValue} disabled={!changeDateValue}
                                    onChange={(e) => changeTimeChangeHandler(e)} onBlur={changeTimeBlurHandler}
                                    className={`form__item__step--select ${changeTimeHasError ? "invalid" : ""}`}>
                                    {appointmentMap.has(selectedDay) && appointmentMap.get(selectedDay).options}
                                </select>
                                {changeTimeHasError && <span className="form__item__step--error">Ny tid mangler.</span>}
                            </div> 
                        </div> 

                        <div className="modal__form__item item__inline">    
                            <label htmlFor="published">Avpubliser gammel time</label>    

                            <div> 
                                <input type="checkbox" id="published" name="published" checked={publish} 
                                    onChange={() => setPublish((prev) => !prev)} className="checkmark"/> 
                            </div> 
                        </div> 

                        <div className="modal__form__feedback">
                            <Button onClick={(e) => (changeDateValid && changeTimeValid) ?  updateAppointmentTime(e) : undefined} disabled={(!changeDateValid || !changeTimeValid)} title={(changeDateValid && changeTimeValid) ? "Lagre ny tid og oppdater timen" : "Du må rette opp feil før du kan lagre endringer"} 
                                className="confirm button__mr">Lagre endringer
                            </Button>
                            <Button className="plain" type="button" title="Lukk visning" onClick={props.onHide}>Avbryt</Button>

                            {onSave && <Feedback class="feedback--center topped" feedbackClass="feedback--loading" message={onSave}/>}
                            {onSuccessDate && <TimeoutFeedback class="topped" clearMessage={clearSuccessState} feedbackClass="feedback--success" message={onSuccessDate}/>}
                            {onFailedDate && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={onFailedDate}/>}
                            {grainError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={grainError}/>}
                        </div>
                    </>}
                    {app && (grainVarieties.length <= 0) && <div className="topped"><LeafLoader loadingMessage="Laster inn time ..."/></div>}
                    {!app && <div className="topped"><Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto et problem ved endring av time, vennligst last inn siden på nytt."}/></div>}
                </div>          
            } 

            {editData && 
                <div className="modal__form__content">   
                    {app && grainId && (grainVarieties && grainVarieties.length >= 1) && <>
                        <h2>{formatDate(app.scheduledAt)} <span>{capitalizeStr(formatDay(app.scheduledAt, {weekday: 'long'}))} {formatTime(app.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</span></h2>

                        {(userCtx.isAdmin && customers) &&
                            <div className="modal__form__item modal__form__item--topped">
                                <label htmlFor="reservedBy">Kunde <span className="required" title="Påkrevd felt">*</span></label>
                                <div>
                                    <input type="text" defaultValue={app.reservedBy ? getUserName(customers, app.reservedBy) : ""} key={app.reservedBy ? getUserName(customers, app.reservedBy) : ""} 
                                        onChange={(e) => {handleReservedByChange(e, app); userNameChangeHandler(e);}} list="customers" name="reservedBy" id="reservedBy" 
                                        className={`form__item__step--input ${userNameHasError && "invalid"}`} onBlur={(e) => checkIfUserExists(e, customers, setUserNotFound)} 
                                    />
                                    <datalist id="customers">
                                        {customers.map((customer) => (
                                            <option key={customer.name} value={customer.name}>
                                                {customer.name}
                                            </option>
                                        ))}
                                    </datalist>
                                    <span className="button__clear" title="Tøm kundesøk" onClick={(e) => clearReservedByInput(e)}>x</span>

                                    {userNotFound && <span className="form__item__step--error">Kunne ikke finne en kunde med det brukernavnet, prøv igjen.</span>}
                                    {!userNotFound && (userNameHasError || emptyCustomerName) && <span className="form__item__step--error">Kundens brukernavn mangler.</span>}   
                                </div> 
                            </div>
                        }
                        <div className={`modal__form__item ${userCtx.isAdmin ? "" : "modal__form__item--topped"}`}>
                            <label htmlFor="grainTypeId">Kornart <span className="required" title="Påkrevd felt">*</span></label>
                            <div>
                                <select id="grainTypeId" name="grainTypeId" required defaultValue={app.grainTypeId} 
                                    onChange={(e) => {handleGrain(e, app); grainTypeValueChangeHandler(e)}} onBlur={grainTypeInputBlurValue}
                                    className={`form__item__step--select ${grainTypeIdHasError ? "invalid" : ""}`}>
                                    {grainVarieties.map(type => (
                                        <option key={type.id} value={type.id}>
                                            {capitalizeStr(type.name)}
                                        </option>
                                    ))}
                                </select>
                                {grainTypeIdHasError && <span className="form__item__step--error">Kornart mangler.</span>}
                            </div> 
                        </div>

                        {app?.grainTypeId && grainMap.get(grainId.toString()).grainName.toLowerCase() === "havre" && 
                            <div className="modal__form__item item__inline">    
                                <label htmlFor="sprayed">Kornet er ikke sprøytet med glyfosat</label>    
                                <div> 
                                    <input type="checkbox" id="sprayed" name="sprayed" onBlur={sprayedInputBlurValue}
                                        checked={app.sprayed ? false : true} defaultValue={app.sprayed ? false : true}
                                        onChange={(e) => {handleChange(e, app); sprayedValueChangeHandler(e)}} className="checkmark"/> 
                                </div> 
                            </div> 
                        }
                        
                        <div className="modal__form__item">      
                            <label htmlFor="varietyTypeId">Kornsort <span className="required" title="Påkrevd felt">*</span></label>                 
                            {grainVarieties.find((g) => g.id === app.grainTypeId).varieties.length <= 0 
                                ? <div><span className="form__tip">Denne arten har ingen sorter.</span></div>
                                :
                                <div>
                                    <select id="varietyTypeId" name="varietyTypeId" required defaultValue={app.varietyTypeId} 
                                        onChange={(e) => {handleVariety(e, app); varietyTypeValueChangeHandler(e)}} onBlur={grainVarietyInputBlurValue}
                                        className={`form__item__step--select ${varietyTypeIdHasError ? "invalid" : ""}`}>
                                        {grainMap.has(grainId.toString()) && grainMap.get(grainId.toString()).options}
                                    </select>  
                                    {varietyTypeIdHasError && <span className="form__item__step--error">Kornsort mangler.</span>}
                                </div> 
                            }
                        </div>
                        <div className="modal__form__item">    
                            <label htmlFor="amount">Mengde <span>(tonn)</span><span className="required" title="Påkrevd felt">*</span></label>    
                            <div> 
                                <input type="number" autoComplete="off" id="amount" defaultValue={app.amount} min="1" max="100" name="amount"
                                    onChange={(e) => {amountValueChangeHandler(e); handleChange(e, app)}} onBlur={amountInputBlurValue}
                                    className={`form__item__step--input ${amountHasError ? "invalid" : ""}`}/>
                                {(amountHasError || app.amount === 0) && <span className="form__item__step--error">Mengde (tonn) mangler.</span>}
                                {app.grainTypeId && isTonnageOverAppointmentLimit(app) && app.amount > 0 ? 
                                    <TonnageErrorFeedback amount={app.grainTypeId && grainMap.get(grainId.toString()).appointmentLimit} type={grainMap.get(grainId.toString()).grainName.toLowerCase()}/> : "" 
                                }
                            </div> 
                        </div> 
                        <div className="modal__form__item">    
                            <label htmlFor="comment">Kommentar</label>    
                            <div> 
                                <input type="text" id="comment" autoComplete="off" defaultValue={app.comment ? app.comment : ""} max="255" name="comment"
                                    onChange={(e) => {handleChange(e, app); commentValueChangeHandler(e)}} onBlur={commentInputBlurValue}
                                    className="form__item__step--input"/>
                            </div> 
                        </div> 
                        <div className="modal__form__item item__inline">    
                            <label htmlFor="warehouseRental">Lagerleie</label>    
                            <div> 
                                <input type="checkbox" id="warehouseRental" name="warehouseRental" onBlur={warehouseInputBlurValue}
                                    checked={app.warehouseRental || isChecked } defaultValue={!isChecked}
                                    onChange={(e) => {handleChange(e, app, "warehouse"); warehouseValueChangeHandler(e); setIsChecked((prev) => !prev)}} className="checkmark"/> 
                            </div> 
                        </div> 
                        <div className="modal__form__feedback">
                            <Button onClick={(e) => !setIsValid ? undefined : updateAppointemnt(e)} disabled={!setIsValid} title={setIsValid ? "Lagre endringer og oppdater timen" : "Du må rette opp feil før du kan lagre endringer"} 
                                className="confirm button__mr">Lagre endringer
                            </Button>
                            <Button className="plain" type="button" title="Lukk visning" onClick={props.onHide}>Avbryt</Button>
                        
                            {onSave && <Feedback class="feedback--center topped" feedbackClass="feedback--loading" message={onSave}/>}
                            {onSuccess && <TimeoutFeedback class="topped" clearMessage={clearSuccessState} feedbackClass="feedback--success" message={onSuccess}/>}
                            {actionError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={actionError}/>}
                            {grainError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={grainError}/>}
                        </div>
                    </>}
                    {app && (grainVarieties.length <= 0) && <div className="topped"><LeafLoader loadingMessage="Laster inn time ..."/></div>}
                    {!app && <div className="topped"><Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto et problem ved endring av time, vennligst last inn siden på nytt."}/></div>}
                </div>
            }
        </div>
    </>);
};

export default EditAppointment;