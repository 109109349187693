import { Fragment, useEffect, useState, lazy, Suspense } from "react";
import { getUserToken } from "../../../../../../data/token";
import useFetch from "../../../../../../hooks/useFetch";
import fetchMultiple from "../../../../../../assets/FetchMultiple";
import { Link } from "react-router-dom";
import { getGrainData, getConfiguration } from "../../../../../booking/BookingService";
import { getUserName } from "../../../../../../assets/GetUserCredentials";
import { formatDate, formatTime, formatDay } from "../../../../../../assets/DateFormatOptions";
import useConfirm from "../../../../../../hooks/useConfirm";
import LeafLoader from "../../../../../../assets/LoadingSpinner";
import { Feedback, TimeoutFeedback, ErrorFeedback } from "../../../../../../assets/Feedback";
import { hideHandler } from "../../../../../layout/MainLayout";
import { ActionModal } from "../../../../../UI/Modal";
import { findGrainTypeName, findVarietyName, isSprayed } from "../../../../user/UserService";
import TableHead, { getRefreshToken, untapMultiple, removeTapError, removeExpiredError } from "./AppointmentBoardHelpers";
import { updatePublishState, updatePublishStatusMultiple, updatePublisAtMultiple, changeMultipleStatesHandler} from "./AppointmentBoardHelpers";
import { updateAppointmentState, updateAppointmentMovingState, updateOrderState, setDateFilter, handleCheckboxClick, uncheckDay, checkboxDay} from "./AppointmentBoardHelpers";
import { removeAllChecked, removeFromState, deleteOne, deleteSelected, cancelAppointmentHandler} from "./AppointmentBoardHelpers";
import { getErrorStatusMessage } from "../../../../../../assets/ResponseFeedback";
import { onFocusShowPicker } from "../../../../../UI/Input";
import Button from "../../../../../UI/Button";
import PrintButton from "../../../../../UI/PrintButton";
import attentionIcon from "../../../../../../assets/icons/error_FILL1_wght500_GRAD0_opsz40.svg";
import editIcon from "../../../../../../assets/icons/edit_note_FILL1_wght400_GRAD0_opsz40.svg";
import deleteIcon from "../../../../../../assets/icons/delete_forever_FILL1_wght400_GRAD0_opsz40.svg";
import cancelIcon from "../../../../../../assets/icons/event_busy_black_24dp.svg";
import publish from "../../../../../../assets/icons/publish_black_24dp.svg";
import unPublish from "../../../../../../assets/icons/unpublished_black_24dp.svg";
import updatePublish from "../../../../../../assets/icons/published_with_changes_black_24dp.svg";
import refresh from "../../../../../../assets/icons/refresh_FILL1_wght500_GRAD0_opsz48.svg";
import searchIcon from "../../../../../../assets/icons/search_black_24dp.svg";
import checkmark from "../../../../../../assets/icons/check_black_24dp.svg";
import readComment from "../../../../../../assets/icons/comment_black_24dp.svg";
import help from "../../../../../../assets/icons/help_outline_black_24dp.svg";
import RadioButton from "../../../../../UI/RadioButton";

export const refreshToken = getRefreshToken();

const ExportAppointmentData = lazy(() => import("./AppointmentBoardExport"));
const AppointmentBoardMassOrder = lazy(() => import("./AppointmentBoardMassOrder"));
const EditAppointment = lazy(() => import("./AppointmentBoardEditAppointment"));
const ShowComment = lazy(() => import("./AppointmentBoardComment"));
const EditPublishState = lazy(() => import("./AppointmentBoardEditPublish"));
const HelpSection = lazy(() => import("./AppointmentBoardHelpSection"));

const AppointmentBoard = () => {
    const [allAppointments, setAllAppointments] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [showUpdate, setShowUpdate] = useState(false);
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");
    const [fetchError, setFetchError] = useState("");
    const [showChangeMultiple, setShowChangeMultiple] = useState(false);
    const [showOrderMultiple, setShowOrderMultiple] = useState(true);
    const [showExportModal, setShowExportModal] = useState(false);
    const [search, setSearch] = useState(""); 
    const [filter, setFilter] = useState(""); 
    const [showAllAppointments, setShowAllAppointments] = useState(false);
    const [showUpdatePublishing, setShowUpdatePublishing] = useState(false);
    const [modalId, setModalId] = useState(null); 
    const [userModal, setUserModal] = useState(""); 
    const [showComment, setShowComment] = useState(false);
    const [showUpdateDateInput, setShowUpdateDateInput] = useState(false);
    const [actionErrorMultiple, setActionErrorMultiple] = useState("");
    const [actionOnSaveMultiple, setActionOnSaveMultiple] = useState("");
    const [actionSuccessMultiple, setActionSuccessMultiple] = useState("");
    const [updateMultiplePublishAt, setUpdateMultiplePublishAt] = useState(null);
    const [actionDeleteError, setActionDeleteError] = useState([]);
    const [isChecked, setIsChecked] = useState([]);
    const [selectedDay, setSelectedDay] = useState({selected: []});
    const [selectedMassOption, setSelectedMassOption] = useState("0");
    const [customerName, setCustomerName] = useState("");
    const [tappedError, setTappedError] = useState("");
    const [tapError, setTapError] = useState([]);
    const [loadingOverlay, setLoadingOverlay] = useState([]);
    const [showHelp, setShowHelp] = useState(false);
    const [expiredAppointments, setExpiredAppointments] = useState([]);
    const [tapDisabled, setTapDisabled] = useState([]);
    const [disableCheckboxes, setDisableCheckboxes] = useState(false);
    const [refreshScroll, setRefreshScroll] = useState(false);

    const tokenData = getUserToken();

    const {confirm} = useConfirm();

    const { data: customers, customerLoading, customerError} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const fetchCombineAppointments = async () => {
        fetchMultiple('appointments', 'appointments/reserved', tokenData, setAllAppointments, setLoading, setError, setFilter, filter, setRefreshScroll);
    };

    let filteredData; 
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    useEffect(() => {
        fetchMultiple('appointments', 'appointments/reserved', tokenData, setAllAppointments, setLoading, setError, setFilter)
        
        getConfiguration(tokenData).then(response => 
            setConfiguration({threshold: response.cancellationThreshold, unpublishOnCancel: response.cancellationCausesUnpublish})
        ).catch((error) => { 
            setFetchError("Det oppsto et problem ved innlasting av grenseverdi til kansellering. Forsøk å laste inn siden på nytt.");}
        );

        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setFetchError("Det har oppstått en feil ved henting av korntyper. Forsøk å laste inn siden på nytt.")}
        );
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tokenData]);

    useEffect(() => {
        if (refreshScroll) {
            handleScrollPosition();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshScroll]);

    const updateAppointmentStateHandler = (app) => {
        updateAppointmentState(app, allAppointments, setAllAppointments);
    }; 

    const moveAppointmentsInState = (appointment, newId, newDate, publishState) => {
        updateAppointmentMovingState(appointment, newId, newDate, publishState, allAppointments, setAllAppointments);
    }

    const publishStateHandler = (checked, status, app) => {
        updatePublishState(checked, status, app, allAppointments, setAllAppointments);
    }; 

    const orderStateHandler = (orderIds, data, responses, commented) => {
        updateOrderState(orderIds, data, responses, allAppointments, setAllAppointments, setIsChecked, commented, setTapError, setExpiredAppointments, tokenData);
    }; 

    const changeFilterHandler = (event) => {
        event.persist();
        const value = event.target.value;
        setDateFilter(setFilter, value, setSearch);
        setIsChecked([]);
        setSelectedDay({selected: []});

        if (value && value === "showAllAvailableDays") {
            setShowAllAppointments(true);
        } else if (value && value !== "showAllAvailableDays") {
            setShowAllAppointments(false);
        };
    };

    const changeMultipleAppointmentHandler = (event) => {
        event.persist();
        const value = event.target.value;
        changeMultipleStatesHandler(value, setShowChangeMultiple, setShowOrderMultiple, setShowUpdateDateInput, setSelectedMassOption);
    };

    const changeWorkflowHandler = (event) => {
        const value = event.target.value;

        if (isChecked.length > 0) {
            untapMultiple(isChecked, setTappedError, tokenData, tapError);
        }; 

        changeMultipleAppointmentHandler(event); 
        setSelectedMassOption(value);
        setCustomerName("");
        setSelectedDay({selected: []});
        setSearch("");

        if (value === "0" || value === "1" ) {
            setIsChecked([]);
        };
    }

    const WorkFlowMenu = ({id, name, onClick}) => {
        return (
            <div className="overview__menu--checkbox">
                <label><span className="bold">{isChecked.length}</span> {isChecked.length === 1 ? "time " : "timer "} valgt</label>
                <input type="checkbox" id={id} title="Tøm liste med valgte timer" name={name} disabled={disableCheckboxes} onClick={onClick}  />
                <label htmlFor={name} title="Tøm liste med valgte timer">Fjern valgte timer</label>
            </div>
        )
    }
    
    const refreshData = () => {
        const dataTable = document.querySelector("#overviewTable");
        localStorage.setItem("scrollPosition", dataTable.scrollTop);

        if (isChecked.length > 0) {
            untapMultiple(isChecked, setTappedError, tokenData, tapError);
            setIsChecked([]);
        };
        
        fetchCombineAppointments(); 
        setTapError([]);
    }

    const handleScrollPosition = () => {
        const scrollPosition = localStorage.getItem("scrollPosition");
        const dataTable = document.querySelector("#overviewTable");

        if (scrollPosition) {
            dataTable.scrollTo(0, parseInt(scrollPosition));
        };

        localStorage.setItem("scrollPosition", []);
        setRefreshScroll(false);
    };

    const calculateTableClasses = () => {
        let filterShowAll = (filter === "showAllAvailableDays") ? true : false;
        let onEditMode = (!showOrderMultiple && !showChangeMultiple) ? true : false;

        if (filterShowAll && allAppointments.length > 1 ) {
            return "expandVH";
        } else if (filterShowAll && onEditMode && allAppointments.length === 1) {
            return"singleEdit";
        } else if (!filterShowAll && onEditMode && allAppointments.length > 0) {
            return "singleEdit";
        } else {
            return "";
        }
    };

    if ((loading && customerLoading) || customerLoading || loading) loadingState = true;

    if (error || customerError || fetchError) errorState = true;

    if (allAppointments && (customers && customers.length > 0) && (grainVarieties && grainVarieties.length > 0) && configuration) renderState = true;
    
    if (allAppointments && filter) {
        filteredData = showAllAppointments ? allAppointments : allAppointments.filter(day => { return day.day === filter});
    };
    
    if (search && allAppointments.length > 0) {
        const getAllAppointments = allAppointments.map((a) => a.appointments);
        const getReservedAppointments = [].concat(...getAllAppointments).filter((r) => r.reservedBy);

        const groupByUserName = getReservedAppointments.reduce((groupByUserName, data) => {
            let user = getUserName(customers, data.reservedBy.toString());
            (groupByUserName[user] = groupByUserName[user] || []).push(data);
    
            return groupByUserName;
        }, {});
    
        const groupAppointmentsByUser = Object.keys(groupByUserName).map((user) => { 
            return { user, appointments: groupByUserName[user] };
        }); 

        filteredData = search ? groupAppointmentsByUser.filter((u) => u.user.toLowerCase().includes(search.toLowerCase())) : groupAppointmentsByUser;
    };

    return (
        <section className={`contents__container ${!errorState && renderState ? "tableView" : undefined} ${showChangeMultiple && isChecked.length > 0 ? "liftedLayout" : undefined} ${showOrderMultiple && isChecked.length > 0 ? "liftedLayout liftedLayout--lg" : undefined} ${!showChangeMultiple && !showOrderMultiple ? "expandedTable" : undefined}`} >          
            {((loadingState || !renderState) && !errorState) && <LeafLoader loadingMessage="Laster inn timer ..."/>}
            {errorState && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Det oppsto en feil ved henting av data, vennligst last inn siden på nytt."}/>}
            
            {(!loadingState && !errorState) && renderState &&
                <div className="overview">        
                    <div className={`overview__intro ${showHelp ? "show" : ""}`}> 
                        <p>Dette er en oversikt over alle ledige (publiserte og ikke publiserte), samt bestilte timer sortert i en tabell basert på dato.</p>    
                        
                        <div className="overview__intro__wrapper">
                            <Suspense>
                                <HelpSection/>
                            </Suspense>
                        </div>
                    </div>

                    <div className="overview__banner">
                        <h1>Timeovervåker</h1>
                        
                        <div className="overview__banner__filter">
                            <div className="overview__banner__filter--dates">
                                <label htmlFor="filterDate">Grupper timer etter dato</label>
                                <select id="filterDate" value={filter} disabled={allAppointments.length <= 0} name="filterDate" onChange={changeFilterHandler} className="form__item__step--select">
                                    {allAppointments.map((date, index) => (
                                        <option key={index} value={date.day}>{formatDate(date.day)} - {formatDay(date.day, {weekday: 'long'})}</option>
                                    ))}
                                    <option value="showAllAvailableDays">Vis for alle dager</option>
                                </select>  
                            </div>

                            <div className="overview__banner__filter--search">
                                <label htmlFor="searchCustomer">Sorter timer på kunder</label>
                                <div className="searchbar">
                                    <input placeholder="Søk etter kunder ..." disabled={allAppointments.length <= 0} autoComplete="off" type="text" id="searchCustomer" name="searchCustomer" onChange={(e) => setSearch(e.target.value)} value={search ? search : ""}/>
                                    <img src={searchIcon} alt="Search icon"/>
                                    <span className="button__clear" title="Tøm kundesøk" onClick={() => {setSearch(""); setSelectedDay({selected: []})}}>x</span>
                                </div>
                            </div>
                        </div>

                        <div className="overview__banner__radio">
                            <label htmlFor="workflowDD">Arbeidsflyt</label>

                            <select id="workflowDD" name="workflowDD" disabled={allAppointments.length <= 0} value={selectedMassOption} onChange={(e) => changeWorkflowHandler(e)} className="form__item__step--select">
                                <option value="0">Bestilling</option>
                                <option value="1">Enkeltendring</option>
                                <option value="2">Masseendring</option>
                            </select>  
                            
                            <div id="workflowRadio">
                                <RadioButton htmlFor="massOrder" title="Bestilling" disabled={allAppointments.length <= 0} id="massOrder" name="massOrder" onChange={(e) => changeWorkflowHandler(e)}
                                    className={`${selectedMassOption === "0" && "checked"} ${allAppointments.length <= 0 && "disabled"}`} checked={selectedMassOption === "0"} value="0"/>
                                <RadioButton htmlFor="singleEdit" title="Enkeltendring" disabled={allAppointments.length <= 0} id="singleEdit" name="singleEdit" onChange={(e) => changeWorkflowHandler(e)}
                                    className={`${selectedMassOption === "1" && "checked"} ${allAppointments.length <= 0 && "disabled"}`} checked={selectedMassOption === "1"} value="1"/>
                                <RadioButton htmlFor="massEdit" title="Masseendring" disabled={allAppointments.length <= 0} id="massEdit" name="massEdit" onChange={(e) => changeWorkflowHandler(e)}
                                    className={`${selectedMassOption === "2" && "checked"} ${allAppointments.length <= 0 && "disabled"}`} checked={selectedMassOption === "2"} value="2"/>
                            </div>
                        </div>

                        <div className="overview__banner__refresh">
                            <div>
                                <label>Oppfrisk data</label>
                                <button className="text__btn" onClick={() => refreshData()} title="Last inn timedata på nytt">
                                    Manuelt                                         
                                    <img src={refresh} className="spin" alt="Refresh icon" title="Oppfrisk timedata nå"/> 
                                </button>
                            </div>
                            <div>
                                <label>Hjelp</label>
                                <button className="text__btn" onClick={() => setShowHelp((prev) => !prev)} title="Vis hjelpeseksjon">
                                    Vis
                                    <img src={help} alt="Help icon"/>
                                </button>
                                <span className="contents__container--help" title="Vis informasjon om bruk av timeovervåkeren" onClick={() => setShowHelp((prev) => !prev)}>{showHelp ? "Lukk hjelp" : ""}</span>
                            </div>
                        </div>

                    </div>

                    {allAppointments.length > 0 && showChangeMultiple &&
                        <div className="overview__menu">
                            <div className="overview__menu--buttons">
                                {isChecked.length === 0 ? 
                                    <p>Du må velge minst <span className="bold">én</span> time før du kan foreta endringer.</p> 
                                    : 
                                    <WorkFlowMenu id={"emptyAll"} name={"emptyAll"} onClick={() => {removeAllChecked(setIsChecked); setShowUpdateDateInput(false); setSelectedDay({selected: []})}}/>
                                }

                                <div>
                                    {!showUpdateDateInput && <>
                                        <Button className="text__btn action" disabled={!showChangeMultiple || showUpdateDateInput || isChecked.length === 0} onClick={(e) => 
                                            updatePublishStatusMultiple(e, "publish", isChecked, allAppointments, setAllAppointments, setActionErrorMultiple, setActionOnSaveMultiple, setActionSuccessMultiple, setIsChecked, tokenData, setTapError, setExpiredAppointments, setSelectedDay)}
                                            title={!showChangeMultiple || isChecked.length === 0 ? "Velg minst en time først" : "Publiser valgte timer øyeblikkelig"}>
                                            <img className="sm" src={publish} alt="Publish icon"/>Publiser valgte
                                        </Button>
                                        
                                        <Button className="text__btn action" disabled={!showChangeMultiple || showUpdateDateInput || isChecked.length === 0} onClick={(e) => 
                                            updatePublishStatusMultiple(e, "unpublish", isChecked, allAppointments, setAllAppointments, setActionErrorMultiple, setActionOnSaveMultiple, setActionSuccessMultiple, setIsChecked, tokenData, setTapError, setExpiredAppointments, setSelectedDay)}
                                            title={!showChangeMultiple || isChecked.length === 0 ? "Velg minst en time først" : "Avpubliser valgte timer øyeblikkelig"}>
                                            <img className="sm" src={unPublish} alt="Unpublish icon"/>Avpubliser valgte 
                                        </Button>

                                        <Button className="text__btn action" disabled={!showChangeMultiple || showUpdateDateInput || isChecked.length === 0}
                                            onClick={() => deleteSelected(isChecked, confirm, allAppointments, setAllAppointments, setActionErrorMultiple, setActionOnSaveMultiple, setIsChecked, setActionSuccessMultiple, filter, setFilter, tokenData, setTapError)} title={!showChangeMultiple || isChecked.length === 0 ? "Velg minst en time først" : "Slett valgte timer"}>
                                            <img className="sm" src={deleteIcon} alt="Delete icon"/>Slett valgte
                                        </Button> 
                                    </>}
                                
                                    {!showUpdateDateInput ? 
                                        <Button className={`text__btn action ${showUpdateDateInput ? "text__btn--exit" : ""}`} disabled={!showChangeMultiple || isChecked.length === 0} onClick={() => setShowUpdateDateInput(showUpdateDateInput => !showUpdateDateInput)} 
                                            title={!showChangeMultiple || isChecked.length === 0 ? "Velg minst en time først" : "Endre eller sett tidspunkt for når timer skal publiseres"}>
                                            <img className="sm" src={updatePublish} alt="Change publish status icon"/> Endre publiseringstidspunkt
                                        </Button>
                                    : ""} 
                                 </div>
                            </div>
                            <div className="overview__menu--content">
                                {showUpdateDateInput && 
                                    <div>
                                        <label htmlFor="publishAt">Sett publiseringstidspunkt </label>
                                        <input type="datetime-local" onFocus={(e) => onFocusShowPicker(e)} min={new Date().toISOString().substring(0, 16)} disabled={isChecked.length === 0}
                                            id="publishAt" name="publishAt" onChange={(event) => setUpdateMultiplePublishAt(new Date(event.target.value).getTime())}/> 
                                        <Button onClick={(e) => {updatePublisAtMultiple(e, updateMultiplePublishAt, isChecked, allAppointments, setAllAppointments, setActionErrorMultiple, setActionOnSaveMultiple, setActionSuccessMultiple, setShowUpdateDateInput, setIsChecked, tokenData, setTapError, setExpiredAppointments); setUpdateMultiplePublishAt(null)}} 
                                            className="confirm thin">Lagre
                                        </Button>
                                        <Button onClick={() => setShowUpdateDateInput(showUpdateDateInput => !showUpdateDateInput)}
                                            className="plain thin">Avbryt
                                        </Button>
                                    </div>
                                }
                            </div>

                            {actionOnSaveMultiple && <Feedback class="" feedbackClass="feedback--warning" message={actionOnSaveMultiple}></Feedback>}
                            {actionErrorMultiple && <Feedback class="" feedbackClass="feedback--error" message={actionErrorMultiple} title="Klikk for å skjule melding" onClick={() => setActionErrorMultiple("")}/>}
                            {actionSuccessMultiple && <TimeoutFeedback class="" timeout={6000} feedbackClass="feedback--success" clearMessage={() => setActionSuccessMultiple("")} message={actionSuccessMultiple} title="Klikk for å skjule melding" onClick={() => setActionSuccessMultiple("")}/>}
                        </div>
                    }

                    {allAppointments.length > 0 && showOrderMultiple &&
                        <div className="overview__menu">
                            <div className="overview__menu--buttons mass-order">
                                {!customerName && <p>Velg kunde først.</p>}
                                {customerName && isChecked.length === 0 && <p>Velge minst <span className="bold">én</span> time før du kan legge inn en bestilling.</p>}
                                {(isChecked.length > 0 && customerName) && 
                                    <WorkFlowMenu id={"emptyAllChecked"} name={"emptyAllChecked"} onClick={() => {untapMultiple(isChecked, setTappedError, tokenData, tapError); removeAllChecked(setIsChecked); setSelectedDay({selected: []})}}/>
                                }
                                <Suspense>
                                    <AppointmentBoardMassOrder customers={customers} grain={grainVarieties} token={tokenData} appointmentIds={isChecked} updateChangesToState={orderStateHandler} disableCheckboxOnSave={(value) => setDisableCheckboxes(value)} returnCustomername={(customername) => setCustomerName(customername)} emptyChecked={() => setIsChecked([])} untapCustomer={() => isChecked.length > 0 && untapMultiple(isChecked, setTappedError, tokenData, tapError)}/>
                                </Suspense>
                            </div>
                        </div>
                    }
  
                    {filteredData?.length > 0 ? filteredData.map((data, index) => ( 
                        <Fragment key={index}>
                            <div className="overview__results">
                                {!search ? <>
                                    <h2>{formatDate(data.day)} - {formatDay(data.day, {weekday: 'long'})}</h2>
                                    <p>
                                        <span>{data.appointments.length}</span> {data.appointments.length === 1 ? "time" : "timer"} / 
                                        <span>{data.appointments.filter((a) => !a.reservedBy).length}</span> {data.appointments.filter((a) => !a.reservedBy).length === 1 ? " ledig time" : " ledige timer"}
                                    </p>
                                    
                                    {!search &&  
                                        <PrintButton disabled={search} onClick={() => {setShowExportModal(true); setUserModal(data.day)}} title="Åpne valg for eksportering av timedata"/>
                                    } 
                                </> : <>
                                    <h2>Kundetimer</h2>
                                    <p>Fant<span>{data.appointments.length}</span> {data.appointments.length === 1 ? "time" : "timer"}</p>
                                </>}

                                {(showExportModal && userModal === data.day) &&
                                    <ActionModal onHide={() => hideHandler(setShowExportModal)}>
                                        <Suspense> 
                                            <ExportAppointmentData onHide={() => hideHandler(setShowExportModal)} date={data.day} data={filteredData} customers={customers} grains={grainVarieties}/>
                                        </Suspense>
                                    </ActionModal>
                                }

                                {(showChangeMultiple || showOrderMultiple) && data.appointments.filter((a) => !a.reservedBy).length > 0 && 
                                    <div className="checkAll">
                                        {!selectedDay.selected.find((s) => s.day === data.day) ? <>
                                            <input type="checkbox" id="addAllApps" name="addAllApps" value="" checked={!selectedDay.selected.find((s) => s.day === data.day) ? false : true} title={showOrderMultiple && !customerName ? "Velg en kunde først" : "Velg alle ledige timer for denne dagen" } disabled={showOrderMultiple && !customerName}
                                                onChange={() => { 
                                                    setSelectedDay((days) => ({...days, selected: [...days.selected, {day : data.day, checked: true}]}));
                                                    checkboxDay(data.appointments.filter((a) => !a.reservedBy), setIsChecked, (showOrderMultiple && customerName) ? "tap" : "", customerName, setTappedError, tokenData, setTapError, isChecked, setExpiredAppointments, setTapDisabled, setLoadingOverlay, loadingOverlay); 
                                                }}/>  
                                                
                                            <label htmlFor="addAllApps"> Velg alle ledige {data.appointments.filter((a) => !a.reservedBy).length === 1 ? " time" : " timer"} ({data.appointments.filter((a) => !a.reservedBy).length})</label>
                                    
                                            </> : <>
                                                <input type="checkbox" value="" checked={selectedDay.selected.find((s) => s.day === data.day)} id="removeAllApps" name="removeAllApps" disabled={showOrderMultiple && !customerName} onClick={() => {
                                                    uncheckDay(isChecked, data.appointments.filter((a) => !a.reservedBy).map(a => parseInt(a.id), tapError, setTapError), 
                                                    setIsChecked, data.appointments.filter((a) => !a.reservedBy).map((a) => a)); 
                                                    setSelectedDay((days) => ({selected: days.selected.filter((d) => d.day !== data.day)}));
                                                    ((showOrderMultiple && customerName) && untapMultiple(data.appointments.filter((a) => !a.reservedBy).map((a) => parseInt(a.id)), setTappedError, tokenData, tapError))
                                                }}/>
                                                <label htmlFor="removeAllApps"> Fjern alle valgte timer </label>
                                            </>                                                                                                                                          
                                        }
                                        
                                        {tappedError && <ErrorFeedback class="error__container--inline error__container--topped" feedbackClass="" message={tappedError}/>}
                                    </div>
                                }
                            </div>
                        
                            <div className="overview__table">
                                <table style={{width: "100%"}} id="overviewTable"
                                    className={`table table--xxl ${calculateTableClasses()}`} >
                                    <TableHead />
                                        {(loadingOverlay.find((o) => o.day === data.day && o.display) && selectedDay.selected.find((s) => s.day === data.day)) &&  
                                            <tbody className="loadingOverlay">
                                                <tr><td><span>Velger timer ...</span></td></tr>
                                            </tbody>
                                        }
                                        <tbody>
                                            {data.appointments.map((appointment) => { return (
                                                <Fragment key={appointment.id}>
                                                    {expiredAppointments && !expiredAppointments.find((a) => a === appointment.id) ?
                                                        <tr className={`table__row ${appointment.reservedBy && "table__row__reserved"} ${isChecked.find((i) => i === appointment.id) && "table__row__checked"} ${actionDeleteError?.find((e) => e.id === appointment.id) && "table__row__error"} ${tapError.find((t) => t.id === appointment.id) && "table__row__taken"}`} style={{ borderBottom: actionDeleteError && actionDeleteError.id === appointment.id && 'none'}}>
                                                            <td className="td__center td__center--checkbox">
                                                                {((!appointment.reservedBy && showChangeMultiple) || (!appointment.reservedBy && showOrderMultiple)) && 
                                                                    <input type="checkbox" name="checkbox" disabled={(showOrderMultiple && !customerName) || tapError.find((a) => a.id === appointment.id) || tapDisabled.find((a) => a === appointment.id) || disableCheckboxes} value="" checked={isChecked.includes(appointment.id) ? true : false} title={showOrderMultiple && !customerName ? "Velg kunde først" : "Velg denne timen"}
                                                                        onChange={() => handleCheckboxClick(appointment, setIsChecked, (showOrderMultiple && customerName) ? "tapCheck" : "", customerName, isChecked.includes(appointment.id) ? "untap" : "tap", setTappedError, tokenData, setTapError, isChecked, setExpiredAppointments, setTapDisabled)}/>
                                                                }
                                                            </td>
                                                            
                                                            <td>
                                                                {search && <span style={{fontWeight: "600"}}>{formatDate(appointment.scheduledAt)} </span>} 
                                                                <span style={{fontWeight: "600"}}>{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</span> {""}
                                                            </td>

                                                            <td>
                                                                {appointment.reservedBy ? getUserName(customers, appointment.reservedBy) : ""}
                                                                {(!appointment.reservedBy && tapError && tapError.find((t) => t.id === appointment.id)) && 
                                                                
                                                                <span className="feedback sm">
                                                                    {getErrorStatusMessage(tapError.find((t) => t.id === appointment.id).status)}
                                                                    <span onClick={(e) => removeTapError(appointment.id, setTapError)} title="Skjul denne beskjeden"> Skjul beskjed</span>
                                                                </span>
                                                                }
                                                            </td>

                                                            <td className="td__center">{appointment.amount && appointment.amount + ' tonn'} </td>

                                                            <td className="td__center">{appointment.reservedBy ? (
                                                                <>
                                                                    {findGrainTypeName(grainVarieties, appointment.grainTypeId)}
                                                                    {" "}{isSprayed(grainVarieties, appointment, "serverside")}
                                                                </>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </td>

                                                            <td className="td__center">{appointment.reservedBy && findVarietyName(grainVarieties, appointment.grainTypeId, parseInt(appointment.varietyTypeId))}</td>

                                                            <td className="td__center">{appointment.warehouseRental ? <img src={checkmark} alt="Comment icon" style={{ height: "21px"}}/> : ""}</td>

                                                            <td className="td__center">{!appointment.reservedBy ? appointment.published ? "Ja" : "Nei" : ""}</td>

                                                            <td className="td__center"> 
                                                                {!appointment.reservedBy && (!appointment.published && appointment.publishAt) && <>{formatDate(appointment.publishAt)} {formatTime(appointment.publishAt, {hour: '2-digit', minute: '2-digit'})}</>}
                                                                {!appointment.reservedBy && (appointment.published && !appointment.publishAt) && "Ved opprettelse"}
                                                                {!appointment.reservedBy && (!appointment.published && !appointment.publishAt) && <><img src={attentionIcon} alt="Attention icon" className="table__row--icon"/>Mangler tid</>}
                                                            </td>

                                                            <td className="td__center">
                                                                {appointment.comment && 
                                                                    <Button className="action mass__actions--btn" title="Les kommentar" onClick={() => {setShowComment(true); setModalId(appointment.id)}}>
                                                                        <img src={readComment} alt="Comment icon"/>
                                                                    </Button>
                                                                }
                                                            </td>

                                                            <td className="td__right mass__actions">
                                                                {!appointment.reservedBy ? <>
                                                                    <Button className="action mass__actions--btn mass__actions--btn--left mass__actions--btn--mr" 
                                                                        disabled={showChangeMultiple || showOrderMultiple || tapError?.find((t) => t.id === appointment.id) || actionDeleteError?.find((e) => e.id === appointment.id)} 
                                                                        title="Endre publiseringstidspunkt eller status"
                                                                        onClick={() => { new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => {return [...items, appointment.id]}) : setShowUpdatePublishing(true); setModalId(appointment.id)}}>
                                                                        <img src={updatePublish} alt="Change publish status icon"/>
                                                                    </Button>
                                                                    <Button title="Slett time" className="action mass__actions--btn mass__actions--btn--left"
                                                                        disabled={showChangeMultiple || showUpdate || showOrderMultiple || (showOrderMultiple && modalId === appointment.id) || tapError.find((t) => t.id === appointment.id) || actionDeleteError?.find((e) => e.id === appointment.id)} 
                                                                        onClick={() => {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => {return [...items, appointment.id]}) : deleteOne(appointment, confirm, allAppointments, setActionDeleteError, setAllAppointments, tokenData)}}>
                                                                        <img src={deleteIcon} alt="Delete icon"/>
                                                                    </Button>
                                                                </>
                                                                : 
                                                                <>
                                                                    <Button className="action mass__actions--btn mass__actions--btn--mr" title="Endre time" disabled={showChangeMultiple || showOrderMultiple} 
                                                                        onClick={() => {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => {return [...items, appointment.id]}) : setShowUpdate(true); setModalId(appointment.id)}}>
                                                                        <img src={editIcon} alt="Edit icon"/>
                                                                    </Button>
                                                                    <Button className="action mass__actions--btn" title="Kanseller time" disabled={showChangeMultiple || showOrderMultiple}
                                                                        onClick={(e) => {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => {return [...items, appointment.id]}) : cancelAppointmentHandler(e, confirm, configuration, appointment, allAppointments, setActionDeleteError, setAllAppointments, tokenData, appointment.reservedBy, "arranged state")}}>
                                                                        <img src={cancelIcon} alt="Cancel icon"/>
                                                                    </Button>  
                                                                </>
                                                                }
                                                            </td>

                                                            {(showUpdatePublishing && modalId === appointment.id) &&
                                                                <ActionModal onHide={() => hideHandler(setShowUpdatePublishing)}>
                                                                    <Suspense>
                                                                        <EditPublishState onHide={() => hideHandler(setShowUpdatePublishing)} app={appointment} updateChangesToState={publishStateHandler} returnError={(error) => setActionDeleteError((items) => {return [...items, error]})}/>
                                                                    </Suspense>
                                                                </ActionModal>
                                                            }

                                                            {(showComment && modalId === appointment.id) &&
                                                                <ActionModal onHide={() => hideHandler(setShowComment)}>
                                                                    <Suspense>
                                                                        <ShowComment onHide={() => hideHandler(setShowComment)} app={appointment} customers={customers}/>
                                                                    </Suspense>
                                                                </ActionModal>
                                                            }

                                                            {(showUpdate && modalId === appointment.id) &&
                                                                <ActionModal onHide={() => hideHandler(setShowUpdate)}>
                                                                    <Suspense>
                                                                        <EditAppointment onHide={() => hideHandler(setShowUpdate)} app={appointment} movable={true} customers={customers} appointments={allAppointments} updateChangesToState={updateAppointmentStateHandler} moveAppointment={moveAppointmentsInState}/>
                                                                    </Suspense>
                                                                </ActionModal>
                                                            }
                                                        </tr>
                                                    : 
                                                        <tr className="table__row table__row__expired">
                                                            <td className="td__center td__center--checkbox">
                                                                {((!appointment.reservedBy && showChangeMultiple) || (!appointment.reservedBy && showOrderMultiple)) && 
                                                                    <input type="checkbox" name="checkbox" disabled={expiredAppointments.find((a) => a === appointment.id)} value="" checked={isChecked.includes(appointment.id) ? true : false}/>
                                                                }
                                                            </td>
                                                            <td>{search && <span style={{fontWeight: "600"}}>{formatDate(appointment.scheduledAt)} </span>} 
                                                                {formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}
                                                            </td>
                                                            <td colSpan={7}>
                                                                <span className="feedback sm">Denne timen er utløpt eller finnes ikke lengere.
                                                                <span onClick={(e) => {removeExpiredError(appointment.id, setExpiredAppointments); removeFromState(e, appointment.id, allAppointments, setAllAppointments)}} title="Fjern time ifra listen"> Fjern visning av time</span></span>
                                                            </td>
                                                            <td colSpan={2}></td>
                                                        </tr>
                                                    }

                                                    {actionDeleteError?.find((e) => e.id === appointment.id) && 
                                                        <tr className="table__row table__row__error">
                                                            <td></td>
                                                            <td colSpan="11">
                                                                <span className="feedback sm">{actionDeleteError?.find((e) => e.id === appointment.id).message} 
                                                                <span onClick={() => setActionDeleteError((errors) => {return errors.filter((a) => a.id !== appointment.id)})} title="Skjul feilmelding"> Skjul feilmelding</span></span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </Fragment>
                                            );})}
                                        </tbody>
                                </table>
                            </div>
                        </Fragment>
                    )) : 
                        <div className="overview__table" key={"emptyTable"}>
                            <table style={{width: "100%"}} className="table table--xxl emptyTable">
                                <TableHead/>
                                    <tbody>
                                        <tr style={{ borderBottom: "none", height: "150px" }} className="table__empty">
                                            <td colSpan={12} >
                                                {!search && allAppointments?.length <= 0 && 
                                                    <p className="empty-result empty-result--positive">
                                                        Det finnes ingen ledige eller kommende bestilte timer. Opprett nye timer <Link title="Opprett nye timer" to={"/administrator/opprett-timer"}>her</Link>.
                                                    </p>
                                                }
                                                {search && filteredData?.length <= 0 && <p className="empty-result empty-result--positive">Fant ingen bestilte timer for den kunden, vennligst se over navnet på nytt. </p>}
                                            </td>
                                        </tr>
                                    </tbody>
                            </table>
                        </div>
                    }
                </div>
            }
        </section>
    );
};

export default AppointmentBoard;