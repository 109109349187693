const HomePage = () => {
    return (
        <div className="main__section">
            <div className="main__section__body content">
                <div className="content__container">
                    <div className="content__header">
                        <h1>Velkommen til Braskereidfoss kornsilo sitt timesbestillingssystem!</h1>
                        <p className="note">Nedenfor finner du informasjon om hvordan vårt timesbestillingssystem fungerer og hvordan du går frem for å bestille timer hos oss. 
<br/>
                            Du <span className="bold">må være logget inn</span> for å ha tilgang til navigasjonsmenyen vår. 
                        </p>
                        <p></p>
                    </div>
                    <div className="content__body">
                        <div className="content__body--square">
                            <h2>Navigasjon</h2>
                            <p>Etter innlogging vil du kunne finne en utvidet navigasjonsmeny på toppen av siden, den vil inneholde de ulike
                                funksjonene tilgjengelig for deg som bruker av systemet. For å komme tilbake til denne siden, kan du klikke på vår logo eller menypunktet <span className="bold">“Hjem”</span>.
                            </p>
                        </div>

                        <div className="content__body--square">
                            <h2>Finn dine timer</h2>
                            <p>
                                Om du har bestilte og kommmende timer så vil du kunne finne de under <span className="bold">“Mine timer”</span>, 
                                de listes ut med all tilhørende informasjon for hver bestilling. Her kan du endre art, sort og mengde per time, eller kansellere timer.
                                Det vil ikke være tilgjengelig informasjon om du ikke har bestilt timer, eller om timene har passert.
                                Din bestillingshistorikk vil du kunne finne under <span className="bold">“Arkiv"</span>.
                            </p>
                        </div>

                        <div className="content__body--square">
                            <h2>Timebestilling</h2>
                            <p>
                                Under <span className="bold">“Timebestilling”</span>, vil du kunne bestille opp til flere timer på ulike dager.
                                Du kan bestille fra en eller flere dager om gangen, og er kun begrenset av antall timer som kan bestilles per bestilling og antall timer tillat å bestille per dag. 
                                Timer bestilt her vil havne under <span className="bold">"Mine Timer"</span> til de er forbigått.
                            </p>
                        </div>

                        <div className="content__body--square">
                            <h2>Logg inn / Logg ut</h2>
                            <p>På toppen til høyre av siden finner du funksjonen for å logge seg inn, eller ut.
                                Om du skal logge inn så vil du bli bedt om å oppgi brukerinformasjonen som du har fått tilsendt; brukernavn og passord.
                                Ved suksesfull innlogging vil du bli ført til <span className="bold">"Timebestilling"</span>. 
                                Om du mangler denne informasjonen så ta kontakt med oss.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;