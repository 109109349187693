import { Fragment, useEffect, useState } from "react";
import { getUserToken } from "../../../../../data/token";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import { apiUrl } from "../../../../../data/API";
import { delayPromise } from "../../../../../assets/DelayPromise";
import { Feedback, TimeoutFeedback, ErrorFeedback } from "../../../../../assets/Feedback";
import Button from "../../../../UI/Button";

const ConfigPage = () => {
    const [configuration, setConfiguration] = useState({});
    const [saveError, setSaveError] = useState("");
    const [show, showUpdate] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [fetchError, setFetchError] = useState("");
    const tokenData = getUserToken();

    let formIsValid = false;

    const fetchConfigurationData = () => {
        setIsLoading(true);                            
        setFetchError("");
        fetch(apiUrl + `configuration`, {
            method: "GET",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json",},
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data) {
                        setConfiguration(data)
                        setIsLoading(false);
                    } else {
                        setFetchError("Kunne ikke finne innstillinger, vennligst last inn siden på nytt.");
                        setIsLoading(false);
                    }
                })
            } else {
                return response.json().then((data) => {
                    setFetchError("Kunne ikke finne innstillinger, vennligst last inn siden på nytt og forsøk igjen.");
                    setIsLoading(false);
                });
            }
        })
    };

    useEffect(() => {
        fetchConfigurationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    if (configuration && (configuration.maxDailyReservations && configuration.cancellationThreshold && configuration.maxHourlyGrain && configuration.maxTapped && configuration.tapDuration) > 0) {
        formIsValid = true;
    }

    const handleChange = (event, config, boolean) => {
        event.persist(); 
        const { id, value } = event.target;

        let updateField;

        if (value) {
            updateField = {...config, [id] : boolean ? JSON.parse(value) : parseInt(value)};
        } else {
            updateField = {...config, [id] : ""};
        }

        setConfiguration(updateField);
    }

    const updateConfig = (event) => {
        event.preventDefault();

        if (formIsValid) {
            setSaveError("");
            setIsSuccess("");
            setFeedback("Oppdaterer innstillinger ...");    

            fetch(apiUrl + `configuration?cancellationCausesUnpublish=${configuration.cancellationCausesUnpublish}&cancellationThresholdInMinutes=${configuration.cancellationThreshold}&maxDailyReservations=${configuration.maxDailyReservations}&maxHourlyGrainInTons=${configuration.maxHourlyGrain}&maxTapped=${configuration.maxTapped}&tapDurationInMinutes=${configuration.tapDuration}`, {
                method: "PUT",
                headers: { "Authorization": `Bearer ${tokenData}`,"Content-Type": "application/json"}
            }).then(delayPromise(2000))
            .then((response) => {
                if (response.ok) {
                    setFeedback("");   
                    setSaveError("");
                    setIsSuccess("Innstillingene ble oppdatert");
                    showUpdate(false);   
                    return response.text();
                } else {
                    setFeedback("");
                    setIsSuccess("");   
                    return response.json().then((data) => {
                        if (data.message === "VALUES_MUST_BE_GREATER_THAN_ZERO") {
                            setSaveError("Verdiene må være større enn `0`.");
                        } else {
                            setSaveError("Det oppsto en feil ved oppdatering av innstillinger. Vennligst se over feltene, alle må være utfylt.");
                        }
                        setSaveError("");
                        setFeedback("");
                    });
                }
            }).catch((error) => {
                setFeedback("");
                setIsSuccess("");
                setSaveError("Det oppsto en feil ved oppdatering av innstillinger. Last inn siden på nytt og prøv igjen.");
            });
        } else {
            return;
        }
    }

    const clearSuccessState = () => {
        setIsSuccess("");
    }

    return (
        <section className="contents__container">
            <h1 className="no-mt">Innstillinger</h1>
            {fetchError && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Det oppsto et problem; fant ingen innstillinger. Vennligst last inn siden på nytt."}/>} 
            
            {(!isLoading && configuration) ? <Fragment> 
                <p>Oppdater innstillinger for bruk av timebestillingssystemet.</p>
                    <div className="config">
                        <form>
                            <div className="config__form">
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="maxDailyReservations">Antall bestilte timer</label>
                                        <p>Sett en maksgrense for antall timer en vanlig bruker kan bestille <span className="bold">per dag</span>.</p>
                                    </div>
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <input type="number" autoComplete="off" name="maxDailyReservations" id="maxDailyReservations" defaultValue={configuration.maxDailyReservations} 
                                                onChange={(e) => handleChange(e, configuration)} className={!configuration.maxDailyReservations ? "input--error" : null} 
                                            /> 
                                            {!configuration.maxDailyReservations && <p className="inline--error">Mangler antall, eller har ugyldig data.</p>}
                                        </> : <span> {configuration.maxDailyReservations}</span>
                                        }
                                    </div>
                                </div>
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="maxTapped">Antall valgte timer</label>
                                        <p>Sett en maksgrense for antall timer en bruker kan velge <span className="bold">per bestilling</span>. </p>
                                    </div>
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <input type="number" autoComplete="off" name="maxTapped" id="maxTapped" defaultValue={configuration.maxTapped} 
                                                onChange={(e) => handleChange(e, configuration)} className={!configuration.maxTapped ? "input--error" : null} 
                                            /> 
                                            {!configuration.maxTapped && <p className="inline--error">Mangler antall, eller har ugyldig data.</p>}
                                        </> : <span> {configuration.maxTapped}</span>
                                        }
                                    </div>
                                </div>
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="tapDuration">Reservasjonstid (minutter)</label>
                                        <p>Sett en maksgrense for antall <span className="bold">minutter</span> en time holdes som valgt når en bruker velger den under bestilling.  
                                            Valgte timer skjules for andre brukere innenfor reservasjonstiden.
                                        </p>
                                    </div>
                                    
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <input type="number" autoComplete="off" name="tapDuration" id="tapDuration" defaultValue={configuration.tapDuration} 
                                                onChange={(e) => handleChange(e, configuration)} className={!configuration.tapDuration ? "input--error" : null}
                                            /> 
                                            {!configuration.tapDuration && <p className="inline--error">Mangler antall minutter, eller ugyldig data.</p>}
                                            </> :<span> {configuration.tapDuration}</span>
                                        }
                                    </div>
                                </div>
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="maxHourlyGrain">Kornmengde (vektgrense) </label>
                                        <p>Sett en maksgrense for antall <span className="bold">tonn</span> korn som kan bestilles i løpet av en klokketime (60 min).
                                            Terskelen gjelder alle arter korn. 
                                        </p>
                                    </div>
                                    
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <input type="number" autoComplete="off" name="maxHourlyGrain" id="maxHourlyGrain" defaultValue={configuration.maxHourlyGrain} 
                                                onChange={(e) => handleChange(e, configuration)} className={!configuration.maxHourlyGrain ? "input--error" : null}
                                            /> 
                                            {!configuration.maxHourlyGrain && <p className="inline--error">Mangler antall tonn, eller ugyldig data.</p>}
                                            </> : <span> {configuration.maxHourlyGrain}</span>
                                        }
                                    </div>
                                    
                                </div>
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="cancellationThreshold">Kanselleringsterskel</label>
                                        <p>Sett en terskel i minutter for hvor nært en time kan kanselleres uten konsekvenser for kunden. 
                                            All kansellering innenfor tidsrommet vil kreve bekreftelse av brukerne.
                                        </p>
                                    </div>
                                    
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <input type="number" autoComplete="off" name="cancellationThreshold" id="cancellationThreshold" defaultValue={configuration.cancellationThreshold} 
                                                onChange={(e) => handleChange(e, configuration)} className={!configuration.cancellationThreshold ? "input--error" : null}
                                            /> 
                                            {!configuration.cancellationThreshold && <p className="inline--error">Mangler antall minutter, eller ugyldig data.</p>} 
                                            </> : <span> {configuration.cancellationThreshold}</span>
                                        }
                                    </div>
                                </div>
                                <div className="config__form__step">
                                    <div>
                                        <label htmlFor="cancellationCauses">Ved kansellering (av kunde)</label>
                                        <p>Ved kansellering så skal timen avpubliseres eller forbli publisert.</p>
                                    </div>
                                    
                                    <div className="config__form__step--input">
                                        {show ? <>
                                            <select id="cancellationCausesUnpublish" name="cancellationCauses" required defaultValue={configuration.cancellationCausesUnpublish} 
                                                onChange={(e) => handleChange(e, configuration, "boolean")}>
                                                    <option value={false}>Forbli publisert</option>
                                                    <option value={true}>Avpubliseres</option>
                                            </select>
                                            </> : <span> {configuration.cancellationCausesUnpublish === true ? "Avpubliseres" : "Forbli publisert"}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="config__form__step--confirm"> 
                                {show && <Button className="confirm button__mr" type="submit" onClick={(e) => updateConfig(e)} disabled={!formIsValid} title="Oppdater innstillinger">Oppdater innstillinger</Button>}
                                <Button className="plain" type="button" onClick={() => {showUpdate(show => !show); show && fetchConfigurationData();}} title="Endre innstillinger">{!show ? "Endre innstillinger" : "Avbryt endring"}</Button>
                            
                                {feedback && <Feedback class="feedback--center" feedbackClass="feedback--warning" message={feedback}/>} 
                                {saveError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={saveError}/>} 
                                {isSuccess && <TimeoutFeedback class="topped" feedbackClass="feedback--success" clearMessage={clearSuccessState} message={isSuccess}/>}
                            </div> 
                        </form>
                    </div>
                </Fragment> 
                : <LeafLoader loadingMessage="Henter innstillinger ..."/>
            }
        </section>
    );
};

export default ConfigPage;