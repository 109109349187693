import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import Footer from "./Footer";
import LoginModal from "../access/LoginModal";

const MainLayout = () => {
    const [showLogin, setShowLogin] = useState(false);

    return (
        <Fragment>
            {showLogin && <LoginModal onHide={() => hideHandler(setShowLogin)} />}
            <MainNavigation toggleModal={() => showHandler(setShowLogin)} />
                <main className="main">
                    <Outlet/>
                </main>
            <Footer/>
        </Fragment>
    );
};

export default MainLayout;

export const showHandler = (setState) => {
    setState(true);
};

export const hideHandler = (setState) => {
    setState(false);
};