
import { useState } from "react";
import useInput, { inputNotEmpty } from "../../../../../hooks/useInput";
import { capitalizeStr } from "../../../../../assets/Capitalize";
import Button from "../../../../UI/Button";

const CreateTimespanTemplates = (props) => {
    const [validName, setValidName] = useState(true);

    const { value: templateNameValue, isValid: templateNameIsValid, hasError: templateNameHasError, valueChangeHandler: templateNameChangeHandler, inputBlurHandler: templateNameBlurHandler, reset: resetTemplateName } = useInput(inputNotEmpty);
    const { value: templateStartTimeValue, isValid: templateStartTimeIsValid, hasError: templateStartTimeHasError, valueChangeHandler: templateStartTimeChangeHandler, inputBlurHandler: templateStartTimeBlurHandler, reset: resetTemplateStartTime } = useInput(inputNotEmpty);
    const { value: templateEndTimeValue, isValid: templateEndTimeIsValid, hasError: templateEndTimeHasError, valueChangeHandler: templateEndTimeChangeHandler, inputBlurHandler: templateEndTimeBlurHandler, reset: resetTemplateEndTime } = useInput(inputNotEmpty);
    const { value: templateMinutesBetweenValue, isValid: templateMinutesBetweenIsValid, hasError: templateMinutesBetweenHasError, invalidMinutesBetween: passedMinuteLimit, valueChangeHandler: templateMinutesBetweenChangeHandler, inputBlurHandler: templateMinutesBetweenBlurHandler, reset: resetTemplateMinutesBetween } = useInput(inputNotEmpty);

    const templateData = props.templates;
    const startTime = templateStartTimeValue.replace(':','');
    const endTime = templateEndTimeValue.replace(':','');

    let validStartEnd;
    let setIsValid = false;
    let invalidValue = false; 

    if (templateStartTimeIsValid && templateEndTimeIsValid) {
        validStartEnd = new Date(endTime) > new Date(startTime) ? true : false
    }

    if (templateMinutesBetweenIsValid && templateMinutesBetweenValue <= 0) {
        invalidValue = true;
    }


    if (templateNameIsValid && validStartEnd && templateMinutesBetweenIsValid && !passedMinuteLimit && validName && !invalidValue) {
        setIsValid = true;
    }

    const saveTemplate = (event) => {
        event.preventDefault();
        
        if (!setIsValid) {   
            props.onValid("false");

        } else {
            props.onSave({name: templateNameValue, minutesBetween : templateMinutesBetweenValue, start : startTime, end : endTime});
            resetTemplateName();
            resetTemplateStartTime();
            resetTemplateEndTime();
            resetTemplateMinutesBetween();
            setValidName(true);
        }
    };
    
    return (
        <form onSubmit={saveTemplate} noValidate >
            <div>
                <label htmlFor="templateName">Malnavn <span className="required" title="Påkrevd felt">*</span></label>                           
                <input type="text" id="name" value={templateNameValue} maxLength="26" onChange={(e) => templateNameChangeHandler(e)} autoComplete="off"
                    className={`${(templateNameHasError ? "input--error" : null)}`} onBlur={(e) => {templateNameBlurHandler(); checkForUniqueName(e, templateData, templateNameValue, setValidName)}}
                />
                {templateNameValue.length === 26 && (<p className="error-text">Velg et beskrivende navn (maks 26 karakterer).</p>)}
                {!validName && (<p className="error-text">Navnet du har valgt eksisterer allerede.</p>)}
            </div>
            <div>
                <label htmlFor="templateStart">Starttid <span className="required" title="Påkrevd felt">*</span></label>
                <input type="time" id="start" value={templateStartTimeValue} onChange={(e) => templateStartTimeChangeHandler(e)} 
                    onBlur={templateStartTimeBlurHandler} className={`${(templateStartTimeHasError ? "input--error" : null)}`}/>
            </div>
            <div>
                <label htmlFor="templateEnds">Sluttid <span className="required" title="Påkrevd felt">*</span></label>
                <input type="time" id="end" value={templateEndTimeValue} onChange={(e) => templateEndTimeChangeHandler(e)} onBlur={templateEndTimeBlurHandler}
                    className={`${(templateEndTimeHasError || (templateStartTimeIsValid && templateEndTimeIsValid && !validStartEnd) ? "input--error" : null)}`}
                />
                {(templateStartTimeIsValid && templateEndTimeIsValid) && !validStartEnd && (<p className="error-text">Sluttid kan ikke være før startid.</p>)}
            </div>
            <div>
                <label htmlFor="minutesBetween">Minutter mellom <span className="required" title="Påkrevd felt">*</span></label>
                <input type="number" id="minutesBetween" autoComplete="off" value={templateMinutesBetweenValue} 
                    onChange={(e) => templateMinutesBetweenChangeHandler(e)} onBlur={templateMinutesBetweenBlurHandler}
                    className={`${((templateMinutesBetweenHasError || invalidValue ||  passedMinuteLimit) ? "input--error" : null)}`}
                />
                {passedMinuteLimit && (<p className="error-text">Tid mellom timer kan ikke overskride 120 minutter (2 timer).</p>)}
                {invalidValue && <p className="error-text">Uglydig verdi oppgitt.</p>}   
            </div>

            <Button type="submit" className="confirm topped" title="Lagre timemal" >Lagre timemal</Button>
        </form>
    );
};

export default CreateTimespanTemplates;

export const addWhiteSpace = (value) => {
    const nameTrim = value.split('-').join(' ');
    return capitalizeStr(nameTrim);
}

export const checkForUniqueName = (event, data, enteredValue, state) => {
    event.preventDefault();
    const checkDataForName = data.find((template) => template.name.toLowerCase() === enteredValue.toLowerCase())

    if (checkDataForName) {
        state(false);
    } else {
        state(true);
    }
}