import { useEffect, useState } from "react";
import { getUserToken } from "../../data/token";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LeafLoader from "../../assets/LoadingSpinner";
import { getGrainData } from "./BookingService";
import { formatTime, formatDate, formatDay } from "../../assets/DateFormatOptions";
import { findGrainTypeName, findVarietyName, isSprayed } from "../access/user/UserService";
import { ErrorFeedback } from "../../assets/Feedback";
import Button from "../UI/Button";

const BookingConfirmation = () => {
    const [confirmationData, setConfirmationData] = useState([])
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [feedback, setFeedback] = useState([]);

    const navigate = useNavigate();
    const tokenData = getUserToken();
    const {state} = useLocation();

    let error;
    let renderState = false;

    useEffect(() => {
        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
                error = "Det har oppstått en feil ved henting av korntyper. Prøv på nytt senere eller send oss en e-post."});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenData]);

    useEffect(() => {
        if (state === null || !state) {
            navigate("/");
        } else {
            setConfirmationData(state.appointmentData);
            setFeedback(state.bookingStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmationData, state, feedback]);

    const forwardToUserPage = () => {
        navigate("/mine-timer");
    }

    const forwardToBooking = () => {
        navigate("/timebestilling");
    }

    const orderStatus = (state, messaage) => {
        return (<>
            <span className={`pill ${state !== "OK" ? "pill__error" : "pill__active"}`} title={state !== "OK" ? "Timen er ikke bestilt" : "Timen ble bestilt"}>
                {state !== "OK" ? "Feil" : "OK"}
            </span>
            <span className="pill__desc">{messaage}</span>
        </>)
    }

    const checkForStatus = (id) => {
        const status = feedback.find((i) => i.id === id).status;

        switch (status) {
            case "OK": 
                return orderStatus("OK", "Timen ble bestilt")
            case "TAKEN":
                return orderStatus("ERROR", "Timen er allerede tatt")
            case "TOO_MUCH_GRAIN":
                return orderStatus("ERROR", "Ulovelig stor mengde med korn")
            case "AMOUNT_EXCEEDS_THRESHOLD":
                return orderStatus("ERROR", "Ulovelig stor mengde med korn per klokketime"); 
            case "APPOINTMENT_ALREADY_RESERVED":
                return orderStatus("ERROR", "Timen var allerede reservert")
            case "APPOINTMENT_ALREADY_TAPPED":
                return orderStatus("ERROR", "Timen var valgt av noen andre"); 
            case "APPOINTMENT_ALREADY_TAPPED_BY_ANOTHER":
                return orderStatus("ERROR", "Timen var valgt av noen andre")
            case "APPOINTMENT_DOES_NOT_EXIST":
                return orderStatus("ERROR", "Timen eksisterer ikke lengere"); 
            case "APPOINTMENT_HAS_PASSED":
                return orderStatus("ERROR", "Timen er utgått")
            case "MAXIMUM_DAILY_RESERVATIONS_REACHED":
                return orderStatus("ERROR", "Max reservasjoner per dag er nådd"); 
            case "ILLEGAL_WAREHOUSE_RENTAL":
                return orderStatus("ERROR", "Du har ikke mulighet til å bestille lagerleie. Ta kontakt med oss for å signere avtale om lagerleie"); 
            default:
                return orderStatus("ERROR", "Ukjent feil, kunne ikke bestille time");
        }
    }

    const sortAscAppointments = (array) => {
        return array.sort((a,b)=> a.scheduledAt.localeCompare(b.scheduledAt));
    }

    if (confirmationData?.length >= 1 && grainVarieties?.length >= 1 && feedback?.length >= 1 && !error) renderState = true;

    return (
        <div className="main__section">
            <div className="main__section__body content">
                <div className="content__header">
                    <h1>Vi har registrert din timebestilling!</h1>
                </div>
                <div className="content__container">
                    {error && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={error}/>}
                    {!renderState && <LeafLoader loadingMessage="Laster inn ledige dager ..."/>}

                    {renderState && <>
                        <div className="content__body--subheader">  
                            <p>Du kan nå se en oversiktlig over bestillingen din nedenfor, eller gå vidre til <Link to="/mine-timer" title="Gå til Mine Timer"><span className="bold"> "Mine Timer" </span></Link> for å se alle dine kommende timer. 
                            </p>
                        </div>

                        <div className="content__body">
                            <div className="booking">
                                <div className="booking__section">
                                    {confirmationData.map((booked) => (
                                        <div key={booked.id} className="booking__section--table">
                                            <h2>{formatDate(booked.day)}</h2><span>{formatDay(booked.day, {weekday: 'long'})}</span>
                                            <table style={{width: "100%"}} className="table">
                                                <thead>
                                                    <tr className="table__head">
                                                        <th className="table__head--main" style={{width:"14%"}}>Tid</th>
                                                        <th style={{width:"20%"}}>Kornart</th>
                                                        <th style={{width:"20%"}}>Kornsort</th>
                                                        <th style={{width:"12%"}}>Tonn</th>
                                                        <th style={{width:"10%"}}>Lagerleie</th>
                                                        <th className="table__head--feedback">Anmerkning</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sortAscAppointments(booked.appointments).map((appointment) => {
                                                        return (
                                                            <tr className="table__row" key={appointment.id}>
                                                                <td className="table__row--main">{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</td>
                                                                <td>{findGrainTypeName(grainVarieties, parseInt(appointment.grainTypeId))} {isSprayed(grainVarieties, appointment)} </td>
                                                                <td>{appointment.varietyTypeId ? findVarietyName(grainVarieties, parseInt(appointment.grainTypeId), parseInt(appointment.varietyTypeId)) : "-"}</td>
                                                                <td>{appointment.amount}</td>
                                                                <td>{appointment.warehouseRental ? "Ja" : ""}</td>
                                                                <td>{checkForStatus(appointment.id)}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))} 
                                </div>
                            </div>
                        </div>  

                        <div className="feedback__container feedback--xl topped">
                            <Button className="plain button__mr" type="button" onClick={() => forwardToBooking()} title="Gå til Timebestilling">Bestill timer</Button>
                            <Button className="confirm" onClick={() => forwardToUserPage()} title="Gå til Mine Timer" type="button">Se dine timer</Button>
                        </div>    
                    </>}   
                </div>
            </div>
        </div>
    )
}

export default BookingConfirmation;

