import React, { useReducer } from "react";

const BookingContext = React.createContext({
    selectedAppointments: [],
    tappedAppointments: [],
    addAppointment: (appointment) => {},
    updateAppointment: (event, appointmentId, value, inputName, varietyCheck) => {},
    removeAppointment: (id) => {},
    appointmenStoredCheck: (array, time) => {},
    checkForExpiredAppointments: () => {},
});

const defaultBookingState = {
    selectedAppointments: [],
    tappedAppointments: []
};

const bookingReducer = (state, action) => {
    if (action.type === "ADD-APPOINTMENT") {
        const existingAppointment = state.selectedAppointments?.find((appointment) => appointment.id === action.appointment.id);
        const existingTap = state.tappedAppointments?.find((tap) => tap === action.appointment.id);

        let updatedAppointments = [];
        let updatedTaps = [];

        if (existingAppointment || existingTap) {
            updatedAppointments = [...state.selectedAppointments];
            updatedTaps = [...state.tappedAppointments];
        } else {
            updatedAppointments = [...state.selectedAppointments, action.appointment];
            updatedTaps = [...state.tappedAppointments, action.appointment.id];
        }

        return {
            selectedAppointments: updatedAppointments,
            tappedAppointments: updatedTaps,
        };
    }

    if (action.type === "UPDATE-APPOINTMENT") {
        const event = action.event;
        const { id } = event.target;
        const value = action.value;
        const inputName = action.inputName;

        const updatedAppointment = state.selectedAppointments.map((appointment) => {
            if (appointment.id === action.appointmentId) {
                if (event.target.type === 'checkbox') {
                    if (inputName === "warehouseRental") {
                    return {...appointment, warehouseRental: value === "false" ? false : true};                        
                    }
                    if (inputName === "sprayed") {
                        return {...appointment, sprayed: value === "false" ? false : true};                        
                    }
                } else if (action.varietyCheck) {
                    return {...appointment, [id]: value ? value : "", varietyTypeId : ""}
                } else {
                    return {...appointment, [id]: value ? value : ""}
                }
            }
            return appointment;
        });

        return {
            selectedAppointments: updatedAppointment,
            tappedAppointments: state.tappedAppointments
        };
    }

    if (action.type === "REMOVE-APPOINTMENT") {
        const existingAppointment = state.selectedAppointments?.find((appointment) => appointment.id === action.id);
        const existingTap = state.tappedAppointments?.find((tap) => tap === action.id);

        let updatedAppointments = [];
        let updatedTaps = [];

        if (existingAppointment || existingTap) {
            updatedAppointments = state.selectedAppointments.filter((appointment) => appointment.id !== action.id);
            updatedTaps = state.tappedAppointments.filter((tap) => tap !== action.id);
        } else {
            updatedAppointments = [...state.selectedAppointments];
            updatedTaps = [...state.tappedAppointments];
        }

        return {
            selectedAppointments: updatedAppointments,
            tappedAppointments: updatedTaps
        };
    }

    if (action.type === "CHECK-APPOINTMENT") {
        const pastTappedAppointments = action.array?.filter((appointment) => appointment.tapped);
        const pastTappedWithExpiration = pastTappedAppointments.map((app) => ({ id: app.id, expiration: new Date(app.tappedAt).getTime() + ((1000 * 60) * action.time)}));
        localStorage.setItem("taps", JSON.stringify(pastTappedWithExpiration));

        let updatedAppointments = [];
        let updatedTaps = [];

        if (pastTappedAppointments?.length > 0) {
            pastTappedAppointments.map((app) => {
                const appointment = {id: app.id, scheduledAt: app.scheduledAt, tapped: true, grainTypeId: "", varietyTypeId: "", amount: "", warehouseRental: false, comment: "", sprayed: false};
                return (
                    updatedAppointments.push(appointment),
                    updatedTaps.push(app.id)
                )
            });
        }

        return {
            selectedAppointments: updatedAppointments,
            tappedAppointments: updatedTaps,
        };
    }

    if (action.type === "APPOINTMENT-EXPIRATION") {
        const getStoredTaps = JSON.parse(localStorage.getItem("taps") || "[]");
        const expiredAppointments = getStoredTaps.filter((tap) => tap.expiration < (new Date().getTime())).map((t) => t.id);
        const appointmentsThatArentInMyState = state.selectedAppointments.filter((o1) => !getStoredTaps.some((o2) => o1.id === o2.id)).map((a) => a.id);

        let validAppointments = [];
        let validTaps = [];

        if (expiredAppointments.length > 0 ) {
            validAppointments = state.selectedAppointments.filter((e) => !expiredAppointments.includes(e.id));
            validTaps = state.tappedAppointments.filter((e) => !expiredAppointments.includes(e));
        } else if (appointmentsThatArentInMyState.length > 0 ) {
            validAppointments = state.selectedAppointments.filter((e) => !appointmentsThatArentInMyState.includes(e.id));
            validTaps = state.tappedAppointments.filter((e) => !appointmentsThatArentInMyState.includes(e));
        } else {
            validAppointments = [...state.selectedAppointments];
            validTaps = [...state.tappedAppointments];
        }

        return {
            selectedAppointments: validAppointments,
            tappedAppointments: validTaps
        };
    }

    return defaultBookingState;
};

export const BookingContextProvider = (props) => {
    const [bookingState, dispatchBookingAction] = useReducer(bookingReducer, defaultBookingState);

    const addAppointmentHandler = (appointment) => {
        dispatchBookingAction({ type: "ADD-APPOINTMENT", appointment: appointment });
    };

    const updateAppointmentHandler = (event, appointmentId, value, inputName, varietyCheck) => {
        dispatchBookingAction({ type: "UPDATE-APPOINTMENT", event: event, appointmentId : appointmentId, value: value, inputName: inputName, varietyCheck : varietyCheck});
    };

    const removeAppointmentHandler = (id) => {
        dispatchBookingAction({ type: "REMOVE-APPOINTMENT", id: id });
    };

    const checkAppointmentsHandler = (array, time) => {
        dispatchBookingAction({ type: "CHECK-APPOINTMENT", array: array, time: time});
    };

    const appointmentExpirationHandler = () => {
        dispatchBookingAction({ type: "APPOINTMENT-EXPIRATION"});
    };

    const bookingContext = {
        selectedAppointments: bookingState.selectedAppointments,
        tappedAppointments: bookingState.tappedAppointments,
        addAppointment: addAppointmentHandler,
        updateAppointment : updateAppointmentHandler,
        removeAppointment: removeAppointmentHandler,
        appointmenStoredCheck: checkAppointmentsHandler,
        checkForExpiredAppointments: appointmentExpirationHandler,
    };

    return (
        <BookingContext.Provider value={bookingContext}>
            {props.children}
        </BookingContext.Provider>
    );
};

export default BookingContext;