import React, {useReducer} from "react";

const ConfirmContext = React.createContext();
export default ConfirmContext;

export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

export const initialState = {
    show: false,
    text: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_CONFIRM:
            return {
                show: true,
                text: action.payload.text
            };
        case HIDE_CONFIRM:
            return initialState;
        default:
            return initialState;
    }
};

export const ConfirmContextProvider  = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ConfirmContext.Provider value={[state, dispatch]}>
            {children}
        </ConfirmContext.Provider>
    );
}