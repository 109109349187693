import { useEffect, useState, Fragment } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { getUserToken } from "../../../../../data/token";
import { ErrorFeedback } from "../../../../../assets/Feedback";
import { hideHandler } from "../../../../layout/MainLayout";
import { ActionModal } from "../../../../UI/Modal";
import { capitalizeName, capitalizeStr } from "../../../../../assets/Capitalize";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import CustomerCredentials from "./CustomerCredentials";
import CustomerEdit from "./CustomerEdit";
import Button from "../../../../UI/Button";
import visibilityOn from "../../../../../assets/icons/visibility_FILL1_wght400_GRAD0_opsz40.svg";
import visibilityOff from "../../../../../assets/icons/visibility_off_FILL1_wght400_GRAD0_opsz40.svg";
import editIcon from "../../../../../assets/icons/mode_edit_black_24dp.svg";
import searchIcon from "../../../../../assets/icons/search_black_24dp.svg";

export const findCategoryName = (categories, categoryId) => {
    const findMatchingName = categories.find((c) => c.id === categoryId).name;
    return capitalizeStr(findMatchingName)
}

export const findMaxOverride = (categories, categoryId) => {
    const maxOverride = categories.find((c) => c.id === categoryId).maxDailyReservationsOverride;
    return maxOverride + " reservasjoner per dag";
}

const CustomerList = () => {
    const [customerList, setCustomerList] = useState([]);
    const [statusState, setStatusState] = useState("active"); 
    const [checkAll, setCheckAll] = useState(false); 
    const [search, setSearch] = useState(""); 
    const [showCredentials, setShowCredentials] = useState("");
    const [showUpdateCustomer, setShowUpdateCustomer] = useState(false);
    const [userCategories, setUserCategories] = useState([]);
    const [filterCategory, setFilterCategory] = useState(0);
    const [isChecked, setIsChecked] = useState([]);
    
    const tokenData = getUserToken();

    const { data: customers, loading, error} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const { data: categories, categoriesLoading, categoriesError} = useFetch("customer-categories", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    let filterCustomers = customerList;
    let filterByStatus;
    let zeroResult = false;
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    useEffect(() => {
        setCustomerList(customers);
        setUserCategories(categories);
    },[customers, categories]);

    const changeCategory = (event) => {
        event.persist();
        setFilterCategory(parseInt(event.target.value));
    }

    const handleCustomerStateUpdate = (usernames, category) => {
        const updateCategories = customerList.map((customer) => {
            let matchingUser = usernames.find((username) => username === customer.producerNr);
            if (matchingUser) {
                return {
                    ...customer,
                    categoryId: category
                }
            };

            return customer;
        });

        setCustomerList(updateCategories);
        setIsChecked([]);
    }; 

    const handleCheckboxClick = (customer) => {
        setIsChecked((checkedNames) => {
            const nameExists = checkedNames.find((n) => n === customer.producerNr);

            if (nameExists) {
                return checkedNames.filter((n) => n !== customer.producerNr);
            } else {
                return [...checkedNames, customer.producerNr];
            }
        });
    }

    const handleCheckAll = () => {
        const checkedId = filterCustomers.map((u) => u.producerNr);

        if (checkAll) {
            const matchingCheckedIds = isChecked.filter((id) => checkedId.includes(id));
        
            const filteredOutMatchingIds = isChecked.filter((id) =>
                !matchingCheckedIds.some((matchingCheckedId) => {
                    return id === matchingCheckedId;
                })
            );
            
            setIsChecked(filteredOutMatchingIds);
        } else {
            const filteredChecked = checkedId.filter((newCheck) => !isChecked.find((checked) => checked === newCheck));

            setIsChecked((checkedItems) => {
                return [...checkedItems, ...filteredChecked]
            });
        }
    }
    
    if (customers && customerList && statusState && !filterCategory) {
        filterByStatus = statusState && statusState === "active" ? customerList.filter((t) => t.active) : customerList.filter((t) => !t.active);
        filterCustomers = filterByStatus;

        filterCustomers = search ? filterByStatus.filter((user) =>  user.name.toLowerCase().includes(search.toLowerCase())) : filterByStatus;

    } else if(customers && customerList && search && !filterCategory) {
        filterCustomers = search ? customerList.filter((user) =>  user.name.toLowerCase().includes(search.toLowerCase())) : customerList;
    } else if (filterCategory && !statusState) {
        const matchingCategoryUsers = customerList.filter((user) => user.categoryId === filterCategory);
        filterCustomers = matchingCategoryUsers;  
        filterCustomers = search ? matchingCategoryUsers.filter((user) =>  user.name.toLowerCase().includes(search.toLowerCase())) : matchingCategoryUsers;

    } else if (filterCategory && statusState) {
        const matchingCategoryUsers = customerList.filter((user) => user.categoryId === filterCategory);
        const activeUsers = matchingCategoryUsers.filter((t) => t.active);
        const inactiveUsers = matchingCategoryUsers.filter((t) => !t.active);

        if (statusState === "active" && activeUsers.length > 0)  {
            filterCustomers = search ? activeUsers.filter((user) =>  user.name.toLowerCase().includes(search.toLowerCase())) : activeUsers;;
        } else if (statusState === "inactive" && inactiveUsers.length > 0) {
            filterCustomers = search ? inactiveUsers.filter((user) =>  user.name.toLowerCase().includes(search.toLowerCase())) : inactiveUsers;
        } else {
            zeroResult = true;
            filterCustomers = "";
        }
    }

    if ((loading && categoriesLoading) || categoriesLoading || loading) loadingState = true;

    if ((customers?.length > 0  && customerList?.length > 0) && userCategories) renderState = true;

    if (error || categoriesError) errorState = true;

    return (
        <section className="contents__container">
            <h1 className="no-mt">Kundeliste</h1>

            {(loadingState || !renderState) && <LeafLoader loadingMessage="Henter kunder ..."/>}
            
            {errorState && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente kundedata, vennligst last inn siden på nytt."}/>} 
            {categoriesError && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for kategorier, vennligst last inn siden på nytt."}/>} 
        
            {(!loadingState && !errorState) && renderState ? <>
                <p>Nedenfor finner du en liste over alle kunder. Kunder kan filtreres etter status (aktiv / inaktiv / alle), sortres etter kategorier, eller man kan søke etter kundenavn.
                    Om kunder ikke husker deres brukernavn og eller passord, så trykker du bare på <img src={visibilityOn} className="list__visibility" alt="Turn on visibility-icon"/> for å få tilgang til denne informasjonen.
                </p>
                <p>For å sette eller endre kategorier på brukere så velger du først kunden(e) det gjelder, for så å trykke på "<img src={editIcon} className="list__visibility list__visibility--lg" alt="Edit icon"/><span className="bold">Sett kategori</span>"-knappen.</p>
                
                {(showUpdateCustomer && isChecked.length > 0) &&
                    <ActionModal onHide={() => hideHandler(setShowUpdateCustomer)}>
                        <CustomerEdit onHide={() => hideHandler(setShowUpdateCustomer)} customers={customerList} userCategories={userCategories} customerChecked={isChecked} updateChangesToState={handleCustomerStateUpdate} />
                    </ActionModal>
                }
                
                {customerList.length >= 1 ?
                    <div className="list">       
                        <section className="list__form">
                            <section className="list__form__header">
                                <h2>Kunder <span> {customerList.length} stk</span></h2> 
                                <div className="list__form__header--search">
                                    <Button className="button__mr button plain thin" onClick={() => setShowUpdateCustomer(true)} title={isChecked.length <= 0 ? "Velg en kunde først" : "Sett kategori på kunde(r)"} disabled={isChecked.length <= 0}>
                                        <img src={editIcon} alt="Edit icon"/>Sett kategori
                                    </Button>
                                    <select id="categories" value={filterCategory} name="categories" onChange={(e) => {changeCategory(e); setShowCredentials(null); setIsChecked([]); setCheckAll(false);}}>
                                        <option value="0">Vis for kategori</option>
                                        {userCategories?.length >= 1 && userCategories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {capitalizeStr(category.name)}
                                            </option>
                                        ))}
                                    </select>
                                    <select value={statusState} onChange={(event) => {setStatusState(event.target.value); setSearch(""); setShowCredentials(null); setIsChecked([]); setCheckAll(false);}}>
                                        <option value="active">Vis aktive kunder</option>
                                        <option value="inactive">Vis inaktive kunder</option>
                                        <option value="">Vis alle kunder</option>
                                    </select>
                                    <div className="searchbar">
                                        <input placeholder="Søk etter kunder ..." title="Søk etter kunder basert på kundenavn" value={search ? search : ""} onChange={(event) => {setSearch(event.target.value); setShowCredentials(null); setIsChecked([]); setCheckAll(false);}}/>
                                        <img src={searchIcon} alt="Search icon" title="Søk"/>
                                    </div>
                                    <button type="button" className="text__btn emptyFilter" onClick={() => {setSearch(""); setStatusState("active"); setFilterCategory(0); setIsChecked([]); setCheckAll(false);}} title="Fjern filter">&#10006;</button>
                                </div>
                            </section>

                            <section className="list__form__table">
                                    <p> 
                                        Viser {filterCustomers?.length} {filterCustomers.length === 1 ? "kunde" : "kunder"}
                                        <span>/</span>
                                        {isChecked.length} {isChecked.length === 1 ? "kunde" : "kunder"} er valgt
                                    </p>                 
                                
                                <table style={{width: "100%"}} className="table">
                                    <thead>
                                        <tr className="table__head table__head--yellow">
                                            <th className="td__center" style={{ width: "2%"}}>
                                                {filterCustomers.length >= 1 &&
                                                <input type="checkbox" name="checkbox" checked={checkAll} title={checkAll ? "Avvelg alle" : "Velg alle"}
                                                    onChange={() => setCheckAll((check) => !check)} onClick={() => handleCheckAll()} 
                                                />}
                                            </th>
                                            <th style={{ minWidth: "40%", paddingLeft: "15px" }}>Kundenavn</th>
                                            <th className="td__center"  style={{ width: "13%" }}>Kategori</th>
                                            <th className="td__center" style={{ width: "13%" }}>Lagerleie</th>
                                            <th className="td__center" style={{ width: "10%" }}>Status</th>
                                            <th className="td__center" style={{ width: "10%" }}>Brukerdata</th>
                                        </tr>
                                    </thead>

                                    {filterCustomers.length >= 1 &&
                                        <tbody className="table__reverse">
                                            {filterCustomers?.map((customer, index) => ( 
                                                <Fragment key={index}>
                                                    <tr className={`table__row ${showCredentials === index ? "table__row--open" : ""} ${isChecked.find((n) => n === customer.producerNr) && "table__row__checked"}`}>
                                                        <td className="td__center td__center--checkbox">
                                                            <input type="checkbox" name="checkbox" checked={isChecked.includes(customer.producerNr)} 
                                                                onChange={() => {handleCheckboxClick(customer); setShowCredentials("")}} title="Velg denne kunden"
                                                            />
                                                        </td>
                                                        <td style={{ paddingLeft: "15px" }}>{capitalizeName(customer.name)}</td>
                                                        <td className="td__center" title={customer.categoryId ? findMaxOverride(userCategories, customer.categoryId) : ""}>{customer.categoryId ? findCategoryName(userCategories, customer.categoryId) : ""}</td>
                                                        <td className="td__center" title="Om kunden har tilgang til å bestille lagerleie">{customer.warehouseEligible ? "Ja" : ""}</td>
                                                        <td className="td__center">{customer.active ? 
                                                            <span className="pill pill__active">Aktiv</span> 
                                                            : <span className="pill pill__inactive">Inaktiv</span>}
                                                        </td>
                                                        <td className="td__center">
                                                            <Button onClick={() => setShowCredentials(showCredentials === index ? "" : index)}
                                                                className="text__btn action" title={showCredentials === index  ? "Skjul brukerinformasjon" : "Vis brukerinformasjon"}>
                                                                {showCredentials === index ? 
                                                                    <><img src={visibilityOff} alt="Turn off visibility-icon"/> Skjul</> : <><img src={visibilityOn} alt="Turn on visibility-icon"/> Vis</>
                                                                } 
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                    {showCredentials === index && 
                                                        <CustomerCredentials user={customer}/> 
                                                    }
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    } 

                                    {filterCustomers.length <= 0 && 
                                        <tbody>
                                            <tr style={{ borderBottom: "none" }} className="table__empty">
                                                <td colSpan={5}>
                                                    {search && <p className="empty-result empty-result--positive">Fant ingen kunder med det navnet.</p>}
                                                    {zeroResult && <p className="empty-result empty-result--positive">Fant ingen kunder.</p>}
                                                    {(statusState && !search && !filterCategory) && <p className="empty-result empty-result--positive">Fant ingen kunder med den statusen.</p>}
                                                    {filterCategory && !search && !statusState ? <p className="empty-result empty-result--positive">Fant ingen kunder med den kategorien.</p> : ""}
                                                    {(!search && !statusState && !filterCategory) && <p className="empty-result empty-result--positive">Fant ingen kunder.</p>}
                                                </td>
                                            </tr>
                                        </tbody>
                                    }
                                </table>
                            </section>
                        </section>
                    </div>
                : <p className="empty-result empty-result--positive">Det finnes ingen kunder enda.</p>}
            </>: ""}
        </section>
    );
};

export default CustomerList;