import { Fragment, useContext } from "react";
import AuthContext from "../../context/auth-context";
import { Link, NavLink, useNavigate } from "react-router-dom";
import loginIcon from "../../assets/icons/person_FILL1_wght400_GRAD0_opsz40.svg";
import logo from "../../assets/icons/braskereidfosslogo.png";

const MainNavigation = (props) => {
    const userCtx = useContext(AuthContext);
    const navigate = useNavigate();

    const logoutHandler = () => {
        userCtx.logout();
    }

    const forwardToAdmin = () => {
        navigate("/administrator/timeovervoker");
    }

    return (
        <header>
            <nav className="nav">

                <div className="nav__section nav__logo">
                    <Link to="/" className="nav__section__logo" title="Til forsiden">
                        <img className="nav__section__icon" src={logo} alt="Braskereidfoss Kornsilo logo"/>
                        <div>
                            <p>Braskereidfoss Kornsilo</p>
                            <h1>Timebestilling</h1>
                        </div>
                    </Link>
                </div>

                {userCtx.isLoggedIn ? (   
                    <Fragment>
                        <div className={`nav__section nav__section--access ${!userCtx.isAdmin ? "reverse" : ""}`}>
                            {userCtx.isAdmin && <p className="nav__section__actions" onClick={forwardToAdmin} title="Gå til administrator panel">Administrator</p>}

                            <div>
                                <img className="nav__section__icon" src={loginIcon} alt="Logout icon"/>
                                <p className="nav__section__actions" onClick={logoutHandler} title="Logg ut">Logg Ut</p>
                            </div>
                        </div>
                        
                        <div className="nav__navigation">
                            <ul className="nav__navigation__ul">
                                <li className="nav__navigation__ul--item">
                                    <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to="/" title="Gå til forsiden">Hjem</NavLink>
                                </li>
                                <li className="nav__navigation__ul--item">
                                    <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to="/timebestilling" title="Gå til timebestilling">Timebestilling</NavLink>
                                </li>
                                <li className="nav__navigation__ul--item">
                                    <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to="/mine-timer" title="Gå til Mine Timer">Mine Timer</NavLink>
                                </li>
                                <li className="nav__navigation__ul--item">
                                    <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to="/arkiv" title="Gå til Arkiv">Arkiv</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                    ) : (
                    <Fragment>
                        <div className="nav__section nav__section--access reverse">
                            <div>
                                <img className="nav__section__icon" src={loginIcon} alt="Login icon"/>
                                <p className="nav__section__actions" onClick={props.toggleModal} title="Logg inn">Logg Inn</p>
                            </div>
                        </div>
                        <div className="nav__navigation">
                            <ul className="nav__navigation__ul">
                                <li className="nav__navigation__ul--item">
                                    <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to="/" title="Til forsiden">Hjem</NavLink>
                                </li>
                            </ul>
                        </div>
                    </Fragment>
                )}
            </nav>
        </header>
    );
};

export default MainNavigation;
