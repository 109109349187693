import { useState, Suspense, lazy, useEffect } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { apiUrl } from "../../../../../data/API";
import { getUserToken } from "../../../../../data/token";
import { hideHandler } from "../../../../layout/MainLayout";
import { ActionModal } from "../../../../UI/Modal";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import Button from "../../../../UI/Button";
import useConfirm from "../../../../../hooks/useConfirm";
import { formatTime, formatDate } from "../../../../../assets/DateFormatOptions";
import { PrintButtonUsers } from "../../../../UI/PrintButton";
import { ErrorFeedback } from "../../../../../assets/Feedback";
import { groupAllAppointments } from "../../../../../assets/GroupAppointments";
import { getUserName } from "../../../../../assets/GetUserCredentials";
import { findGrainTypeName, findVarietyName,isSprayed } from "../../../user/UserService";
import checkmark from "../../../../../assets/icons/check_black_24dp.svg";
import readComment from "../../../../../assets/icons/comment_black_24dp.svg";
import searchIcon from "../../../../../assets/icons/search_black_24dp.svg";
import editIcon from "../../../../../assets/icons/mode_edit_black_24dp.svg";

const ShowComment = lazy(() => import("./dashboard/AppointmentBoardComment"));
const ExportAppointmentData = lazy(() => import("./dashboard/AppointmentBoardExport"));

const todaysDate = new Date().toLocaleDateString("en-CA");

const HistoricAppointments = () => {
    const [appointments, setAppointments] = useState([]); 
    const [historicDay, setHistoricDay] = useState(todaysDate); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [modalId, setModalId] = useState(null); 
    const [showExportModal, setShowExportModal] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [search, setSearch] = useState(""); 
    const [markAttendanceLoading, setMarkAttendanceLoading] = useState(false);

    const tokenData = getUserToken();

    const {confirm} = useConfirm();

    const { data: customers, customerLoading, customerError} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const { data: grains, grainLoading, grainError} = useFetch("grains", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    let appointmentLoading = false;
    let loadingState = false;
    let renderState = false;
    let errorState = false;
    let filteredAppointments = appointments;

    const fetchAndSet = (time) => {
        setLoading(true);                            
        setError("");
        fetch(apiUrl + `appointments/historic/${time}`, {
            method: "GET",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json",},
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data) {
                        const appointments = data.map((app) => {
                            if (app.reservedBy) {
                                return {
                                    ...app,
                                    reservedByUser: getUserName(customers, app.reservedBy)
                                }
                            }
                            return app;
                        })
                        setAppointments(appointments);
                        setLoading(false);
                    } else {
                        setError("Kunne ikke finne noe data for denne dagen, vennligst last inn siden på nytt.");
                        setLoading(false);
                    }
                })
            } else {
                return response.json().then((data) => {
                    setError("Kunne ikke hente data for denne dagen, vennligst last inn siden på nytt og forsøk igjen.");
                    setLoading(false);
                });
            }
        }).catch((error) => {
            setError("Kunne ikke hente data for denne dagen, vennligst last inn siden på nytt og forsøk igjen.");
            setLoading(false);
        });
    };

    useEffect(() => {
        if (customers?.length > 0) {
            fetchAndSet(historicDay);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[historicDay, customers]);

    const changeDayHandler = (event) => {
        const value = event.target.value;
        return value ? setHistoricDay(event.target.value) : setHistoricDay(todaysDate);
    }

    const handleMarkAttendance = async (event, id, key) => {
        event.preventDefault();

        setMarkAttendanceLoading(true);

        await fetch(apiUrl + `appointments/${id}/${key}`, {
            method: "PATCH",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
        }).then((response) => {
            if (response.ok) {
                setMarkAttendanceLoading(false);

                setAppointments((prev) => {
                    const updateAppointments = prev.map((appointment) => {
                        if (appointment.id === id) {
                            return {...appointment, attended : key === "attended" ? true : false};
                        } 
                        return appointment;
                    });
        
                    return updateAppointments;
                });
                
                return response.text();
            } else {
                setMarkAttendanceLoading(false);
                return response.json().then((data) => {
                    if (data.message === "APPOINTMENT_YET_TO_COME") {
                        confirm("Det har oppstått en feil - denne timen har ikke passert enda.");
                    } else if (data.message === "APPOINTMENT_DOES_NOT_EXIST") {
                        confirm("Det har oppstått en feil - denne timen finnes ikke lengere.");
                    } else {
                        confirm("Det oppsto en feil ved markering av fravær, vennligst last inn siden på nytt og prøv igjen.");
                    }
                })
            }                
        }).catch((error) => {
            confirm("Det oppsto en feil ved markering av fravær, vennligst last inn siden på nytt og prøv igjen.");
        });
    }

    if ((customerLoading && grainLoading) || customerLoading || grainLoading ) loadingState = true;

    if (loading) appointmentLoading = true;
    
    if ((error && customerError && grainError) || error || customerError || grainError ) errorState = true;

    if (appointments && (customers && customers.length > 0) && (grains && grains.length > 0) && !errorState) renderState = true;

    if (search && appointments.length > 0) {
        filteredAppointments = search ? appointments.filter((a) => a.reservedBy && a.reservedByUser.toLowerCase().includes(search.toLowerCase())) : appointments;
    };

    return (
        <section className="contents__container">
            <h1 className="no-mt">Historiske timer</h1>
            <p>Her kan du se alle ledige og reserverte timer for en enkelt dag. Du kan se historikken til alle tidligere dager ved å velge de i kalenderen nedenfor.</p>
            
            {(loadingState || !renderState) && <LeafLoader loadingMessage="Laster inn kunder og timer ..."/>}
            {(errorState && !renderState) && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for historiske timer, vennligst last inn siden på nytt."}/>} 
            {renderState &&
                <div className="list">       
                    <section className="list__form">
                        <section className="list__form__header">
                            <h2>Historikk per dag</h2> 
                            <div className="dashboard__header__dropdown">
                                <label htmlFor="historicDate">Velg dag </label> 
                                <input id="historicDate" type="date" value={historicDay} max={todaysDate} name="historicDate" onChange={(e) => changeDayHandler(e)}/>
                                
                                <div id="customerFilter">
                                    <div className="searchbar">
                                        <input placeholder="Søk etter kunder ..." disabled={appointments.length <= 0} title={appointments.length <= 0 ? "Det finnes ingen timer" : "Filtrer timer på kunder"}
                                        autoComplete="off" type="text" id="searchCustomer" name="searchCustomer" value={search} onChange={(event) => setSearch(event.target.value)}/>
                                        <img src={searchIcon} alt="Search icon" onClick={() => setSearch("")}/>
                                    </div>
                                </div> 
                                <PrintButtonUsers className="print" disabled={filteredAppointments.length === 0} onClick={() => setShowExportModal(true)} title={filteredAppointments.length === 0 ? "Det finnes ingen timer å printe" : "Åpne valg for eksportering av timedata"}></PrintButtonUsers>
                            </div>
                        </section>
                        
                        <section className="list__content limited">
                            <div className="booking__section--table">
                                {!appointmentLoading ? <>
                                    <h3>Viser timer for {formatDate(historicDay)} ({filteredAppointments.length} {filteredAppointments.length === 1 ? "time" : "timer"})</h3>
                                    <p></p>
                                    <table style={{width: "100%"}} className="table">
                                        <thead>
                                            <tr className="table__head table--attendance">
                                                <th style={{minWidth:"60px", width:"5%"}}>Tid</th>
                                                <th style={{minWidth:"100px", width:"7%"}}></th>
                                                <th style={{minWidth:"29%"}}>Kunde</th>
                                                <th className="td__center" style={{width:"6%"}}>Tonn</th>
                                                <th className="td__center" style={{width:"10%"}}>Art</th>
                                                <th className="td__center" style={{width:"10%"}}>Sort</th>
                                                <th className="td__center" style={{width:"7%"}}>Lager</th>
                                                <th className="td__center" style={{width:"7%"}}>Publisert</th>
                                                <th className="td__center" style={{width:"10%"}}>Kommentar</th>
                                                <th className="table__head--action td__center" style={{width: "8%"}}>Oppmøte</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredAppointments.length >= 1 ? filteredAppointments.map((appointment) => (
                                                <tr className="table__row" key={appointment.id}>
                                                    <td>{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</td>
                                                    <td>
                                                        {appointment.reservedBy && <>
                                                            {appointment.attended ? 
                                                                <span className={`pill pill__attendance pointer`}>Møtt</span>
                                                            :
                                                                <span className={`pill pill__attendance pill__attendance--error pointer`}>Ikke møtt</span>
                                                            }
                                                        </>}
                                                    </td>
                                                    <td>{appointment.reservedBy ? getUserName(customers, appointment.reservedBy) : ""}</td>
                                                    <td className="td__center">{appointment.amount && appointment.amount}</td>
                                                    <td className="td__center">
                                                        {appointment.reservedBy ? (
                                                            <>
                                                                {findGrainTypeName(grains, appointment.grainTypeId)}
                                                                {" "}{isSprayed(grains, appointment, "serverside")}
                                                            </>
                                                            ) : (
                                                                ""
                                                        )}
                                                    </td>
                                                    <td className="td__center">{appointment.reservedBy && findVarietyName(grains, appointment.grainTypeId, parseInt(appointment.varietyTypeId))}</td>
                                                    <td className="td__center">{appointment.warehouseRental ? <img src={checkmark} alt="Comment icon" style={{ height: "21px"}}/> : ""}</td>
                                                    <td className="td__center">{appointment.published ? "Ja" : "Nei"}</td>
                                                    <td className="td__center">                                                             
                                                        {appointment.comment && 
                                                            <Button className="action mass__actions--btn" title="Les kommentar" onClick={() => {setShowComment(true); setModalId(appointment.id)}}>
                                                                <img src={readComment} alt="Comment icon"/>
                                                            </Button>
                                                        }
                                                    </td>
                                                    <td className="td__center">
                                                        {appointment.reservedBy && 
                                                            <Button className="action mass__actions--btn" onClick={(e) => handleMarkAttendance(e, appointment.id, appointment.attended ? "unattended" : "attended")} title="Endre oppmøte" disabled={markAttendanceLoading}>
                                                                <img src={editIcon} alt="Edit icon"/>
                                                            </Button>
                                                        }
                                                    </td>

                                                    {(showComment && modalId === appointment.id) &&
                                                        <ActionModal onHide={() => hideHandler(setShowComment)}>
                                                            <Suspense fallback="">
                                                                <ShowComment onHide={() => hideHandler(setShowComment)} app={appointment} customers={customers}/>
                                                            </Suspense>
                                                        </ActionModal>
                                                    }
                                                </tr> 
                                                )) 
                                                : 
                                                <>
                                                {search ? 
                                                    <tr><td colSpan={8}>Fant ingen kunder med det navnet.</td></tr>
                                                    :
                                                    <tr><td colSpan={8}>Ingen timer denne dagen.</td></tr>
                                                }</>
                                            }   
                                        </tbody>
                                    </table>
                                </>
                                : 
                                    <p className="centered-text">Laster ...</p>
                                }
                                {showExportModal && 
                                    <ActionModal onHide={() => hideHandler(setShowExportModal)}>
                                        <Suspense fallback="">
                                            <ExportAppointmentData onHide={() => hideHandler(setShowExportModal)} date={historicDay} data={groupAllAppointments(filteredAppointments)} customers={customers} grains={grains}/>
                                        </Suspense>
                                    </ActionModal>
                                }
                            </div>
                        </section>
                    </section>
                </div>
            }
        </section>
    );
};
 
export default HistoricAppointments; 
