import { apiUrl } from "../../../../../../data/API";
import { calculateThreshold } from "../../../../user/UserService";

export const untapTerms = ["AMOUNT_MUST_BE_GREATER_THAN_ZERO", "GRAIN_DOES_NOT_EXIST", "VARIETY_DOES_NOT_EXIST", "VARIETY_IS_REQUIRED", "AMOUNT_EXCEEDS_THRESHOLD"];

export const getResponseErrors = (data) => {
    const tapErrors = data.filter((response) => response.status !== "OK");

    let tapErrorsStatusIds = [];
    let expiredAppointments = [];
    let failedAppointments = [];

    let responseStatus = {};

    if (tapErrors.length > 0) {
        tapErrorsStatusIds = tapErrors.map((e) => e.id);
        expiredAppointments = tapErrors.filter((res) => res.status === ("APPOINTMENT_HAS_PASSED" || "APPOINTMENT_DOES_NOT_EXIST"));
        failedAppointments = tapErrors.filter((res) => res.status !== ("APPOINTMENT_HAS_PASSED" || "APPOINTMENT_DOES_NOT_EXIST"));
        
        responseStatus = { tapErrors: tapErrors, tapErrorsIds: tapErrorsStatusIds, expiredAppointments: expiredAppointments, failedAppointments: failedAppointments}
    }

    return responseStatus;
}

export const setStateError = (state, items) => {
    state((error) => {
        return [...error, ...items];
    });
}

export const setItemInState = (state, id) => {
    state((items) => {
        const idExists = items.find((a) => a === id);
    
        if (idExists) {
            return items.filter((a) => a !== id);
        } else {
            return [...items, id];
        }
    });
}

export const getRefreshToken = () => {
    const storedRefresh = localStorage.getItem("auto-refresh");

    if (!storedRefresh) {
        localStorage.setItem("auto-refresh", false);
    } 

    return storedRefresh;
}

export const setRefreshToken = (token) => {
    localStorage.setItem("auto-refresh", parseInt(token));
};

export const setDateFilter = (filterState, date, searchState) => {
    if (date) {
        filterState(date);
    }
    searchState("");
};

export const filterAppointmentDays = (array, daysWithChanges) => {
    const filteredAppointmentDays = array.filter(appointmentDay => 
        !daysWithChanges.some(changedAppointmentDay => changedAppointmentDay.day === appointmentDay.day));

    const combineArrays = filteredAppointmentDays.concat(daysWithChanges);
    const sortedArray = combineArrays.slice(0).sort((a,b)=> a.day.localeCompare(b.day));
    
    return sortedArray;
}

export const changeMultipleStatesHandler = (value, changeMultipleState, orderMultipleState, updatePubState, setState) => {
    if (value === "0") {
        orderMultipleState(true); 
        changeMultipleState(false); 
        updatePubState(false);
    } else if (value === "1") {
        changeMultipleState(false); 
        updatePubState(false);
        orderMultipleState(false);
    } else if (value === "2") {
        changeMultipleState(true); 
        orderMultipleState(false);
    };

    setState(value);
};

const TableHead = () => {
    return (
        <thead>
            <tr className="table__head">
                <th className="table__head--main td__center" style={{ minWidth: "37px", width: "2%" }}></th>
                <th className="table__head--main" style={{ minWidth: "72px", width: "6%" }}>Tid</th>
                <th style={{ minWidth: "30%" }} title="Brukernavn til kunde" >Kunde</th>
                <th className="td__center" style={{ width: "5%" }}>Tonn</th>
                <th className="td__center" style={{ width: "10%" }}>Art</th>
                <th className="td__center" style={{ width: "6%" }}>Sort</th>
                <th className="td__center" style={{ width: "6%" }}>Lager</th>
                <th className="td__center" style={{ width: "6%" }}>Publisert</th>
                <th className="td__center" style={{ width: "12%" }}>Publiseringstid</th>
                <th className="table__head--action td__center" style={{ width: "7%" }}>Kommentar</th>
                <th className="table__head--action td__center td__mass__actions" style={{ minWidth: "190px", width: "10%" }}>Handlinger</th>
            </tr> 
        </thead>
    );
};
export default TableHead;

export const checkboxDay = (dataArray, checkedState, actionState, username, tappedErrorState, token, tapErrorState, isCheckedArray, setExpiredState, tapDisabledState, setOverlayState, overlayArray) => {              
    const getPastAppointments = dataArray.filter((a) => new Date(a.scheduledAt).getTime() < new Date().getTime()).map((a) => parseInt(a.id));
    const getPrestentAppointments = dataArray.filter((a) => new Date(a.scheduledAt).getTime() > new Date().getTime()).map((a) => parseInt(a.id));
    const filteredChecked = getPrestentAppointments.filter((newAppointment) => !isCheckedArray.find((checked) => checked === newAppointment));
    const day = dataArray[0].scheduledAt.split("T")[0];

    checkedState((checkedItems) => {
        return [...checkedItems, ...filteredChecked]
    });

    setExpiredState((expiredItems) => {
        return [...expiredItems, ...getPastAppointments]
    });

    if (actionState === "tap") {
        handleCheckboxTap(filteredChecked, username, actionState, tappedErrorState, token, tapErrorState, filteredChecked, checkedState, tapDisabledState, setExpiredState, setOverlayState, overlayArray, day);
    };
};

export const handleCheckboxClick = (app, checkedState, check, username, actionState, tappedErrorState, token, tapErrorState, isCheckedArray, setExpiredState, tapDisabledState) => {
    if (new Date(app.scheduledAt).getTime() > new Date().getTime()) {
        setItemInState(checkedState, app.id);
    } else {
        setItemInState(setExpiredState, app.id);
    };

    if (check === "tapCheck") {
        handleCheckboxTap([app.id], username, actionState, tappedErrorState, token, tapErrorState, isCheckedArray, checkedState, tapDisabledState, setExpiredState);
    };
};

export const uncheckDay = (checkedArray, dataArray, checkedState) => {
    const matchingCheckedIds = checkedArray.filter((appointment) =>
        dataArray.includes(appointment)
    );

    const filteredOutMatchingIds = checkedArray.filter(
        (appointment) =>
            !matchingCheckedIds.some((matchingCheckedId) => {
                return appointment === matchingCheckedId;
            })
    );

    checkedState(filteredOutMatchingIds);
};

export const removeAllChecked = (checkedState) => {
    checkedState([]);
};

export const updateAppointmentState = (appointment, array, updateState) => {
    const getDay = appointment.scheduledAt.split("T")[0];

    const updatedState = array.map((date) => {
        if (date.day !== getDay) return date;

        return {
            ...date,
            appointments: date.appointments.map((app) => {
                if (app.id !== appointment.id) return app;

                let updateApp = {
                    ...app,
                    amount: appointment.amount,
                    grainTypeId: appointment.grainTypeId,
                    varietyTypeId: appointment.varietyTypeId,
                    reservedBy: appointment.reservedBy,
                    comment: appointment.comment,
                    warehouseRental: appointment.warehouseRental,
                };

                if (appointment.sprayed !== undefined) {
                    updateApp = {
                        ...updateApp,
                        sprayed: appointment.sprayed
                    };
                }

                return updateApp;
            }),
        };
    });

    updateState(updatedState);
};


export const updateAppointmentMovingState = (appointment, newId, newDate, publishState, appointmentArray, appointmentState) => {
    const oldDate = appointment.scheduledAt.split("T")[0];

    const moveAppointmentInState = appointmentArray.map((date) => {
        if (date.day !== newDate) return date;

        return {
            ...date,
            appointments: date.appointments.map((app) => {
                if (app.id !== newId) {
                    return app;
                } else {
                    return {
                        ...app,
                        id: app.id,
                        scheduledAt: app.scheduledAt,
                        reservedBy: appointment.reservedBy,
                        grainTypeId: appointment.grainTypeId,
                        varietyTypeId: appointment.varietyTypeId,
                        amount: appointment.amount, 
                        published: true,
                        warehouseRental: appointment.warehouseRental,
                        comment: appointment.comment,
                        attended: appointment.attended,
                    };
                } 
            }),
        };
    });

    const removeOldAppointment = moveAppointmentInState.map((date) => {
        if (date.day !== oldDate) return date;

        return {
            ...date,
            appointments: date.appointments.map((app) => {
                if (app.id !== appointment.id) {
                    return app;
                } else {
                    return {
                        id: app.id,
                        scheduledAt: app.scheduledAt,
                        published: publishState === false ? true : false,
                        publishedAt: null,
                        warehouseRental: false
                    };
                } 
            }),
        };
    });

    appointmentState(removeOldAppointment);
};


export const updateOrderState = (appointmentIds, order, responses, array, updateState, checkedState, commented, tapErrorState, expirationState, token) => {
    const response = getResponseErrors(responses);
    const hasResponses = Object.keys(response).length > 0 ? true : false;

    const changedAppointmentDays = array
        .filter((day) => day.appointments.some((appointment) => appointmentIds.includes(appointment.id)))
        .map((day) => {
            return {
                ...day,
                appointments: day.appointments.map((appointment) => {
                    let updatedAppointment;

                    if (!appointmentIds.includes(appointment.id)) return appointment;
                    if (hasResponses && response?.tapErrors.length > 0 && response?.tapErrorsIds.includes(appointment.id)) {
                        return appointment;
                    };

                    updatedAppointment = {
                        ...appointment, 
                            grainTypeId: order.grainTypeId,
                            varietyTypeId: order.varietyTypeId ? order.varietyTypeId : null,
                            amount: order.amount, 
                            reservedBy: order.reservedBy,
                            warehouseRental: order.warehouseRental,
                    }

                    if (order.sprayed !== undefined) {
                        updatedAppointment = {
                            ...updatedAppointment,
                            sprayed: order.sprayed ? false : true
                        };
                    }

                    if (commented) {
                        updatedAppointment = {...updatedAppointment, comment: order.comment}
                    } 

                    return updatedAppointment;
                })
            }
    });

    const sortedArray = filterAppointmentDays(array, changedAppointmentDays);
 
    if (hasResponses && response?.failedAppointments.length > 0) {
        const untappableAppointments = response.failedAppointments.filter((res) => untapTerms.includes(res.status)).map((a) => a.id);

        if (untappableAppointments.length > 0) {
            untapOnError(untappableAppointments, token); 
        }

        setStateError(tapErrorState, response.failedAppointments); 
    } 

    if (hasResponses && response?.expiredAppointments.length > 0) {
        const expiredAppointmentsIds = response.expiredAppointments.map((e) => e.id);
        setStateError(expirationState, expiredAppointmentsIds); 
    } 
    
    updateState(sortedArray);
    checkedState([]);
};

export const updatePublishState = (checked, status, app, array, updateState) => {
    const publishState = status === "publish" ? true : false;

    const getDay = app.scheduledAt.split("T")[0];
    const updatedState = array.map((date) => {
        if (date.day !== getDay) return date;

        if (checked === true) {
            return {
                ...date,
                appointments: date.appointments.map((appointment) => {
                    if (appointment.id !== app.id) return appointment;

                    return {
                        ...appointment,
                        published: publishState,
                        publishAt: null,
                    };
                }),
            };
        } else {
            return {
                ...date,
                appointments: date.appointments.map((appointment) => {
                    if (appointment.id !== app.id) return appointment;

                    return {
                        ...appointment,
                        publishAt: checked,
                    };
                }),
            };
        } 
    });

    updateState(updatedState);
};

export const updatePublishStatusMultiple = (event, mode, idArray, array, state, errorState, saveState, successState, checkedState, token, tapErrorState, expirationState, selectedDayState) => {
    event.preventDefault();
    
    const updateMultipleIds = idArray.toString();

    successState("");
    errorState("");
    saveState(mode === "publish" ? "Publiserer timer ..." : "Avpubliserer timer ...");

    if (idArray.length > 0) {
        fetch(apiUrl + `appointments/${mode}-multiple?appointmentIds=${updateMultipleIds}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        }).then((response) => {
            if (response.ok) {
                errorState("");
                saveState("");
                selectedDayState({selected: []});

                return response.json().then((data) => {
                    const response = getResponseErrors(data);
                    const hasResponses = Object.keys(response).length > 0 ? true : false;

                    const changedAppointmentDays = array
                        .filter((appointmentDay) => appointmentDay.appointments.some((appointment) => idArray.includes(appointment.id)))
                        .map((day) => {
                            return {
                                ...day,
                                appointments: day.appointments.map((appointment) => {
                                    if (!idArray.includes(appointment.id)) return appointment;
                                    if (hasResponses && response.tapErrors.length > 0 && response.tapErrorsIds.includes(appointment.id)) return appointment;

                                    return {
                                        ...appointment, 
                                        published: mode === "publish" ? true : false,
                                        publishAt: null}
                                })
                            }
                    });

                    const sortedArray = filterAppointmentDays(array, changedAppointmentDays);

                    if (hasResponses && response.failedAppointments.length > 0) {
                        setStateError(tapErrorState, response.failedAppointments); 
                    } 
                
                    if (hasResponses && response.expiredAppointments.length > 0) {
                        const expiredAppointmentsIds = response.expiredAppointments.map((e) => e.id);
                        setStateError(expirationState, expiredAppointmentsIds); 
                    } 

                    const updateState = hasResponses && (response.expiredAppointments.length > 0 || response.failedAppointments.length > 0) 
                        ? "Kunne ikke oppdatere alle timer" 
                        : `Oppdatering var vellykket, valgte timer ble ${mode === "publish" ? "publisert" : "avpublisert"}`
                    ;

                    state(sortedArray);
                    checkedState([]);
                    successState(updateState);
                })
            }      
             else {
                saveState("");
                successState("");
                return response.json().then((data) => {
                    if (data.message === "ILLEGAL_APPOINTMENT_IDS_FORMAT") {
                        errorState("Det oppsto en feil ved utvalget av timer for endring, last inn siden på nytt og prøv igjen.");
                    } else {
                        errorState("Det oppsto en feil ved endring av publisering, last inn siden på nytt og prøv igjen.");
                    }
                })
            }                
        }).catch((error) => {
            errorState("Det oppsto en feil ved endring av publisering, last inn siden på nytt og prøv igjen.");
        });
    };
}

export const updatePublisAtMultiple = (event, publishAt, idArray, array, state, errorState, saveState, successState, closeState, checkedState, token, tapErrorState, expirationState) => {
    event.preventDefault(); 
    const updateMultipleIds = idArray.toString();

    successState("");
    errorState("");
    saveState("Lagrer endringer ...");

    if (idArray.length > 0) {
        fetch(apiUrl + `appointments/publish-multiple?appointmentIds=${updateMultipleIds}&optionalPublishAt=${publishAt}`, {
            method: "PATCH",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"}
        }).then((response) => {
            if (response.ok) {
                errorState("");
                saveState("");

                return response.json().then((data) => {
                    const response = getResponseErrors(data);
                    const hasResponses = Object.keys(response).length > 0 ? true : false;

                    const changedAppointmentDays = array
                        .filter((appointmentDay) => appointmentDay.appointments.some((appointment) => idArray.includes(appointment.id)))
                        .map((day) => {
                            return {
                                ...day,
                                appointments: day.appointments.map((appointment) => {
                                    if (!idArray.includes(appointment.id)) return appointment;
                                    if (hasResponses && response.tapErrors.length > 0 && response.tapErrorsIds.includes(appointment.id)) return appointment;

                                    return {
                                        ...appointment,
                                            published: false,
                                            publishAt: publishAt
                                    }
                                })
                            }
                    });

                    const sortedArray = filterAppointmentDays(array, changedAppointmentDays);

                    if (hasResponses && response.failedAppointments.length > 0) {
                        setStateError(tapErrorState, response.failedAppointments); 
                    } 
                
                    if (hasResponses && response.expiredAppointments.length > 0) {
                        const expiredAppointmentsIds = response.expiredAppointments.map((e) => e.id);
                
                        setStateError(expirationState, expiredAppointmentsIds); 
                    } 

                    const updateState = hasResponses && (response.expiredAppointments.length > 0 || response.failedAppointments.length > 0)
                        ? "Kunne ikke oppdatere alle timer" 
                        : "Oppdatering var vellykket, valgte timer ble oppdatert!"
                    ;

                    state(sortedArray);
                    closeState(false);
                    checkedState([]);
                    successState(updateState);
                })
            }  
            else {
                saveState("");
                successState("");
                return response.json().then((data) => {
                    if (data.message === "PUBLISH_AT_CANNOT_BE_AFTER_SCHEDULED_AT" ) {
                        errorState("Du kan ikke endre publiseringsdatoen til et tidspunkt etter dato/klokkelsett som timen er satt opp til.");
                    } else if (data.message === "ILLEGAL_APPOINTMENT_IDS_FORMAT") {
                        errorState("Det oppsto en feil ved utvalget av timer for endring, last inn siden på nytt og prøv igjen.");
                    } else {
                        errorState("Det oppsto en feil ved endring av publiseringsdato og klokkeslett. Last inn siden på nytt og forsøk igjen.");
                    }
                });
            }                
        }).catch((error) => {
            saveState("");
            errorState("Det oppsto en feil ved endring av publiseringsdato og klokkeslett. Last inn siden på nytt og forsøk igjen.");
        });
    }
}

export const removeFromState = (e, id, stateArray, setState) => {
    e.preventDefault();
    const updateState = removeAppointmentFromDayState(id, stateArray);
    setState(updateState);
}

export const deleteOne = async (item, confirm, array, setDeleteError, setArray, token) => {
    deleteSingular(
        "Er du sikker på at du vil slette denne timen? Denne handlingen er permanent.",
        item, confirm, array, setDeleteError, setArray, token
    );
};

export const deleteSelected = (items, confirm, array, setArray, setDeleteError, saveState, checkedState, setFeedback, filter, filterState, token, setTapError) => {
    deleteMultiple(
        `Er du sikker på at du vil slette ${
            items.length === 1
                ? "denne ene timen?"
                : `disse ${items.length} timene?`
        } Denne handlingen er permanent.`,
        items, confirm, array, setArray, setDeleteError, saveState, checkedState, setFeedback, filter, filterState, token, setTapError
    );
};

export const deleteSingular = async (message, appointment, confirm, array, errorState, setState, token) => {
    const isConfirmed = await confirm(message);

    if (isConfirmed) {
        return fetch(apiUrl + "appointments/" + appointment.id, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            }
        }).then((response)=> {
            if (response.ok) {
                const updateState = removeAppointmentFromDayState(appointment.id, array);
                setState(updateState);
            } else {
                return response.json().then((data) => {
                    if (data.message === "APPOINTMENT_DOES_NOT_EXIST") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det oppsto en feil ved sletting av timen; timen eksisterer ikke på serveren."}]});
                    } else if (data.message === "APPOINTMENT_ALREADY_RESERVED") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Kunne ikke slette timen - timen er allerede reservert."}]});
                    } else if (data.message === "APPOINTMENT_ALREADY_TAPPED") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Kunne ikke slette timen - den er valgt men ikke bestilt enda."}]});
                    } else {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det har oppstått en feil ved sletting av denne timen."}]});
                    } 
                });
            }
        }).catch((error) => {
            errorState((items) => {return [...items, {id: appointment.id, message: "Det har oppstått en feil ved sletting av denne timen."}]});
        });
    } else {    
        return;
    };
}

export const deleteMultiple = async (message, appointmentIds, confirm, array, setState, errorState, saveState, checkedState, feedbackState, filter, filterState, token, tapErrorState) => {
    const confirmDeleteMultiple = await confirm(message);

    if (confirmDeleteMultiple) {
        feedbackState("");
        errorState("");
        saveState("Sletter timer ...");

        return fetch(apiUrl + "appointments", {
            method: "DELETE", 
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"},
            body: JSON.stringify(appointmentIds)
        }).then((response)=> {
            if (response.ok) {
                errorState("");
                saveState("");

                return response.json().then((data) => {
                    const tapErrorsStatus = data.filter((s) => s.status !== "OK");
                    const appointmentsForDelete = data.filter((s) => s.status === "OK").map((e) => e.id);

                    const changedAppointmentDays = array
                        .filter((appointmentDay) => appointmentDay.appointments.some((appointment) => appointmentsForDelete.includes(appointment.id)))
                        .map((day) => {
                            return {
                                ...day,
                                appointments: day.appointments.filter((appointment) => !appointmentsForDelete.includes(appointment.id))
                            }
                    });

                    const filteredAppointmentDays = array.filter((appointmentDay) => 
                        !changedAppointmentDays.some((changedAppointmentDay) => changedAppointmentDay.day === appointmentDay.day));

                    const daysToChange = changedAppointmentDays.map((b) => b).length;
                    const arrayLength = array.length;
                    const lastOfArray = changedAppointmentDays.every((o) => Object.keys(o.appointments).length === 0);
                    const lastOfAppointments = changedAppointmentDays.map((a) => a.appointments)[0].length < 1;

                    const updatedArray = filteredAppointmentDays.concat(changedAppointmentDays);
                    const sortArray = updatedArray.slice(0).sort((a,b)=> a.day.localeCompare(b.day));

                    if (daysToChange === 1) {
                        if (daysToChange === arrayLength) {
                            if (lastOfAppointments) {
                                setState(filteredAppointmentDays);
                                filterState("showAllAvailableDays");
                            } else {
                                setState(updatedArray);
                            }
                        } else {
                            if (lastOfAppointments) {
                                setState(filteredAppointmentDays);
                                filterState(filteredAppointmentDays[0].day);
                            } else {
                                setState(sortArray);
                            }
                        }
                    } else {
                        if (daysToChange === arrayLength) {
                            if (lastOfArray) {
                                setState(filteredAppointmentDays);
                            } else {
                                setState(sortArray);
                            }
                        } else {
                            if (lastOfArray) {
                                setState(filteredAppointmentDays);
                            } else {
                                setState(sortArray);
                            }
                        }
                        filterState("showAllAvailableDays");
                    }
                    
                    if (tapErrorsStatus.length > 0) {
                        feedbackState("Kunne ikke slette alle valgte timer, noen timer var enten tatt av kunder eller utgått.");
                        tapErrorState((tapStatus) => {
                            return [...tapStatus, ...tapErrorsStatus];
                        });
                    } else {
                        feedbackState("De valgte timene ble slettet.");
                    }

                    checkedState([]);
                })
            } else {
                saveState("");
                feedbackState("");
                return response.json().then((data) => {
                    if (data.message === "APPOINTMENT_DOES_NOT_EXIST") {
                        errorState("Det oppsto en feil ved sletting av time; timen eksisterer ikke på serveren.");
                    } else {
                        errorState("Det har oppstått en feil ved sletting av timer.");
                    }
                });
            }
        }).catch((error) => {
            errorState("Det har oppstått en feil ved sletting av timer.");
        });
    } else {    
        return;
    };
}

export const cancelAppointmentHandler = async (event, confirm, configuration, appointment, array, setError, setArray, token, username, arrangedState) => {
    event.preventDefault();
    let thresholdReached = false; 
    
    thresholdReached = calculateThreshold(appointment.scheduledAt, thresholdReached, configuration.threshold);

    if (thresholdReached) {
        cancelAppointment("Er du sikker på at du vil kansellere denne timen? Du kansellerer nå for nært timens klokkeslett, flere kanselleringer så nært timens tid vil kunne medføre bøter ved hyppig utnyttelse av systemet.", 
            appointment, confirm, array, configuration.unpublishOnCancel, setError, setArray, token, username, arrangedState);
    } else {
        cancelAppointment("Er du sikker på at du vil kansellere denne timen?", 
            appointment, confirm, array, configuration.unpublishOnCancel, setError, setArray, token, username, arrangedState);
    };
}

export const cancelAppointment = async (message, appointment, confirm, array, unpublishOnCancel, errorState, setState, token, username, arrangedState) => {
    let requestUrl;
    const isConfirmed = await confirm(message);
    
    if (username) {
        requestUrl = `appointments/${appointment.id}/cancel?optionalUsername=${username}`;
    }  else {
        requestUrl = `appointments/${appointment.id}/cancel`;
    } 

    if (isConfirmed) {
        return fetch(apiUrl + requestUrl, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" }
        }).then((response)=> {
            if (response.ok) {
                let updatedState;

                if (username && arrangedState) {
                    const getDay = appointment.scheduledAt.split("T")[0];
                    updatedState = array.map((date) => {
                        if (date.day !== getDay) {
                            return date;
                        } else {
                            return {
                                ...date,
                                appointments: date.appointments.map((app) => {
                                    if (app.id !== appointment.id) return app;
                                    
                                    return {
                                        id: appointment.id,
                                        scheduledAt: appointment.scheduledAt,
                                        published: unpublishOnCancel ? false : true,
                                        warehouseRental: false
                                    };
                                }),
                            };
                        }
                    });
                    setState(updatedState);
                } else {
                    updatedState = array.filter((order) => order.id !== appointment.id);
                    setState(updatedState);
                }
            } else {
                return response.json().then((data) => {
                    if (data.message === "APPOINTMENT_DOES_NOT_EXIST") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det oppsto en feil ved kansellering av time; timen eksisterer ikke på serveren."}]})
                    } else if (data.message === "APPOINTMENT_NOT_RESERVED") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det oppsto en feil ved kansellering av time; timen er ikke reservert."}]})
                    } else if (data.message === "APPOINTMENT_ALREADY_RESERVED_BY_ANOTHER") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det oppsto en feil ved kansellering av time; timen står som reservert på en annen bruker."}]})
                    } else if (data.message === "APPOINTMENT_HAS_PASSED") {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det oppsto en feil ved kansellering av time; timen er utgått."}]})
                    } else {
                        errorState((items) => {return [...items, {id: appointment.id, message: "Det har oppstått en feil ved kansellering av time. Prøv på nytt senere eller kontakt oss."}]})
                    }
                });
            }
        }).catch((error) => {
            errorState((items) => {return [...items, {id: appointment.id, message: "Det har oppstått en feil ved kansellering av time. Prøv på nytt senere eller kontakt oss."}]})
        });
    } else {    
        return;
    }
}

export const tapMultiple = async (checkedApps, username, tappedErrorState, token, tapErrorState, isCheckedArray, setCheckState, tapDisabledState, setExpirationState, setOverlayState, overlayArray, day) => {
    const clearOverlay = () => {
        setOverlayState((overlays) => {
            const updateOverlay = overlays.map((obj) => {
                if (obj.day === day) {
                    return {...obj, display: false};
                }
                return obj;
            });
    
            return updateOverlay;
        });
    }

    if (checkedApps) {
        tapDisabledState((checkedItems) => {
            return [...checkedItems, checkedApps]
        });

        if (setOverlayState) {
            const overlayExists = overlayArray.find((o) => o.day === day);

            if (overlayExists) {
                setOverlayState(((overlays) => {
                    const updateOverlay = overlays.map((overlay) => {
                        if (overlay.day === day) {
                            return {...overlay, display: true};
                        } 
                        return overlay
                    });
                    return updateOverlay;
                }));
            } else {
                setOverlayState(((overlays) => {
                    return [...overlays, { day: day, display: true}];
                }));
            }
        }

        await fetch(apiUrl + `appointments/tap-multiple?appointmentIds=${checkedApps}&username=${username}`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"}
        }).then((response) => {
            if (response.ok) {
                tappedErrorState("");

                return response.json().then((data) => {
                    const response = getResponseErrors(data);
                    const hasResponses = Object.keys(response).length > 0 ? true : false;

                    tapDisabledState([]);
                    
                    if (setOverlayState) clearOverlay();

                    if (hasResponses && response.failedAppointments.length > 0) {
                        const untappableAppointments = response.tapErrors.filter((a) => untapTerms.includes(a.status));
                        const untapAppointments = untappableAppointments.map((a) => a.id);

                        if (untappableAppointments.length > 0) {
                            untapOnError(untapAppointments, token); 
                        }

                        setStateError(tapErrorState, response.failedAppointments); 
                    } else if (hasResponses && response.expiredAppointments.length > 0) { 
                        const expiredAppointmentsIds = response.expiredAppointments.map((e) => e.id);
                        setStateError(setExpirationState, expiredAppointmentsIds); 
                    } 

                    if (hasResponses && response.tapErrors.length > 0) {
                        const updateCheckArray = isCheckedArray.filter((check) => !response.tapErrorsIds.find((checked) => checked === check)); 
                        setCheckState(updateCheckArray);
                    }   
                });
            }         
        }).catch((error) => {
            if (setOverlayState) {
                clearOverlay();
            }

            return tappedErrorState("Det oppsto en feil ved valg av timer, kunne ikke sette de som reservert tilhørende valgt kunde."); 
        });
    } else return;
};

export const untapOnError = (ids, token) => {
    fetch(apiUrl + `appointments/untap-multiple?appointmentIds=${ids}`, {
        method: "POST",
        headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"}
    }).then((response) => {
        if (response.ok) {
            return response.text();
        }              
    }).catch((error) => {
        console.log(error)
    });
}

export const untapMultiple = (tappedIds, errorState, token, tapErrors) => {
    if (tappedIds) {
        const tapErrorIds = tapErrors?.map((t) => t.id);
        const untapIds = tappedIds.length > 1 ? tappedIds.filter((newAppointment) => !tapErrorIds.find((checked) => checked === newAppointment)) : tappedIds;

        fetch(apiUrl + `appointments/untap-multiple?appointmentIds=${untapIds}`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${token}`, "Content-Type": "application/json"}
        }).then((response) => {
            if (response.ok) {
                errorState("");
                return response.text();
            } else {
                return response.json().then((data) => {
                    errorState("Det oppsto en feil ved fjerning av valgte timer, kunne ikke fjerne de som reservert tilhørende valgt kunde.");
                });
            }                
        }).catch((error) => {
            errorState("Det oppsto en feil ved fjerning av valgte timer, kunne ikke fjerne de som reservert tilhørende valgt kunde.");
        });
    } else return;
};

export const handleCheckboxTap = (checkedApps, username, actionState, tappedErrorState, token, tapErrorState, isCheckedArray, setCheckState, tapDisabledState, setExpirationState, setOverlayState, overlayArray, day) => {
    if (checkedApps && actionState === "tap") {
        tapMultiple(checkedApps, username, tappedErrorState, token, tapErrorState, isCheckedArray, setCheckState, tapDisabledState, setExpirationState, setOverlayState ? setOverlayState : "", overlayArray, day);
    } else if (checkedApps && actionState === "untap") {
        untapMultiple(checkedApps, tappedErrorState, token);
    } else return;
}

export const removeTapError = (app, tapErrorState) => {
    tapErrorState((idGroup) => {
        return idGroup.filter((a) => a.id !== app);
    });
}

export const removeExpiredError = (app, expiredErrorState) => {
    expiredErrorState((idGroup) => {
        return idGroup.filter((a) => a !== app);
    });
}

export const removeAppointmentFromDayState = (appointmentId, dataArray) => {
    const changedAppointmentDays = dataArray
        .filter((appointmentDay) => appointmentDay.appointments.some((app) => app.id === appointmentId))
        .map((day) => {
            return {
                ...day,
                appointments: day.appointments.filter((app) => app.id !== appointmentId)
            }
    });

    const sortedArray = filterAppointmentDays(dataArray, changedAppointmentDays);

    return sortedArray;
}