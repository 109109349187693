import { Fragment, useState, useEffect, lazy, Suspense } from "react";
import { hideHandler } from "../../layout/MainLayout";
import { getUserToken } from "../../../data/token";
import useFetch from "../../../hooks/useFetch";
import { getGrainData } from "../../booking/BookingService";
import { parseJwt } from "../../../context/auth-context";
import { groupAllAppointments } from "../../../assets/GroupAppointments";
import LeafLoader from "../../../assets/LoadingSpinner";
import { PrintButtonUsers } from "../../UI/PrintButton";
import { ActionModal } from "../../UI/Modal";
import ExportUserAppointments from "../../../assets/ExportUserAppointments";
import { ErrorFeedback } from "../../../assets/Feedback";
import { formatDate, formatTime } from "../../../assets/DateFormatOptions";
import { findGrainTypeName, findVarietyName, isSprayed } from "./UserService";

const RestrictedAdminAccess = lazy(() => import("../../UI/RestrictedAdminAccess"));

const UserOrderArchive = ({access}) => {
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [fetchError, setFetchError] = useState("");
    const [showExportModal, setShowExportModal] = useState(false);

    const tokenData = getUserToken();
    const userName = parseJwt(tokenData).name;

    const { data: appointments, loading, error } = useFetch("appointments/archived", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`},
    });

    let filteredAppointments = [];
    let sortDaysByAscDate = [];
    let sortAppointmentsByAsc = [];
    let renderState = false;

    useEffect(() => {
        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setFetchError("Det har oppstått en feil ved henting av korntyper. Last inn siden på nytt, ta kontakt om problemet vedvarer.")});
    }, [tokenData]);

    if (appointments && appointments.length >= 1) {
        sortAppointmentsByAsc = appointments.slice(0).sort((a,b)=> a.scheduledAt.localeCompare(b.scheduledAt));
        filteredAppointments = groupAllAppointments(sortAppointmentsByAsc);
        sortDaysByAscDate = filteredAppointments.sort((a,b)=> new Date(a.day) < new Date(b.day) ? 1 : -1);
    }

    if (appointments && grainVarieties?.length > 0) renderState = true;

    return (
        <div className="main__section">
            <div className="main__section__body content">
                {access === "adminUser" ? 
                    <div className="content__container">
                        <div className="content__header"><h1>Mitt arkiv</h1></div>
                        <Suspense fallback={<LeafLoader loadingMessage="Laster ..."/>}><RestrictedAdminAccess message="har ikke tilgang til denne siden" status="checkin"/></Suspense>
                    </div>
                    : <>
                    {(loading && !renderState) && <LeafLoader loadingMessage="Laster inn arkiv ..."/>}
                    <div className="content__container">
                        <div className="content__header"><h1>Mitt arkiv</h1></div>

                        {error && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={"Kunne ikke hente data for tidligere timer, vennligst last inn siden på nytt. Kontakt oss om problemet vedvarer."}/>}  
                        {fetchError && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={fetchError}/>}     
                        
                        {renderState && <> 
                            {filteredAppointments.length >= 1 ?
                                <Fragment>                               
                                    <div className="content__body--subheader">
                                        <p>Her er en oversikt over alle dine tidligere, forbigåtte, timer. Timene listes i kronologisk rekkefølge - fra nyest til eldst.</p>
                                        <PrintButtonUsers title="Skriv ut timelister" onClick={() => setShowExportModal(true)} className="topped"/>
                                    </div>

                                    <div className="content__body"> 
                                        <div className="booking">
                                            <div className="booking__section">
                                                {sortDaysByAscDate.map((date, index) => ( 
                                                    <div key={index} className="booking__section--table">
                                                        <h2>{formatDate(date.day)}</h2><span><span>{date.appointments.length}</span> {date.appointments.length > 1 ? "timer" : "time"}</span>

                                                        <table style={{width: "100%"}} className="table">
                                                            <thead>
                                                                <tr className="table__head">
                                                                    <th className="table__head--main" style={{width:"20%"}}>Tid</th>
                                                                    <th style={{width:"30%"}}>Kornsort</th>
                                                                    <th style={{width:"30%"}}>Kornart</th>
                                                                    <th style={{width:"20%"}}>Tonn</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {date.appointments.map((appointment) => {
                                                                    return (
                                                                        <tr className="table__row" key={appointment.id}>
                                                                            <td className="table__row--main">{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</td>
                                                                            <td>{findGrainTypeName(grainVarieties, appointment.grainTypeId)} {isSprayed(grainVarieties, appointment, "serverside")}</td>
                                                                            <td>{appointment.varietyTypeId ? findVarietyName(grainVarieties, appointment.grainTypeId, appointment.varietyTypeId) : "-"}</td>
                                                                            <td>{appointment.amount}</td>
                                                                        </tr>
                                                                    );
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {showExportModal &&
                                        <ActionModal onHide={() => hideHandler(setShowExportModal)}>                    
                                            <ExportUserAppointments onHide={() => hideHandler(setShowExportModal)} data={sortAppointmentsByAsc} user={userName} grains={grainVarieties}/>
                                        </ActionModal>
                                    }
                                </Fragment>
                                : <p className="empty-result empty-result--lg empty-result--positive">Du har ingen arkiverte (utgåtte) timer.</p>
                            }
                        </>}
                    </div>
                    </>
                }
            </div>
        </div>
    );
};

export default UserOrderArchive;