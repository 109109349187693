import { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import { apiUrl } from "../../../data/API";
import { getUserToken } from "../../../data/token";
import { formatShortDate, formatDay } from "../../../assets/DateFormatOptions";
import { Feedback } from "../../../assets/Feedback";
import LeafLoader from "../../../assets/LoadingSpinner";
import { onBlurHidePicker, onFocusShowPicker } from "../../UI/Input";
import DaySummary from "./chart/DaySummary";
import WeekSummary from "./chart/WeekSummary";

const startDate = new Date(new Date().getFullYear(), 0, 1);
const days = Math.floor((new Date() - startDate) / (24 * 60 * 60 * 1000));  
const year = new Date().getFullYear();
export const exportWeek = +Math.ceil(days / 7);
export const startWeek = year + "-W" +Math.ceil(days / 7);

const AdminPage = () => {
    const [summaryData, setSummaryData] = useState({});
    const [weeklyData, setWeeklyData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [weekNumber, setWeekNumber] = useState(exportWeek);
    const [sortDate, setSortDate] = useState(new Date().toISOString().split("T")[0]);

    const tokenData = getUserToken();
    const todaysDate = new Date().toISOString().split("T")[0];

    let renderState = false;
    let sorted;
    let summaryDataByDate;
    let reservedToday;
    let cancelledToday = 0;

    const { data: grains, grainLoading, grainError} = useFetch("grains", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`, }
    });

    const fetchAndSet = (time) => {
        setIsLoading(true);                            
        setError("");
        fetch(apiUrl + `appointments/weekly-summary?timeZone=Europe/Oslo&date=${time}`, {
            method: "GET",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json",},
        }).then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    if (data) {
                        setWeeklyData(data.appointments);
                        const week = data.weekNumber;
                        const year = new Date().getFullYear();
                        const day = (1 + (week - 1) * 7); 
                        let dayOffset = new Date(year, 0, 1).getDay(); 
                        dayOffset--; 
                        
                        let weekOfDates = [];
                        const firstDateOfWeek = new Date(year, 0, day - dayOffset);
                
                        for (let i = 1; i <= 7; i++) {
                            let firstDate = firstDateOfWeek.getDate() - firstDateOfWeek.getDay() + i; 
                            let dateObj = {scheduledAt : new Date(firstDateOfWeek.setDate(firstDate)).toLocaleDateString("sv") + "T00:00:00Z", empty : true}; 
                            weekOfDates.push(dateObj)
                        }
                
                        let updatedArray = data.appointments.slice();
                        const existingDates = updatedArray.map((date) => date.scheduledAt.split("T")[0]);

                        weekOfDates.forEach((date) => {
                            if (!existingDates.includes(date.scheduledAt.split("T")[0])) {
                                updatedArray.push(date);
                            } 
                            return
                        });

                        if (updatedArray.length > 0) {
                            data.appointments = updatedArray;
                            setSummaryData(data);
                        }
                        setIsLoading(false);
                    } else {
                        setError("Kunne ikke finne noe data, vennligst last inn siden på nytt.");
                        setIsLoading(false);
                    }
                })
            } else {
                return response.json().then((data) => {
                    setError("Kunne ikke hente data for denne uken, vennligst last inn siden på nytt og forsøk igjen.");
                    setIsLoading(false);
                });
            }
        }).catch((error) => {
            setError("Kunne ikke hente data for denne uken, vennligst last inn siden på nytt og forsøk igjen.");
            setIsLoading(false);
        });
    }

    useEffect(() => {
        fetchAndSet(new Date().getTime());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDataByWeekHandler  = (event) => {
        event.preventDefault();
        const value = event.target.value;

        if (value) {
            const year = parseInt(value.slice(0,4), 10);
            const week = parseInt(value.slice(6), 10);
            const day = (1 + (week - 1) * 7); 
            let dayOffset = new Date(year, 0, 1).getDay(); 
            dayOffset--; 
    
            fetchAndSet(new Date(year, 0, day - dayOffset).getTime());
            setWeekNumber(week);          
        } else {
            fetchAndSet(new Date().getTime());
            setWeekNumber(exportWeek);
        }
    }

    if (summaryData && !isLoading) {
        if (summaryData?.appointments?.length > 0) {
            const summary = summaryData.appointments;

            const groupDays = summary.reduce((groupDays, date) => {
                const formatDate = date.scheduledAt.split("T")[0];
                (groupDays[formatDate] = groupDays[formatDate] || []).push(date);
        
                return groupDays;
            }, {});
        
            const groupAppointmentsByDay = Object.keys(groupDays).map((day) => { 
                return { day, appointments: groupDays[day]};
            }); 

            sorted = groupAppointmentsByDay.slice(0).sort((a,b)=> a.day.localeCompare(b.day));

            if (sorted.filter((d) => d.day === sortDate).length > 0) {
                renderState = true;
                summaryDataByDate = sorted.filter((d) => d.day === sortDate);
            } else {
                summaryDataByDate = [sorted[0]];
                renderState = true;
                setSortDate(summaryDataByDate[0].day)
            }

            reservedToday = summary.filter((a) => a.reservedAt) ? summary.filter((a) => a.reservedAt && a.reservedAt.split("T")[0] === sortDate).length : "0";
        }

        if (summaryData?.cancelledAppointments?.length > 0) {
            cancelledToday = summaryData.cancelledAppointments.filter((a) => a.split("T")[0] === sortDate).length;
        }
    }

    return (
        <section className="contents__container">
            <h1 className="no-mt">Ukeoversikt</h1>
            {error && <Feedback class="" feedbackClass="feedback--error" message={error}/>}
            {grainError && <Feedback class="" feedbackClass="feedback--error" message={"Det oppsto et problem ved henting av korntyper, vennligst last inn siden på nytt."}/>}
            {(!error && !grainError && !grainLoading && grains?.length > 0) && 
                <div className="dashboard">
                    <div className="dashboard__week">
                        <div className="dashboard__header dashboard__header--lg">
                            <h2>Uke {weekNumber} ({new Date().toLocaleDateString("no", { month: 'long'})} {new Date().getFullYear()})</h2>
                            <div className="dashboard__header__dropdown">
                                <label htmlFor="week">Velg uke </label> 
                                <input id="week" type="week" defaultValue={startWeek} max={startWeek} name="week" onChange={(event) => {updateDataByWeekHandler(event); onBlurHidePicker(event)}} onFocus={(e) => onFocusShowPicker(e)}/>
                            </div>
                        </div>
                        {renderState && summaryData ? 
                            <div className="dashboard__week--wrapper"> 
                                {summaryData?.appointments?.length > 0 && summaryDataByDate?.length > 0 ?
                                    <>            
                                        <section className="dashboard__week--summary">
                                            <WeekSummary data={weeklyData} grains={grains} cancellations={summaryData.cancelledAppointments}/>
                                        </section>

                                        <section className="dashboard__week__day">
                                            <div className="dashboard__header">
                                                <h3>Dagsoversikt</h3>
                                            </div>
                                            <div className="dashboard__week__day--wrapper">
                                                <p>Viser data for individuelle dager i uken. Velg dag ved å trykke på den for å se dagens data.</p>
                                                <div className="dashboard__week__day--calendar">
                                                    <div className="calendar">
                                                        <ul>
                                                            {sorted.map((days) => ( 
                                                                <li className={`${days.day === sortDate && "pickedDate"} ${days.day === todaysDate && "todaysDate"} ${days.appointments.find((a) => a.empty) && "emptyDate"}`} 
                                                                    onClick={() => setSortDate(days.day)} key={days.day} title={days.appointments.find((a) => a.empty) ? "Ingen data" : "Klikk for å se data"}>
                                                                    <span>{formatShortDate(days.day) + " "}</span>
                                                                    <span>{days.day === todaysDate ? "I DAG" : formatDay(days.day, {weekday: 'long'}) + " "}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>
                                                    <DaySummary data={summaryDataByDate} date={sortDate} reserved={reservedToday} cancelled={cancelledToday} />
                                            </div>
                                        </section>          
                                    </>
                                    : <Feedback class="" feedbackClass="feedback--error" message={"Det finnes ingen data for denne uken."}/>
                                }
                            </div> 
                        : <LeafLoader loadingMessage="Laster inn oversiktsvisning ..."/> }
                    </div>
                </div>
            }
        </section>
    );
};

export default AdminPage;