import React, { useState, useEffect } from "react";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import { apiUrl } from "../../../../../data/API";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import useConfirm from "../../../../../hooks/useConfirm";
import { hideHandler } from "../../../../layout/MainLayout";
import { ActionModal } from "../../../../UI/Modal";
import { ErrorFeedback, Feedback, TimeoutFeedback } from "../../../../../assets/Feedback";
import Pagination from "../../../../UI/Pagination";
import useInput, { inputNotEmpty } from "../../../../../hooks/useInput";
import { checkForUniqueName, addWhiteSpace } from "../templates/CreateTimespanTemplates";
import { capitalizeStr } from "../../../../../assets/Capitalize";
import CustomerEditCategory from "./CustomerEditCategory";
import Button from "../../../../UI/Button";

const CustomerCategories = () => {
    const [userCategories, setUserCategories] = useState();
    const [onCreate, setOnCreate] = useState("");
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [actionError, setActionError] = useState("");
    const [validName, setValidName] = useState(true);
    const [isSuccess, setIsSuccess] = useState("");
    const [formError, setFormError] = useState(false);
    const [deleteError, setDeleteError] = useState("");
    const [modalId, setModalId] = useState(null); 
    const [showUpdateTemplate, setShowUpdateTemplate] = useState(false);

    const tokenData = getUserToken();
    const itemsPerPage = 6;

    const {confirm} = useConfirm();

    const { data: categories, loading, error} = useFetch("customer-categories", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const { value: categoryNameValue, isValid: categoryNameIsValid, hasError: categoryNameHasError, valueChangeHandler: categoryNameChangeHandler, inputBlurHandler: categoryNameBlurHandler, reset: resetCategoryName } = useInput(inputNotEmpty);
    const { value: descriptionValue, isValid: descriptionIsValid, hasError: descriptionHasError, valueChangeHandler: descriptionChangeHandler, inputBlurHandler: descriptionBlurHandler, reset: resetDescription } = useInput(inputNotEmpty);
    const { value: maxDailyReservationsOverrideValue, isValid: maxDailyReservationsOverrideIsValid, hasError: maxDailyReservationsOverrideHasError, valueChangeHandler: maxDailyReservationsOverrideChangeHandler, inputBlurHandler: maxDailyReservationsOverrideBlurHandler, reset: resetMaxDailyReservationsOverride } = useInput(inputNotEmpty);

    let paginationData;
    let numberOfPages;
    let readyAfterLoad = false;
    let invalidValue = false; 

    useEffect(() => {
        setUserCategories(categories);
    }, [categories]);
   
    if (!loading && userCategories && userCategories.length > 0) {
        const sortedData = userCategories.sort((a,b) => b.id - a.id);
        const indexOfLastRecord = currentPaginationPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        paginationData = sortedData.slice(indexOfFirstRecord, indexOfLastRecord);
        numberOfPages = Math.ceil(userCategories.length / itemsPerPage)
        readyAfterLoad = true;
    }

    if (maxDailyReservationsOverrideIsValid && maxDailyReservationsOverrideValue <= 0) {
        invalidValue = true;
    }

    const submitForm = (event) => {
        event.preventDefault(); 

        if (categoryNameIsValid && descriptionIsValid && maxDailyReservationsOverrideIsValid && validName && !invalidValue) {     
            saveCategory();
            setFormError("");
        } else {
            setFormError("Skjemaet har feiler eller felter som ikke er fylt ut.");
        }
    }

    const handleCategoryStateUpdate = (updatedCategory) => {
        setUserCategories(prev => {
            const updateCategory = prev.map((c) => {
                if (c.id === updatedCategory.id) {
                    return {...c, name : updatedCategory.name, description : updatedCategory.description,  maxDailyReservationsOverride : updatedCategory.maxDailyReservationsOverride};
                } return c
            });

            return updateCategory;
        });
    }

    const saveCategory = () => {
        setOnCreate("Oppretter kategori ...");
        setActionError("");
        fetch(apiUrl + `customer-categories?color=${"ffffff"}&description=${descriptionValue}&maxDailyReservationsOverride=${maxDailyReservationsOverrideValue}&name=${categoryNameValue}`, {
            method: "POST",
            headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json"},
        }).then((response)=> {
            setOnCreate("");
            if (response.ok) {
                setActionError("");
                setOnCreate("");
                setIsSuccess("Kategori ble opprettet!");
                resetDescription();
                resetCategoryName();
                resetMaxDailyReservationsOverride();
                setValidName(true);
                return response.json().then((data) => {
                    const checkIfExists = userCategories.find((c) => c.id === data.id);   
                                 
                    if (checkIfExists) { 
                        return 
                    } else { 
                        const updatedState = [...userCategories, { id: data.id, name : categoryNameValue, description: descriptionValue, maxDailyReservationsOverride: maxDailyReservationsOverrideValue}]
                        const sortNewState = updatedState.sort((a,b) => b.id - a.id);
                        setUserCategories(sortNewState)
                    }       
                })
            } else {
                setIsSuccess("");
                return response.json().then(() => {
                    setActionError("Det har oppstått en feil ved oppretting av kategori, se over skjemaet og prøv igjen.");
                });
            }
        }).catch(() => {
            setOnCreate("");
            setActionError("Det har oppstått en feil ved oppretting av kategori, se over skjemaet og prøv igjen.");
        });
    };

    const deleteCategory = async (event, id) => {
        event.preventDefault();
        const isConfirmed = await confirm("Er du sikker på at du vil slette denne kategorien?");

        if (isConfirmed) {
            return fetch(apiUrl + "customer-categories/" + id, {
                method: "DELETE",
                headers: { "Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json", }
            }).then((response)=> {
                if (response.ok) {
                    setDeleteError("");
                    const updateCategoryData = userCategories.filter((c) => c.id !== id);
                    setUserCategories(updateCategoryData);
                } else {
                    return response.json().then((data) => {
                        if (data.message === "CUSTOMER_CATEGORY_IN_USE") {
                            setDeleteError("Kategorien kan ikke slettes, det finnes kunder som er knyttet til den.");
                        } else if (data.message === "CUSTOMER_CATEGORY_DOES_NOT_EXIST") {
                            setDeleteError("Kategorien kan ikke slettes, den eksisterer ikke på serversiden.");
                        } else {
                            setDeleteError("Det oppsto en feil ved sletting, last inn siden på nytt og prøv igjen.");
                        }
                    })
                }
            }).catch((error) => {
                setDeleteError("Det har oppstått en feil ved sletting av denne kategorier. Last inn siden på nytt og prøv igjen.");
            });
        } 
    };

    const clearSuccessState = () => {
        setIsSuccess("");
        setFormError("");
    }

    return (
        <section className="contents__container">
            <h1 className="no-mt">Kategorier</h1>
           
            {error && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for kategorier, vennligst last inn siden på nytt."}/>} 
            {!error && <p>Her kan du både lage nye kundekategorier, se over de eksisterende kategoriene og eventuelt slette de.</p>}

            {!error && 
                <section className="equal templates">            
                    <section className="equal__part">
                        <h2>Opprett ny kategori</h2>
                        <div className="templates__form"> 
                            <p>Gi kategorien et navn, en kort beskrivelse og sett grense for maks antall timer en bruker kan bestille per dag. </p>
                            <form>
                                <div>
                                    <label htmlFor="name" className="label__long">Navn <span className="required" title="Påkrevd felt">*</span></label>                           
                                    <input type="text" id="name" value={categoryNameValue} maxLength="26" onChange={(e) => categoryNameChangeHandler(e)} autoComplete="off"
                                        className={`label__long ${(categoryNameHasError ? "input--error" : null)}`} onBlur={(e) => {categoryNameBlurHandler(); checkForUniqueName(e, userCategories, categoryNameValue, setValidName)}}
                                    />
                                    {(categoryNameIsValid && categoryNameValue.length === 26) && (<p className="error-text label__long">Velg et beskrivende navn (maks 26 karakterer).</p>)}
                                    {!validName && (<p className="error-text label__long">Navnet du har valgt eksisterer allerede.</p>)}
                                </div>
                                <div>
                                    <label htmlFor="description" className="label__long">Beskrivelse <span className="required" title="Påkrevd felt">*</span></label>
                                    <input type="text" id="description" maxLength="56" value={descriptionValue} autoComplete="off"
                                        onChange={(e) => descriptionChangeHandler(e)} onBlur={descriptionBlurHandler}
                                        className={`label__long ${(descriptionHasError ? "input--error" : null)}`}
                                    />
                                    {(descriptionIsValid && descriptionValue.length === 56) && ( <p className="error-text label__long">Skriv en kort beskrivelse (max 56 karakterer).</p>)}
                                </div>
                                <div>
                                    <label htmlFor="maxDailyReservationsOverride" className="label__long">Bestillinger per dag <span className="required" title="Påkrevd felt">*</span></label>
                                    <input type="number" id="maxDailyReservationsOverride" value={maxDailyReservationsOverrideValue} autoComplete="off"
                                        onChange={(e) => maxDailyReservationsOverrideChangeHandler(e)} onBlur={maxDailyReservationsOverrideBlurHandler}
                                        className={`label__long ${(maxDailyReservationsOverrideHasError ? "input--error" : null)}`}
                                    />
                                    {invalidValue && <p className="error-text label__long">Uglydig verdi oppgitt.</p>}   
                                </div>

                                <Button type="submit" onClick={(e) => submitForm(e)} className="confirm topped" title="Lagre kategori">Lagre kategori</Button>
                            </form>

                            {formError && <TimeoutFeedback class="topped" feedbackClass="feedback--error" clearMessage={clearSuccessState} message={formError}/>}
                            {onCreate && <Feedback class="topped" feedbackClass="feedback--warning" message={onCreate}/>}
                            {isSuccess && <TimeoutFeedback class="topped" feedbackClass="feedback--success" clearMessage={clearSuccessState} message={isSuccess}/>}
                            {actionError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={actionError}/>}
                        </div>
                    </section>
                    <section className="equal__part">
                        <h2>Eksisterende kategorier ({userCategories?.length})</h2>
                        
                        {!loading && userCategories ?
                            <>
                                <div className="templates__table">
                                    {(!error && userCategories?.length > 0 ) && 
                                        <table style={{width: "100%"}} className="templates__list table">
                                            <thead>
                                                <tr className="table__head">
                                                    <th style={{width:"28%"}}>Navn</th>
                                                    <th style={{width:"28%"}}>Beskrivelse</th>
                                                    <th style={{width:"25%"}}>Bestillinger per dag</th>
                                                    <th style={{width:"15%"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {readyAfterLoad && paginationData.map((timespan) => (
                                                    <tr className="table__row" key={timespan.id}>
                                                        <td>{addWhiteSpace(timespan.name)}</td>
                                                        <td>{capitalizeStr(timespan.description)}</td>
                                                        <td>{timespan.maxDailyReservationsOverride}</td>
                                                        <td>
                                                            <Button className="text__btn button__mr" onClick={(e) => {setShowUpdateTemplate(true); setModalId(timespan.id)}} title="Endre kategori">Endre </Button>
                                                            <Button className="text__btn" onClick={(e) => deleteCategory(e, timespan.id)} title="Slett kategori"> Slett</Button>
                                                        </td>
                                                        {(showUpdateTemplate && modalId === timespan.id) &&
                                                            <ActionModal onHide={() => hideHandler(setShowUpdateTemplate)}>
                                                                <CustomerEditCategory onHide={() => hideHandler(setShowUpdateTemplate)} category={timespan} userCategories={userCategories} updateChangesToState={handleCategoryStateUpdate} />
                                                            </ActionModal>
                                                        }
                                                    </tr>
                                                ))} 
                                            </tbody>
                                        </table>
                                    }
                                    {(!error && userCategories && userCategories.length === 0) && <p className="templates__list--feedback">Ingen kategorier er opprettet enda.</p>}
                                </div> 
                                {itemsPerPage >= userCategories.length ? "" :
                                    <Pagination numberOfPages={numberOfPages} currentPaginationPage={currentPaginationPage} setCurrentPaginationPage={setCurrentPaginationPage}/>
                                }
                                {deleteError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={deleteError}/>}
                                {error && <Feedback class="feedback--center templates__list--feedback" feedbackClass="feedback--error" message={"Det oppsto en feil ved henting av kategorier. Forsøk å last inn siden på nytt."}/>} 
                            </> 
                            : 
                            <div className="loading__container topped">
                                <LeafLoader loadingMessage="Laster inn kategorier ..."/>
                            </div>
                        }
                    </section>
                </section>
            }
        </section>
    );
};

export default CustomerCategories;
