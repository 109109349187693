import { useContext } from "react";
import AuthContext from "../../context/auth-context";
import { Link, NavLink, useLocation  } from "react-router-dom";
import returnIcon from "../../assets/icons/keyboard_return_FILL1_wght400_GRAD0_opsz40.svg";
import userIcon from "../../assets/icons/person_FILL1_wght400_GRAD0_opsz40.svg"
import logo from "../../assets/icons/braskereidfosslogo.png"

const AdminNavigation = () => {
    const userCtx = useContext(AuthContext);
    const location = useLocation();

    const logoutHandler = () => {
        userCtx.logout();
    };

    let activeAppointments = false;
    let activeSettings = false;

    if (location.pathname === "/administrator/timeovervoker" || location.pathname === "/administrator/opprett-timer" || location.pathname ===  "/administrator/bestilte-timer" 
        || location.pathname === "/administrator/kanselleringer" || location.pathname === "/administrator/historiske-timer" || location.pathname === "/administrator/fraværstimer") {
        activeAppointments = true;
    } else if (location.pathname === "/administrator/ukeoversikt" || location.pathname ===  "/administrator/maler" || location.pathname ===  "/administrator/kategorier" || location.pathname ===  "/administrator/innstillinger") {
        activeSettings = true;
    }

    return (
        <nav className="adminNav">
            <ul className="sidenav">
                <div className="sidenav__logo">
                    <Link to={"/administrator/timeovervoker"}><img src={logo} alt="Braskereidfoss Kornsilo logo"/></Link>
                </div>
                <li className="sidenav__subnav">
                    <Link to={"/administrator/timeovervoker"} className={`sidenav__subnav__item ${activeAppointments ? "active" : undefined}`}>Timer</Link>
                    <div className="sidenav__subnav__list">
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/timeovervoker"} title="Opprett ledige timer">Timeovervåker</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/opprett-timer"} title="Opprett ledige timer">Opprett timer</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/historiske-timer"} title="En historikk av timer">Historiske timer</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/bestilte-timer"} title="Liste av bestilte timer">Bestilte timer</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/fraværstimer"} title="Liste av fraværstimer">Fraværstimer</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/kanselleringer"} title="Liste av kansellerte timer">Kansellerte timer</NavLink>
                    </div>
                </li>

                <li className="sidenav__item">
                    <NavLink className="sidenav__subnav__item" to={"/administrator/kundeliste"} title="Kundeliste">Kunder</NavLink>
                </li>

                <li className="sidenav__item">
                    <NavLink className="sidenav__subnav__item" to={"/administrator/korn"} title="Kornliste med mulighet for å oppdatere">Korn</NavLink>
                </li>

                <li className="sidenav__subnav">
                    <Link to="#" className={`sidenav__subnav__item ${activeSettings ? "active" : undefined}`}>Annet</Link>
                    <div className="sidenav__subnav__list">
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/ukeoversikt"} title="Se time og leveringsoversikt">Ukeoversikt</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/maler"} title="Administrer time og dagsmaler">Maler</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/kategorier"} title="Administrer kategorier">Kategorier</NavLink>
                        <NavLink className={({ isActive }) => isActive ? 'active--nav' : ''} to={"/administrator/innstillinger"} title="Innstillinger for grenseverdier">Innstillinger</NavLink>
                    </div>
                </li>
            </ul>

            <section className="topnav">
                <div className="topnav__start">
                    <Link to={"/administrator/timeovervoker"}><p>Administratorpanel</p></Link>

                    {/* <button className="confirm">
                        <span>icon</span>Tavlevisning
                    </button> */}
                </div>
                <div className="topnav__menu">
                    <Link to="/" title="Tilbake til brukerforsiden"><p><img src={returnIcon} alt="Return icon"/> Til brukersiden</p></Link>
                    <p onClick={logoutHandler} title="Logg ut"><img src={userIcon} className="topnav__menu--logout" alt="Logout icon"/>Logg ut</p>
                </div>
            </section>
        </nav>
    );
};

export default AdminNavigation;
