export const dateFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
}

export const formatDate = (date) => {
    return new Date(date).toLocaleDateString("no", dateFormatOptions)
}

export const formatShortDate = (date) => {
    return new Date(date).toLocaleDateString('fr-CH', {
        day: "2-digit",
        month: "2-digit",
    })
}

export const formatTime = (time, options) => {
    return new Date(time).toLocaleTimeString("no", options)
}

export const formatDay = (day, options) => {
    return new Date(day).toLocaleString("no", options)
}

export const getTimeBetween = (date, reverse) => {
    const variableTime = new Date(date).getTime();
    const todaysTime = new Date().getTime();
    const remainingTime = reverse ? todaysTime - variableTime : variableTime - todaysTime;

    return remainingTime;
}

export const formatTimeString = (timestring) => {
    const time = timestring;
    return time.slice(0, 2) + ":" + time.slice(2,4) + " - " + time.slice(4, 6) + ":" + time.slice(6, 8);
}

export const concatenateTimeFunction = (start, end) => {
    const startTime = start.replace(':','');
    const endTime = end.replace(':','');

    return (startTime + endTime);
}

export const getUniqueYears = (array, dateProperty) => {
    const years = array.map(item => new Date(item[dateProperty]).getFullYear());
    const uniqueYears = [...new Set(years)];
    return uniqueYears.sort((a, b) => b - a); 
}