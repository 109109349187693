import { capitalizeName } from "./Capitalize";

export const getUserName = (users, reservedBy) => {
    const user = users.find((u) => u.producerNr === reservedBy);

    return user ? capitalizeName(user.name) : "Ukjent kunde";
}

export const checkIfUserExists = (event, customers, setState, emptyReservedBy) => {
    event.preventDefault();
    const username = event.target.value;

    if (username.length > 0) {
        const getUsername = customers.find((n) => {return n.name === username.toUpperCase()});

        if (getUsername) {
            const findUser = customers.find((f) => parseInt(f.producerNr) === parseInt(getUsername.producerNr));

            if (findUser) {
                setState(false);
            } else {
                setState(true);
                emptyReservedBy && emptyReservedBy("");
            } 
        } else {
            setState(true);
            emptyReservedBy && emptyReservedBy("");
        }
    } else {
        setState(false);
    }
}