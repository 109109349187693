import { Fragment, useState, useEffect, lazy, Suspense } from "react";
import { hideHandler } from "../../layout/MainLayout";
import useFetch from "../../../hooks/useFetch";
import { parseJwt } from "../../../context/auth-context";
import { getUserToken } from "../../../data/token";
import { getGrainData, getConfiguration } from "../../booking/BookingService";
import { formatTime, formatDate } from "../../../assets/DateFormatOptions";
import { groupAllAppointments } from "../../../assets/GroupAppointments";
import { cancelAppointmentHandler } from "../admin/manage/appointments/dashboard/AppointmentBoardHelpers";
import { findGrainTypeName, findVarietyName, isSprayed } from "./UserService";
import { ActionModal } from "../../UI/Modal";
import EditAppointment from "../admin/manage/appointments/dashboard/AppointmentBoardEditAppointment";
import useConfirm from "../../../hooks/useConfirm";
import { PrintButtonUsers } from "../../UI/PrintButton";
import Button from "../../UI/Button";
import LeafLoader from "../../../assets/LoadingSpinner";
import ExportUserAppointments from "../../../assets/ExportUserAppointments";
import { ErrorFeedback } from "../../../assets/Feedback";
import editIcon from "../../../assets/icons/edit_note_FILL1_wght400_GRAD0_opsz40.svg";
import deleteIcon from "../../../assets/icons/delete_forever_FILL1_wght400_GRAD0_opsz40.svg";

const RestrictedAdminAccess = lazy(() => import("../../UI/RestrictedAdminAccess"));

const UserOrderList = ({access}) => {
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [orders, setOrders] = useState([]);
    const [fetchError, setFetchError] = useState("");
    const [actionError, setActionError] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [showUpdateAppointment, setShowUpdateAppointment] = useState(null);
    const [modalId, setModalId] = useState(null); 
    const [expiredAppointments, setExpiredAppointments] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);

    const tokenData = getUserToken();
    const userName = parseJwt(tokenData).name;
    
    const {confirm} = useConfirm();
    
    const { data: appointments, loading, error } = useFetch("appointments/reserved", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`},
    });

    let renderState = false;
    let sortDaysByAscDate = [];
    let sortAppointmentsByAsc = [];

    useEffect(() => { 
        setOrders(appointments);

        getConfiguration(tokenData).then(response => 
            setConfiguration({threshold: response.cancellationThreshold, unpublishOnCancel: response.cancellationCausesUnpublish})
        ).catch((error) => { 
            setFetchError("Det oppsto et problem ved innlasting av grenseverdi til kansellering. Forsøk å laste inn siden på nytt.");}
        );

        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setFetchError("Det har oppstått en feil ved henting av korntyper. Prøv på nytt senere eller send oss en e-post.")});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appointments, tokenData]);

    if (appointments?.length > 0 && orders?.length > 0) {
        const filteredAppointmentData = groupAllAppointments(orders);
        sortDaysByAscDate = filteredAppointmentData.sort((b,a)=> new Date(a.day) < new Date(b.day) ? 1 : -1);
        sortAppointmentsByAsc = appointments.slice(0).sort((a,b)=> a.scheduledAt.localeCompare(b.scheduledAt));
    }
    
    const stateHandler = (app) => {
        setOrders((prev) => {
            const newOrderState = prev.map((order) => {
                if (order.id === app.id) {
                    return {...order, amount: parseInt(app.amount), grainTypeId: parseInt(app.grainTypeId), varietyTypeId: parseInt(app.varietyTypeId), reservedBy: app.reservedBy, comment: app.comment, warehouseRental : app.warehouseRental, sprayed: app.sprayed};
                }
                return order;
            });
            return newOrderState;
        });
    }; 

    if (orders && configuration && grainVarieties.length > 0) renderState = true;

    return (
        <div className="main__section">
            <div className="main__section__body content">
                {access === "adminUser" ? 
                    <div className="content__container">
                        <div className="content__header"><h1>Mine timer</h1></div>
                        <Suspense fallback={<LeafLoader loadingMessage="Laster ..."/>}><RestrictedAdminAccess message="har ikke tilgang til denne siden" status="checkin"/></Suspense>
                    </div>
                    
                    : <>
                        {(loading && !renderState) && <LeafLoader loadingMessage="Laster inn timer ..."/>}
                        <div className="content__container">
                            <div className="content__header"><h1>Mine timer</h1></div>
                                
                                {error && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={"Kunne ikke hente data for tidligere timer, vennligst last inn siden på nytt. Kontakt oss om problemet vedvarer."}/>}   
                                {fetchError && <ErrorFeedback class="error__container--centered error__container--important error__container--inline error__container--padded" feedbackClass="" message={fetchError}/>}     
                                {renderState && <>
                                    {orders.length >= 1 ?  
                                        <Fragment>  
                                            <div className="content__body--subheader">
                                                <p>En oversikt over alle dine bestilte, kommende timer vist i kronologisk rekkefølge. Du kan både endre og kansellere hver enkelt time. </p>
                                                <PrintButtonUsers title="Skriv ut timelister" onClick={() => setShowExportModal(true)} className="topped"/>
                                            </div>

                                            <div className="content__body"> 
                                                <div className="booking">
                                                    <div className="booking__section booking__section--topped">                                
                                                        {sortDaysByAscDate.map((date, index) => (
                                                            <div key={index} className="booking__section--table">
                                                                <h2>{formatDate(date.day)}</h2><span><span>{date.appointments.length}</span> {date.appointments.length > 1 ? "timer" : "time"}</span>
                                                                <table style={{width:"100%"}} className="table table--xl">
                                                                    <thead>
                                                                        <tr className="table__head">
                                                                            <th className="table__head--main" style={{width:"17%"}}>Tid</th>
                                                                            <th style={{width:"18%"}}>Kornart</th>
                                                                            <th style={{width:"18%"}}>Kornsort</th>
                                                                            <th style={{width:"13%"}}>Tonn</th>
                                                                            <th className="table__head--action" style={{width:"17%"}}>Endre time</th>
                                                                            <th className="table__head--action" style={{width:"17%"}}>Kanseller time</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {date.appointments.map((appointment) => { 
                                                                            return (
                                                                                <Fragment key={appointment.id}>
                                                                                    <tr className={`table__row ${showUpdateAppointment === appointment.id ? "table__row--open" : ""}`}>
                                                                                        <td className="table__row--main"><span style={{fontWeight: "600"}}>{formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</span></td>
                                                                                        {(!expiredAppointments?.find((a) => a === appointment.id)) && (!actionError?.find((a) => a.id === appointment.id)) && <>
                                                                                            <td>{findGrainTypeName(grainVarieties, appointment.grainTypeId)} {isSprayed(grainVarieties, appointment, "serverside")}</td>
                                                                                            <td>{appointment.varietyTypeId ? findVarietyName(grainVarieties, appointment.grainTypeId, appointment.varietyTypeId) : "-"}</td>
                                                                                            <td>{appointment.amount}</td>
                                                                                            <td>
                                                                                                <Button onClick={() => {setShowUpdateAppointment(showUpdateAppointment === appointment.id ? null : appointment.id); setModalId(appointment.id) }} 
                                                                                                    className="text__btn action" title={showUpdateAppointment === appointment.id ? "Avbryt redigering" : "Oppdater time"}>
                                                                                                    <img src={editIcon} alt="Edit icon"/> Endre
                                                                                                </Button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Button onClick={(e) => {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => { return [...items, appointment.id]}) 
                                                                                                    : cancelAppointmentHandler(e, confirm, configuration, appointment, orders, setActionError, setOrders, tokenData)}}
                                                                                                    className={`text__btn action ${showUpdateAppointment && "disabled_text"}`}> 
                                                                                                <img className="sm" src={deleteIcon} alt="Delete icon"/> Kanseller</Button>                                                                                    </td>
                                                                                        </>}
                                                                                        {expiredAppointments?.find((a) => a === appointment.id) && <>                                                        
                                                                                            <td colSpan={4}>
                                                                                                <span className="feedback sm">Denne timen er utløpt.</span>
                                                                                            </td>
                                                                                            <td colSpan={2}></td>
                                                                                        </>}
                                                                                        {actionError?.find((a) => a.id === appointment.id) && <>                                                        
                                                                                            <td colSpan={4}>
                                                                                                <span className="feedback sm">{actionError?.find((a) => a.id === appointment.id).message}</span>
                                                                                            </td>
                                                                                            <td colSpan={2}></td>
                                                                                        </>}
                                                                                    </tr>

                                                                                    {(showUpdateAppointment && modalId === appointment.id) &&
                                                                                        <ActionModal onHide={() => hideHandler(setShowUpdateAppointment)}>
                                                                                            <EditAppointment onHide={() => hideHandler(setShowUpdateAppointment)} app={appointment} updateChangesToState={stateHandler} />
                                                                                        </ActionModal>
                                                                                    }
                                                                                </Fragment>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>  

                                            {showExportModal &&
                                                <ActionModal onHide={() => hideHandler(setShowExportModal)}>                    
                                                    <ExportUserAppointments onHide={() => hideHandler(setShowExportModal)} data={sortAppointmentsByAsc} user={userName} grains={grainVarieties}/>
                                                </ActionModal>
                                            }
                                        </Fragment>
                                        : <p className="empty-result empty-result--lg empty-result--positive">Du har ingen bestilte timer.</p>
                                    }
                                </>}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default UserOrderList;