import print from "../../assets/icons/print_black_24dp.svg";

const PrintButton = (props) => {
    return (
        <button type="button" className={`export__button ${props.className}`} disabled={props.disabled} onClick={props.onClick} title={props.title}>
            <span><img className="sm" src={print} alt="Export data icon"/></span>Eksporter timeliste
        </button>
    )
}

export default PrintButton;

export const PrintButtonUsers = (props) => {
    return (
        <button type="button" className={`button plain thin ${props.className}`} disabled={props.disabled} onClick={props.onClick} title={props.title}>
            <span><img style={{height: "19px", verticalAlign: "middle", marginRight: "8px"}} src={print} alt="Export data icon"/></span>Eksporter timeliste
        </button>
    )
}
