import React from 'react'

const Pagination = ({ numberOfPages, currentPaginationPage, setCurrentPaginationPage }) => {
    const pageNumbers = [...Array(numberOfPages + 1).keys()].slice(1);
    
    const nextPage = () => {
        if(currentPaginationPage !== numberOfPages) setCurrentPaginationPage(currentPaginationPage + 1);
    }

    const prevPage = () => {
        if(currentPaginationPage !== 1) setCurrentPaginationPage(currentPaginationPage - 1);
    }

    return (
        <div className="pagination">
            <ul className="pagination__list">
                <li className={`pagination__list__item ${currentPaginationPage === 1 && "disabled"}`}>
                    <span onClick={prevPage} className="controls">&#10094;</span>
                </li>

                {pageNumbers.map((page) => (
                    <li key={page} className={`pagination__list__item ${currentPaginationPage === page ? "pagination__list__item--active" : ""} `}>
                        <span onClick={() => setCurrentPaginationPage(page)}>
                            {page}
                        </span>
                    </li>
                ))}

                <li className={`pagination__list__item ${currentPaginationPage === numberOfPages && "disabled"}`}>
                    <span onClick={nextPage} className="controls">&#10095;</span>
                </li>
            </ul>
        </div>
    )
    
}

export default Pagination