import { getUserToken } from "../../../../data/token";
import useFetch from "../../../../hooks/useFetch";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer, LabelList } from 'recharts';
import { capitalizeStr } from "../../../../assets/Capitalize";
import { findVarietyName } from "../../user/UserService";

export const calcIncomingTonnage = (data, caseType) => {
    const appointmentsMatchingType = data?.filter((e) => e.grainTypeId === caseType);

    const getAmount = appointmentsMatchingType.reduce(function (prev, current) {
        return prev + +current.amount;
    }, 0);

    return (+getAmount);
};

export const getVarietyData = (data, grainData, grainId) => {
    const currentMatchingType = data?.filter((e) => e.grainTypeId === grainId);

    const groupVariations = currentMatchingType.reduce((appointments, appointment) => {
        const { varietyTypeId, amount} = appointment;
        const itemIndex = appointments.findIndex((appointment) => appointment.varietyTypeId === varietyTypeId);

        if (itemIndex === -1){
            appointments.push({varietyTypeId, amount, name: findVarietyName(grainData, grainId, varietyTypeId)});
        } else {
            appointments[itemIndex].amount += amount;
        }
      
        return appointments;
    }, []);

    return groupVariations;
}

function BarChartByDay ({ summaryData, sortDate }) {
    const tokenData = getUserToken();

    let appointmentData = summaryData;
    let data = appointmentData[0].appointments;

    const { data: grains, grainLoading, grainError} = useFetch("grains", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`, }
    });

    let chartDataPoints;
    let displayChart = false;

    if (appointmentData && data.length > 0 && grains && !grainLoading && !grainError && sortDate) {
        displayChart = true;
    }

    if (displayChart) {
        chartDataPoints = grains.map((grain) => ({
            label: capitalizeStr(grain.name),
            tonn: calcIncomingTonnage(data, grain.id),
            grain: getVarietyData(data, grains, grain.id)
        }));

        const dataPointValues = chartDataPoints.map((dataPoint) => dataPoint.tonn);
        const totalSum = dataPointValues.reduce((partialSum, a) => partialSum + a, 0);

        const totalValueData = {label: "Totalt", totalt: totalSum };
        chartDataPoints.push(totalValueData);
    }

    const calculateTotalVarietyAmount = (array) => {
        const dataPointValues = array.map((dataPoint) => dataPoint.amount);
        return dataPointValues.reduce((partialSum, a) => partialSum + a, 0);
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload[0].value > 0) {
            const grainVarietiesPerGrain = payload.map((a) => a.payload).map((b) => b.grain).flatMap((app) => app);
            const nullGrainVarieties = grainVarietiesPerGrain === undefined || grainVarietiesPerGrain.length === 0;

            return (
                <div className="stacked__bar__tooltip">
                    <p className="stacked__bar__tooltip__label">{label === "Total" ? "Total mengde" : label}</p>
                    <div className="stacked__bar__tooltip__content">
                        <ul>
                            {!nullGrainVarieties && label !== "Totalt" && grainVarietiesPerGrain.map((a) => (
                                a.name? 
                                <li key={a.name}>{a.name}: {a.amount} tonn</li> 
                                :
                                <li key={a.name}>Arten har ingen sorter</li>
                            ))}
                        </ul>
                    </div>
                    <p className="stacked__bar__tooltip__footer">
                        Totalt: {""} {label === "Totalt" ? payload[0].value : calculateTotalVarietyAmount(grainVarietiesPerGrain)} tonn
                    </p>
                </div>
            );
        }
        return null;
    };
      
    return (
        <>
            {grainError && <p>Det oppsto en feil henting av korndata, vennligst last inn siden på nytt.</p>}
            
            {displayChart && chartDataPoints.length > 0 && 
                <div className="graph">
                    <h4>Oversikt for kornlevering - per dag</h4>
                    <div className="graph--wrapper diagram__backdrop diagram__backdrop--sm ">
                        <div style={{ width: '100%', height: 350 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart width={1400} data={chartDataPoints} margin={{ top: 15, right: 0, left: 0, bottom: 5}} padding={{top: 20}}>
                                    <CartesianGrid strokeDasharray="2 1" opacity={0.4} />
                                    <Tooltip cursor={{fill: '#ebebeb85'}} content={<CustomTooltip />}/>
                                    <Legend />

                                    <Bar dataKey="tonn" stackId="a" fill="#f29e0d" barSize={50}>
                                        <LabelList dataKey="tonn" position="top" />
                                    </Bar>
                                    <Bar dataKey="totalt" stackId="a" fill="#1a2634" barSize={50}>
                                        <LabelList dataKey="totalt" position="top" />
                                    </Bar>
                                    <XAxis dataKey="label" interval={0} stroke={"#3b454e"} padding={{ top: 10 }}></XAxis>
                                    <YAxis stroke={"#3b454e"} width={80}>
                                        <Label position={"insideLeft"} offset={25} angle={270} style={{ fontSize: "13", fill: "#3b454e", fontWeight: "600"}} value={"Tonn"} />
                                    </YAxis>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default BarChartByDay;