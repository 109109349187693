import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BoardLayout from "../../../../layout/BoardLayout";
import { apiUrl } from "../../../../../data/API";
import { capitalizeStr, capitalizeName } from "../../../../../assets/Capitalize";
import { calculateRemainingTime, calculateUpcomingClass } from "./BoardHelper";
import {CenterLeafLoader} from "../../../../../assets/LoadingSpinner";
import { Feedback } from "../../../../../assets/Feedback";
import fullscreenIcon from "../../../../../assets/icons/fullscreen_FILL1_wght400_GRAD0_opsz40.svg";
import exitFullscreenIcon from "../../../../../assets/icons/fullscreen_exit_black_24dp.svg";
import BoardButton from "./BoardButton";

const Board = () => {
    const [defaultTimecode, setDefaultTimecode] = useState(new Date().getTime() + (60*4)*60000); 
    const [time, setTime] = useState(new Date().toLocaleTimeString());
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [upcoming, setUpcoming] = useState([]);
    const [select, setSelect] = useState(1);
    const [fullscreen, setFullscreen] = useState(false);

    let sortDaysByAscDate;
    let upcomingCount;

    const getBoard = (time) => {
        setLoading(true);
        setError("");

        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get("key");

        fetch(apiUrl + `board?key=${key}&end=${time}`)
        .then((response)=> {
            setLoading(false);
            if (response.ok) {
                setError("");
                return response.json().then((data) => { setUpcoming(data) })
            } else {
                return response.json().then((data) => {
                    setError("Det har oppstått en feil ved oppdatering av tavlevisningen, vennligst last inn siden på nytt.");
                });
            }
        }).catch((error) => {
            setLoading(false);
            setError("Det har oppstått en feil ved oppdatering av tavlevisningen, vennligst last inn siden på nytt.");
        });
    };
    
    useEffect(() => {
        getBoard(defaultTimecode);

        const timer = setInterval(() => {
            updateClock();
        }, 1000);

        const fetchInterval = setInterval(() => {
            getBoard(defaultTimecode);
        }, 60000);

        return () => {clearTimeout(fetchInterval); clearTimeout(timer)};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultTimecode])

    const setTimeCode = (event, hours) => {
        event.preventDefault();
        const timecode = new Date().getTime() + (60*hours)*60000;
        setDefaultTimecode(timecode)
        getBoard(timecode);
    }
        
    const handleSelect = (id) => { setSelect(id)};

    const renderButtons = () => {
        return (
            <Fragment>
                <BoardButton className={`${select === 1 ? "button--xl blank" : "button--lg"}`} disabled={select === 1} onClick={(e) => {handleSelect(1); setTimeCode(e, 4); setError("")}}>4 timer </BoardButton>
                <BoardButton className={`${select === 2 ? "button--xl blank" : "button--lg"}`} disabled={select === 2} onClick={(e) => {handleSelect(2); setTimeCode(e, 6); setError("")}}>6 timer </BoardButton>
                <BoardButton className={`${select === 3 ? "button--xl blank" : "button--lg"}`} disabled={select === 3} onClick={(e) => {handleSelect(3); setTimeCode(e, 8); setError("")}}>8 timer </BoardButton>
                <BoardButton className={`${select === 4 ? "button--xl blank" : "button--lg"}`} disabled={select === 4} onClick={(e) => {handleSelect(4); setTimeCode(e, 12); setError("")}}>12 timer </BoardButton>
                <BoardButton className={`${select === 5 ? "button--xl blank" : "button--lg"}`} disabled={select === 5} onClick={(e) => {handleSelect(5); setTimeCode(e, 24); setError("")}}>24 timer </BoardButton>
                <BoardButton className={`${select === 6 ? "button--xl blank" : "button--lg"}`} disabled={select === 6} onClick={(e) => {handleSelect(6); setTimeCode(e, 48); setError("")}}>48 timer </BoardButton>
            </Fragment>
        )
    }

    const updateClock = () => {
        setTime(new Date().toLocaleTimeString());
    }

    const calcIncomingTonnage = (caseType) => {
        const appointmentsMatchingType = sortDaysByAscDate.filter(e => e.grain === caseType);

        return appointmentsMatchingType.reduce(function(prev, current) {
            return prev + +current.amount
          }, 0);
    } 

    const openInFullscreen = () => {
        const content = document.getElementById("root");

        if (content.requestFullscreen) {
            content.requestFullscreen();
        } else if (content.webkitRequestFullscreen) { 
            content.webkitRequestFullscreen();
        } else if (content.msRequestFullscreen) { 
            content.msRequestFullscreen();
        }

        setFullscreen(true);
    }

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { 
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { 
            document.msExitFullscreen();
        }

        setFullscreen(false);
    }

    if (!loading && upcoming.appointments) {
        const getAppointments = upcoming.appointments.map((a) => a);

        sortDaysByAscDate = getAppointments.slice(0).sort((a,b)=> a.scheduledAt - b.scheduledAt);
        upcomingCount = sortDaysByAscDate.length;
    }

    // board HOPPER pga de eer dependancies i useeffect!

    return (
        <BoardLayout>
            <section className="board__section board__header">
                <h1>Tavlevisning</h1>

                <div className="board__header__summary">
                    <p><span className="bold">{upcomingCount}</span> Kommende {upcomingCount === 1 ? "time" : "timer"}</p>
                    {!loading && !error && upcoming.appointments ? 
                        <ul>
                            <li><span>{calcIncomingTonnage("HVETE")}</span> tonn Hvete</li>
                            <li><span>{calcIncomingTonnage("BYGG")}</span> tonn Bygg</li>
                            <li><span>{calcIncomingTonnage("RUG")}</span> tonn Rug</li>
                            <li><span>{calcIncomingTonnage("HAVRE")}</span> tonn Havre</li>
                        </ul>
                    : ""}
                </div>
            </section>

            <section className="board__section board__clock">
                <h2 className="board__clock--display">{time}</h2>
            </section>

            <section className="board__section board__menu">
                <div className="board__menu__buttons">
                    <h3>Velg tidsrom</h3>
                    {renderButtons()}
                </div>
                <div className="board__menu__links">
                    {/* <Link to="/administrator" title="Gå til administratorpanel"><p><img src={backIcon} alt="Go back icon"/> Administratorpanel</p></Link> */}
                    {fullscreen ?
                        <Link to="#" onClick={() => closeFullscreen()} title="Lukk fullskjermvisning"><p><img src={exitFullscreenIcon} alt="Exit fullscreen icon"/>Lukk fullskjerm</p></Link>
                        :                     
                        <Link to="#" onClick={() => openInFullscreen()} title="Åpne fullskjermvisning"><p><img src={fullscreenIcon} alt="Fullscreen icon"/>Åpne fullskjerm</p></Link>
                    }
                </div>
            </section>

            <section className="board__section board__content">
                {error && <Feedback class="feedback--center feedback--spacing" feedbackClass="feedback--error" message={error}/>}
                {loading && <CenterLeafLoader loadingMessage="Laster inn tavlevisning ..."/>}
                {!loading && !error && upcoming.appointments && <Fragment>
                    <table style={{width: "100%"}} className="board__table">
                        <thead className="board__table__head">
                            <tr>
                                <th className="board__table__head--main" style={{width:"8%"}}>Tid</th>
                                <th style={{width:"48%"}}></th>
                                <th style={{width:"24%"}}>Kunde</th>
                                <th style={{width:"8%"}}>Tonn</th>
                                <th style={{width:"12%"}}>Art</th>
                            </tr>
                        </thead>
                        <tbody className="board__table__body">    
                            {upcoming.appointments.length >= 1 && sortDaysByAscDate.map((appointment, index) => (
                                <tr key={index} className={`board__table__item ${calculateUpcomingClass(appointment.scheduledAt)}`}>
                                    <td>{calculateUpcomingClass(appointment.scheduledAt) === "upcoming__delivery" ? <p>Om</p> : <p className="board__table__item--next">Nå</p>}</td>
                                    <td>{calculateRemainingTime(appointment.scheduledAt)}</td>
                                    <td>{capitalizeName(appointment.reservedBy.toString())}</td>
                                    <td>{appointment.amount}</td>
                                    <td>{capitalizeStr(appointment.grain.toString())}</td>
                                </tr>                               
                            ))}
                        </tbody>
                    </table>
                    
                    {upcoming.appointments.length < 1 && <p className="board__error">Ingen kommende timer innen valgt tidsrom.</p>}
                </Fragment>}
            </section>
        </BoardLayout>
    );
};

export default Board;
