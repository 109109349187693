import { Fragment, useState, useEffect } from "react";
import { apiUrl } from "../../../../../data/API";
import { getUserToken } from "../../../../../data/token";
import { delayPromise } from "../../../../../assets/DelayPromise";
import { Feedback, TimeoutFeedback } from "../../../../../assets/Feedback";
import Button from "../../../../UI/Button";

const GrainUpdate = (props) => {
    const [grainToUpdate, setGrainToUpdate] = useState(props.grain);
    const [feedback, setFeedback] = useState("");
    const [error, setError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    
    const tokenData = getUserToken();
    const maxGrain = props.grainTreshold;

    useEffect(() => {
        setGrainToUpdate(props.grain);
    },[props.grain])

    let formIsValid = false;
    let appointmentMaxError = false;
    let hourlyMaxError = false;

    const handleChange = (event, grain) => {
        event.persist(); 
        const { id, value } = event.target;
        let updateField;

        if (isNaN(value)) {
            updateField = {...grain, [id] : parseInt(value)};
        } else {
            updateField = {...grain, [id] : value};
        }

        setGrainToUpdate(updateField);
    }

    if (grainToUpdate.appointmentMax && grainToUpdate.hourlyMax) {
        formIsValid = true;
    }

    if (grainToUpdate.appointmentMax > grainToUpdate.hourlyMax) {
        formIsValid = false;
        appointmentMaxError = true;
    }

    if (grainToUpdate.hourlyMax > maxGrain) {
        formIsValid = false;
        hourlyMaxError = true;
    }

    const updateGrain = (event) => {
        event.preventDefault();

        if (formIsValid) {
            setError("");
            setIsSuccess("");
            setFeedback("Oppdaterer kornart..");      
            fetch(apiUrl + `grains/${grainToUpdate.id}?appointmentMax=${grainToUpdate.appointmentMax}&hourlyMax=${grainToUpdate.hourlyMax}`, {
                    method: "PATCH",
                    headers: {
                        "Authorization": `Bearer ${tokenData}`,
                        "Content-Type": "application/json",
                    }
            }).then(delayPromise(2000))
            .then((response) => {
                if (response.ok) {
                    setError("");
                    props.updateChangesToState(grainToUpdate); 
                    setIsSuccess("Kornart oppdatert");
                    setFeedback("");      
                    return response.text();
                } else {
                    setFeedback("");
                    return response.json().then((data) => {
                        setFeedback("");
                        setIsSuccess(""); 
                        if (data.message === "APPOINTMENT_MAX_EXCEEDS_HOURLY_MAX") {
                            setError("Det kan ikke være et høyere antall tonn per time enn den totale mengden tillat for en klokketime.");
                        } else if (data.message === "VALUES_MUST_BE_GREATER_THAN_ZERO") {
                            setError("Både antall tonn for en time og en klokketime må være fylt ut før du kan oppdatere.");
                        } else if (data.message === "GRAIN_DOES_NOT_EXIST") {
                            setError("Det har oppstått en feil, kornarten finnes ikke.");
                        } else {
                            setError("Det oppsto en feil ved oppdatering av kornart. Last inn siden på nytt og prøv igjen.");
                        }  
                    });
                }
            }).catch((error) => {
                setFeedback("");
                setIsSuccess(""); 
                setError("Det oppsto en feil ved oppdatering av korn. Last inn siden på nytt og prøv igjen.");
            });
        } else {
            return
        }
    }

    const clearSuccessState = () => {
        setIsSuccess("");
    }

    return (
        <Fragment>
            {!error && grainToUpdate && <>
                <form className="graintable__item--input">
                    <div>
                        <label htmlFor="appointmentMax">Maks tonn per time </label>
                        <input type="number" id="appointmentMax" name="appointmentMax" className={(!grainToUpdate.appointmentMax || appointmentMaxError) ? "input--error" : null}
                            defaultValue={grainToUpdate.appointmentMax} onChange={(e) => handleChange(e, grainToUpdate)} autoComplete="off"/> 
                        {(grainToUpdate.appointmentMax <= 0) && <p className="error-text">Uglydig verdi.</p>}
                        {appointmentMaxError && <p className="error-text">Det kan ikke være et høyere antall tonn per time enn den totale mengden tillat for en klokketime.</p>}
                    </div>
                    <div>
                        <label htmlFor="hourlyMax">Maks tonn per klokketime </label>
                        <input type="number" id="hourlyMax" name="hourlyMax" className={!grainToUpdate.hourlyMax ? "input--error" : null}
                            defaultValue={grainToUpdate.hourlyMax} onChange={(e) => handleChange(e, grainToUpdate)} autoComplete="off"/> 
                        {grainToUpdate.hourlyMax <= 0 && <p className="error-text">Uglydig verdi.</p>}
                        {hourlyMaxError && <p className="error-text">Vektgrensen er satt til {maxGrain} tonn, du kan endre dette under "Innstillinger".</p>}
                    </div>
                </form>
                
                {feedback && <Feedback class="feedback--center" feedbackClass="feedback--warning" message={feedback}/>} 
                {error && <Feedback class="feedback--center" feedbackClass="feedback--error" message={error}/>} 
                {isSuccess && <TimeoutFeedback class="feedback--center" feedbackClass="feedback--success" clearMessage={clearSuccessState} message={isSuccess}/>}
                <Button className="confirm topped button__mr" type="submit" onClick={(e) => updateGrain(e)} disabled={!formIsValid}>Lagre</Button> 
            </>}
        </Fragment>
    );
};

export default GrainUpdate;
