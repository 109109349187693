import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__section">
                <p className="footer__section--logo">Braskereidfoss Kornsilo SA</p>
            </div>
            <div className="footer__section">
                <p>N-2435 Braskereidfoss</p>
                <p>Org.nr NO 910 776 703 MVA</p>
                <p><Link title="Personvernerklæring" to={"/privacy"}>Personvernerklæring</Link></p>
            </div>
            <div className="footer__section">
                <p><span>Telefon: </span>62 42 97 70</p>
                <p><span>Epost:</span> post@brakorn.no</p>
            </div>
        </footer>
    );
};

export default Footer;
