import { formatDate } from "../../../../assets/DateFormatOptions";
import BarChartByDay from "./BarChartByDay";
import HourlySummary from "./HourlySummary";

function DaySummary ({ data, cancelled, date, reserved }) {
    let summaryData = data[0].appointments;
    let cancelledToday = cancelled;
    let todaysDate = date;

    let findAppointmentsBookedToday = reserved;
    let displayChart = false;
    let incomingDeliveries = 0;
    let availableAppointments = 0;
    let uniqueProviders = 0;

    if (data && summaryData.length > 0 && todaysDate) {
        incomingDeliveries = summaryData?.filter((app) => app.reservedAt && app.scheduledAt.split("T")[0] === todaysDate).length;  

        const scheduledOnSelectedDay = summaryData?.filter((app) => (app.scheduledAt.split("T")[0] === todaysDate));
        const availableOnSelectedDay = scheduledOnSelectedDay.filter((app) => app.state === 'AVAILABLE').length;
        const unusedOnSelectedDay = scheduledOnSelectedDay.filter((app) => app.state === 'UNUSED').length;
        availableAppointments = +availableOnSelectedDay + +unusedOnSelectedDay;

        const providers = summaryData.filter((obj) => obj.reservedBy).map((a) => a.reservedBy);
        uniqueProviders = [...new Set(providers)].length;
    }

    if (data && todaysDate) {
        displayChart = true;
    }

    return ( <>
        {displayChart && <>
            <div className="dashboard__week__day--keys">
                <div className="key">
                    <h4>Nøkkeltall for {formatDate(todaysDate)}</h4>
                    <div className="key--wrapper">
                        <div className="key__item">
                            <div>
                                <h3>Leveringer</h3>
                                <p>{incomingDeliveries}</p><span>{incomingDeliveries <= 0 ? "" : "stk"}</span>  
                            </div>
                        </div>
                        <div className="key__item">
                            <div>
                                <h3>Unike leverandører</h3>
                                <p>{uniqueProviders}</p><span>{uniqueProviders <= 0 ? "" : "stk"}</span>  
                            </div>
                        </div>
                        <div className="key__item">
                            <div>
                                <h3>Timer bestilt</h3>
                                <p>{findAppointmentsBookedToday}</p><span>{findAppointmentsBookedToday <= 0 ? "" : "stk"}</span>                      
                            </div>
                        </div>
                        <div className="key__item">
                            <div>
                                <h3>Ledige timer</h3>
                                <p>{availableAppointments}</p><span>{availableAppointments <= 0 ? "" : "stk"}</span>
                            </div>
                        </div>
                        <div className="key__item">
                            <div>
                                <h3>Kanselleringer</h3>
                                <p>{cancelledToday}</p><span>{cancelledToday <= 0 ? "" : "stk"}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dashboard__week__day--graph">
                <BarChartByDay summaryData={data} sortDate={date}/>
            </div>

            <div className="dashboard__week__day--graph">
                <HourlySummary appointments={summaryData}/>
            </div>
        </>}    
    </>);
}

export default DaySummary;

