import { apiUrl } from "../data/API";
import { removePastAndSortDates } from "../components/booking/BookingService";
import { groupAllAppointments } from "./GroupAppointments";

const fetchMultiple = (url, url2, token, state, loadingState, errorState, setFilter, currentFilter, callback) => {
    loadingState(true);
    errorState("");

    Promise.all([
        fetch(apiUrl + url, { method: "GET", headers: { "Authorization": `Bearer ${token}`}})
        .then((response)=> {
            if (response.ok) {
                errorState("");
                return response.json();
            } else {
                return response.json().then((data) => {
                    errorState("Det har oppstått et problem ved henting av ledige timer. Vennligst last inn siden på nytt for å se om dette vedvarer.");
                });
            }
        }),
        fetch(apiUrl + url2, { method: "GET", headers: { "Authorization": `Bearer ${token}`}})
        .then((response)=> {
            if (response.ok) {
                errorState("");
                return response.json();
            } else {
                return response.json().then((data) => {
                    errorState("Det har oppstått et problem ved henting av reservertee timer. Vennligst last inn siden på nytt for å se om dette vedvarer.");
                });
            }
        })
    ]).then(([urlOneData, urlTwoData]) => {
        const combineArray = urlOneData.concat(urlTwoData);
        const combinedAppointments = removePastAndSortDates(combineArray);
        const groupedByDays = groupAllAppointments(combinedAppointments);
        const result = groupedByDays.slice(0).sort((a,b)=> a.day.localeCompare(b.day));

        if (combinedAppointments.length > 0) {
            const newFilter = !currentFilter ? result[0].day : currentFilter;
            setFilter(newFilter);
        }

        loadingState(false);
        state(result);
        callback && callback(true);
    }).catch(() => {
        loadingState(false);
        errorState("Det har oppstått et problem ved henting av alle timer. Vennligst last inn siden på nytt for å se om dette vedvarer.");
    });
}

export default fetchMultiple;