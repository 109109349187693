import Modal from "../UI/Modal";
import Login from "./Login"; 

const LoginModal = (props) => {
    return (
        <Modal onHide={props.onHide}>
            <Login onHide={props.onHide} />
        </Modal> 
    );
};

export default LoginModal;