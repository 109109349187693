import React, { useState } from "react";
import useInput, { inputNotEmpty } from "../../../../../hooks/useInput";
import Button from "../../../../UI/Button";
import { concatenateTimeFunction, formatTimeString } from "../../../../../assets/DateFormatOptions";
import { TimeoutFeedback } from "../../../../../assets/Feedback";
import { guidGenerator } from "../../../../booking/BookingService";

export const padWithZero = (number) => {
    if (number < 10) {
        return number = "0" + number
    } else {
        return number;
    }
}

export const TimespanInputs = (props) => {
    const [timespan, setEnteredTimespan] = useState({ data: []});
    const [formError, setFormError] = useState(false);

    const { value: timespanStartValue, isValid: timespanStartIsValid, hasError: timespanStartHasError, valueChangeHandler: timespanStartChangeHandler, inputBlurHandler: timespanStartBlurHandler, reset: resetTimespanStart } = useInput(inputNotEmpty);
    const { value: timespanEndsValue, isValid: timespanEndsIsValid, hasError: timespanEndsHasError, valueChangeHandler: timespanEndsChangeHandler, inputBlurHandler: timespanEndsBlurHandler, reset: resetTimespanEnds } = useInput(inputNotEmpty);
    const { value: minutesBetweenValue, isValid: minutesBetweenIsValid, hasError: minutesBetweenHasError, invalidMinutesBetween: passedMinuteLimit, valueChangeHandler: minutesBetweenChangeHandler, inputBlurHandler: minutesBetweenBlurHandler, reset: resetMinutesBetween } = useInput(inputNotEmpty);

    let setIsValid = false;
    let invalidValue = false; 

    const timeInputChangeHandler = (event) => {
        event.persist();
        const value = event.target.value;

        setEnteredTimespan({
            ...timespan,
            data: {
                ...timespan.data,
                id: guidGenerator(),
                [event.target.id]: value,
            },
        });
    };

    if (minutesBetweenIsValid && minutesBetweenValue <= 0) {
        invalidValue = true;
    }

    if (timespanStartIsValid && timespanEndsIsValid && minutesBetweenIsValid && !passedMinuteLimit && !invalidValue) {
        setIsValid = true;
    }

    const saveTimespan = (event) => {
        event.preventDefault();
        const concatenateTime = concatenateTimeFunction(timespanStartValue, timespanEndsValue);
        
        if (!setIsValid) { 
            setFormError("Skjemaet har feiler eller felter som ikke er fylt ut.");
        } else {
            props.onAddTimespan({id: guidGenerator(), time : concatenateTime, minutesBetween : minutesBetweenValue});
            resetTimespanStart();
            resetTimespanEnds();
            resetMinutesBetween();
            setFormError(false);
            setEnteredTimespan({ data: [] });
        }
    };

    const clearErrorState = () => {
        setFormError("");
    }

    return (
        <div name="templateList" className="templates__form__container__list">
            <div className="create__step__body">       
                <div>
                    <label htmlFor="timespanStart">Fra <span className="required" title="Påkrevd felt">*</span></label>    
                    <input type="time" id="timespanStart" value={timespanStartValue} className={`${(timespanStartHasError ? "input--error" : null)}`} 
                        onChange={(e) => {timespanStartChangeHandler(e); timeInputChangeHandler(e)}} onBlur={timespanStartBlurHandler} name="timespanStart"
                    />                       
                    {timespanStartHasError && ( <p>Velg start-klokkeslett</p>)}
                </div>

                <div>
                    <label htmlFor="timespanEnds">Til <span className="required" title="Påkrevd felt">*</span></label>
                    <input type="time" id="timespanEnds" value={timespanEndsValue} className={`${(timespanEndsHasError ? "input--error" : null)}`}
                        onChange={(e) => {timespanEndsChangeHandler(e); timeInputChangeHandler(e)}} onBlur={timespanEndsBlurHandler} name="timespanEnds"
                    />
                    {timespanEndsHasError && ( <p>Velg slutt-klokkeslett</p>)}
                </div>
            </div>
            <div className="create__step__body">       
                <div>
                    <label htmlFor="minutesBetween">Tid <span className="required" title="Påkrevd felt">*</span></label>    
                    <input type="number" id="minutesBetween" value={minutesBetweenValue} autoComplete="off" name="minutesBetween"
                        onChange={(e) => {minutesBetweenChangeHandler(e); timeInputChangeHandler(e)}} onBlur={minutesBetweenBlurHandler} 
                        className={`${(minutesBetweenHasError || invalidValue || passedMinuteLimit ? "input--error" : null)}`} placeholder="Minutter mellom timer"
                    />
                    {minutesBetweenHasError && (<p>Sett minutter mellom</p>)}
                    {passedMinuteLimit && (<p>Det kan ikke være mer enn 120 minutter (2 timer) mellom timene</p>)}    
                    {invalidValue && <p>Uglydig verdi oppgitt</p>}          
                </div>
            </div>
            <div className="create__step__body">       
                <Button className="confirm thin button__mr" type="button" title="Bruk tidsrom og tid" onClick={saveTimespan}>Bruk nå</Button>
                <Button className="text__btn" onClick={() => {resetTimespanStart(); resetTimespanEnds(); resetMinutesBetween(); setEnteredTimespan({data: []})}}>
                    Tøm felter
                </Button>
            </div>
            {formError && <TimeoutFeedback class="" clearMessage={clearErrorState} feedbackClass="feedback--error" message={formError}/>}
        </div>
    );
};            

const TimespanItem = (props) => {
    const deleteHandler = () => {
        props.onDelete(props.id);
    };

    return ( 
        <div className="summary__table__content--item">
            {props.children}
            <p onClick={deleteHandler} title="Fjern klokkeslett og varighet">Fjern</p>
        </div> 
    );
};
                 
export const TimespanList = (props) => {
    const sortTimespansByTime = props.items.sort((a,b)=> a.time - b.time);

    return (
        <div className="summary__table__content">  
            {sortTimespansByTime.map((timespan) => ( 
                <TimespanItem key={timespan.id} id={timespan.id} onDelete={props.onDeleteTimespan}>
                    <p>{formatTimeString(timespan.time)}</p>
                    <p>{timespan.minutesBetween} minutter</p>
                </TimespanItem>
            ))}
        </div>
    );
};

export const addPremadeTemplateToList = (event, array, item, setState, emptyState) => {
    event.persist();
    
    const concatTemplateTime = '' + padWithZero(item.startHour) + padWithZero(item.startMinute) + padWithZero(item.endHour) + padWithZero(item.endMinute);

    const template = {
        id: item.id,
        minutesBetween: item.minutesBetween,
        time: concatTemplateTime
    }

    if (array.find((i) => i.id === item.id)) {
        return
    } else {
        setState((array) => {
            const getArray = [...array];
            getArray.unshift(template);
            return getArray;
        });
    }

    emptyState([]);
};

export const getDryRunData = (event, formvalidation, errorState, api, url, token, setDryRun, setState, warningState, dryRunLoading) => {
    event.preventDefault();

    if (!formvalidation) { return; }

    errorState("");
    setDryRun(true);
    dryRunLoading(true);
    fetch(api + url + `&dryRun=true`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json",
            },
    }).then((response) => {
        dryRunLoading(false);
        if (response.ok) {
            response.json().then((data) => {
                if (data.length > 0 ) {
                    return setState(data);
                } else {
                    warningState("Det er ikke mulig å lage noen timer for tidsrommet du har valgt; det eksisterer allerede ledige timer for hele tidsrommet.");
                }
            })
        } else {
            return response.json().then((data) => {
                errorState("Det oppsto en feil ved visning av timer. Vennligst last inn siden på nytt og forsøk igjen.");
            });
        }
    }).catch((error) => {
        errorState("Det oppsto en feil ved visning av timer. Vennligst last inn siden på nytt og forsøk igjen.");
    });
}

export const resetForm = (event, list, days, start, end, publishDate, dryrun, resetStartValue, resetEndValue, resetPublishData, timespanTabs, selectedPublishMethod, frequencyOfSkipsValue, numberOfSkips) => {
    event.preventDefault();
    list([]);
    days([]);
    start("");
    end("");
    publishDate(null);
    dryrun([]);
    resetStartValue();
    resetEndValue();
    resetPublishData();
    timespanTabs("dayspans");
    selectedPublishMethod("publishNow");
    frequencyOfSkipsValue("1");
    numberOfSkips("0");
}
