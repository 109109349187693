import { capitalizeStr } from "../../../assets/Capitalize";
import { getTimeBetween } from "../../../assets/DateFormatOptions";

export const findGrainTypeName = (data, grainTypeId) => {
    let grainName = "Ukjent type";
    const grainTypeName = data.find((t) => t.id === grainTypeId);

    if (grainTypeName) {
        grainName = capitalizeStr(grainTypeName.name)
    }
    
    return grainName;
}

export const findVarietyName = (data, grainTypeId, varietyTypeId, classification) => {
    let varietyName = "";
    let varietyClassification = "";
    const findGrainType = data.find((t) => t.id === grainTypeId);

    if (findGrainType && findGrainType.varieties.length >= 1) {
        const variety = findGrainType.varieties.find((v) => v.id === varietyTypeId);

        if (variety) {
            varietyName = capitalizeStr(variety.name);

            if (classification) {
                varietyClassification = variety.classification ? variety.classification : "";
            }
        } else {
            varietyName = "Ukjent sort";
        }
    }

    if (!classification) {
        return varietyName;
    } else {
        return varietyClassification;
    }
}

export const isSprayed = (graindata, app, serverside) => {
    // serverside check for data, as they are different local and from server
    const matchingGrain = graindata.find((t) => t.id === app.grainTypeId);

    if (!matchingGrain) return;
    
    const grainTypeName = matchingGrain.name;

    if (grainTypeName.toLowerCase() !== "havre") return;

    if (serverside)  {
        return app.sprayed !== false ? <span className="note">(Sprøytet)</span>: "";
    } else {
        return app.sprayed === false ? <span className="note">(Sprøytet)</span>: "";
    }
}


export const returnEmptyVariety = (data, grainTypeId) => {
    let fillerVarietyName = "";
    const findGrainType = data.find((t) => t.id === grainTypeId);

    if (findGrainType) {
        fillerVarietyName = capitalizeStr(findGrainType.name);
    }

    return fillerVarietyName;
}

export const calculateThreshold = (date, thresholdReached, threshold) => {
    const timeDifference = getTimeBetween(date); 
    const thresholdInMilli = (threshold*60)*1000;

    if (timeDifference < thresholdInMilli) {
        thresholdReached = true;

    } else {
        thresholdReached = false;
    }

    return thresholdReached;
}