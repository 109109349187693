import { Outlet, Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./context/auth-context";
import { BookingContextProvider } from "./context/booking-context";
import MainLayout from "./components/layout/MainLayout";
import AdminLayout from "./components/layout/AdminLayout";
import HomePage from "./components/home/HomePage";
import ErrorPage from "./assets/ErrorBoundary";
import BookingPage from "./components/booking/BookingPage";
import AdminPage from "./components/access/admin/AdminPage";
import BookingConfirmation from "./components/booking/BookingConfirmation";
import UserOrderList from "./components/access/user/UserOrderList";
import UserOrderArchive from "./components/access/user/UserOrderArchive";
import CreateAppointments from "./components/access/admin/manage/appointments/CreateAppointments";
import TimespanTemplatesPage from "./components/access/admin/manage/templates/TimespanTemplatesPage";
import Board from "./components/access/admin/manage/board/Board";
import CustomerList from "./components/access/admin/manage/customer/CustomerList";
import CancelledAppointments from "./components/access/admin/manage/appointments/CancelledAppointments";
import ReservedAppointments from "./components/access/admin/manage/appointments/ReservedAppointments";
import GrainPage from "./components/access/admin/manage/grain/GrainPage";
import ConfigPage from "./components/access/admin/manage/config/ConfigPage";
import AppointmentBoard from "./components/access/admin/manage/appointments/dashboard/AppointmentBoard";
import CustomerCategories from "./components/access/admin/manage/customer/CustomerCategories";
import HistoricAppointments from "./components/access/admin/manage/appointments/HistoricAppointments";
import AppointmentAttendance from "./components/access/admin/manage/appointments/AppointmentAttendance";
import Privacy from "./components/privacy/Privacy";

const ProtectedRoute = ({isAllowed, redirectPath = '/', children,}) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }
  
    return children ? children : <Outlet />;
};


function App() {
    const userCtx = useContext(AuthContext);
    let roleAccess = userCtx.isAdmin ? "adminUser" : "user";

    const router = createBrowserRouter([
        {
            path: "/",
            element: <MainLayout />,
            errorElement: <ErrorPage/>,
            children: [
                { index: true, element: <HomePage />},
                {
                    path: "timebestilling",
                    element: <ProtectedRoute isAllowed={userCtx.isLoggedIn}><BookingContextProvider><BookingPage access={roleAccess}/></BookingContextProvider></ProtectedRoute>,
                },
                {
                    path: "timebestilling/kvittering",
                    element: <ProtectedRoute isAllowed={userCtx.isLoggedIn}><BookingConfirmation /></ProtectedRoute>,
             
                },
                { path: "mine-timer", element: <ProtectedRoute isAllowed={userCtx.isLoggedIn}><UserOrderList access={roleAccess}/></ProtectedRoute>},
                { path: "arkiv", element: <ProtectedRoute isAllowed={userCtx.isLoggedIn}><UserOrderArchive access={roleAccess}/></ProtectedRoute>},
                { path: "privacy", element: <Privacy />},
            ],
        },
        {
            path: "/administrator",
            element: 
                <ProtectedRoute isAllowed={userCtx.isLoggedIn && userCtx.isAdmin}><AdminLayout></AdminLayout></ProtectedRoute>,
            children: [
                { index: true, path: "/administrator", element: <Navigate to="/administrator/timeovervoker" replace />},
                { path: "timeovervoker", element: <AppointmentBoard/> },
                { path: "ukeoversikt", element: <AdminPage/> },
                { path: "innstillinger", element: <ConfigPage/> },
                { path: "kundeliste", element: <CustomerList/> },
                { path: "kanselleringer", element: <CancelledAppointments/> },
                { path: "bestilte-timer", element: <ReservedAppointments/> },
                { path: "opprett-timer", element: <CreateAppointments/> },
                { path: "maler", element: <TimespanTemplatesPage/> },
                { path: "korn", element: <GrainPage/> },
                { path: "kategorier", element: <CustomerCategories/> },
                { path: "historiske-timer", element: <HistoricAppointments/> },
                { path: "fraværstimer", element: <AppointmentAttendance/> },
            ],
        },
        {
            path: "/tavle",
            element: <Board/>,
        },
    ]);

    return <RouterProvider router={router} />;
}

export default App;
