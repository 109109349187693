import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useConfirm from '../../hooks/useConfirm';
import Button from './Button';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
    const portalElement = document.getElementById('confirmOverlay');

    const handleEscape = event => { if (event.key === 'Escape') onCancel();}

    const keyListenersMap = new Map([[27, handleEscape],]);

    const handleKeydown = event => {
        const listener = keyListenersMap.get(event.keyCode)

        return listener && listener(event);
    }

    useEffect(() => {   
        document.addEventListener('keydown', handleKeydown)
        return () => { document.removeEventListener('keydown', handleKeydown) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const component = confirmState.show ? (
        <div className="confirm__modal">
            <div className="confirm__modal__overlay" onClick={onCancel}>
            </div>
            <div className="confirm__modal__dialog">
                <p>{confirmState?.text && confirmState.text}</p>
                <div className="confirm__modal__dialog--buttons"> 
                    <Button className="confirm" type="button" title="Godkjenn handling" onClick={onConfirm}>Ok</Button>
                    <Button className="plain" type="button" title="Angre handling" onClick={onCancel}>Avbryt</Button>
                </div>
            </div>
        </div>
    ) : null;

    return createPortal(component, portalElement);
};
export default ConfirmDialog;