import { calcIncomingTonnage } from "./BarChartByDay";
import { capitalizeStr } from "../../../../assets/Capitalize";
import { cleanPercentage } from "../../../../assets/CleanPercentage";
import { PieChart, Pie, Label, Cell, Tooltip } from 'recharts';
import scale from "../../../../assets/icons/scale_black_24dp.svg";
import calendar from "../../../../assets/icons/calendar_today_black_24dp.svg";

function WeekSummary ({ data, grains, cancellations}) {
    const allAppointments = data.filter((a) => a.state !== "UNPUBLISHED" && a.state !== "UNUSED_AND_UNPUBLISHED"); 
    const unpublishedAppointments = data.filter((a) => a.state === "UNPUBLISHED" || a.state === "UNUSED_AND_UNPUBLISHED");  
    const reservedApps = data.filter((a) => a.reservedAt);
    const allReservations = reservedApps.length;

    const orderPercentage = cleanPercentage((allReservations/allAppointments.length) * 100).toFixed();

    const percentageData = [
        {label: "Bestillingsprosent", value: parseInt(orderPercentage)},
        {label: "Tomrom", value: (100 - parseInt(orderPercentage))}
    ]

    const calculatedAppObj = grains.map((grain) => ({
        label: capitalizeStr(grain.name),
        value: calcIncomingTonnage(reservedApps, grain.id),
    }));
    
    const totalTonnage = calculatedAppObj.map((obj) => obj.value).reduce((partialSum, a) => partialSum + a, 0);

    const providers = reservedApps.map((a) => a.reservedBy);
    const uniqueProviders = [...new Set(providers)].length;

    const SLICE__COLORS = ['#f29e0d', '#5da7ae', '#1a2634', '#ca6144'];
    const PERCENT__COLORS = ['#f29e0d', '#ffffff'];

    const CustomPieSliceTooltip = ({ active, payload }) => {
        const grainData = payload.map((a) => a.payload);

        if (active) {
            return ( 
                <div className="pie__chart__tooltip">
                    <div className="pie__chart__tooltip__content">
                        <ul>
                            {grainData.map((grain) => (
                                <li key={grain.label}>{grain.label}: {payload[0].value} tonn</li>
                            ))}
                        </ul>
                    </div>
                </div>
            );
        }
        
        return null;
    };

    const CustomPercentTooltip = ({ active }) => {
        if (active) {
            return ( 
                <div className="pie__chart__tooltip">
                    <div className="pie__chart__tooltip__content">
                        <ul>
                            <li>Bestillingsprosent</li>
                        </ul>
                    </div>
                </div>
            );
        }
        
        return null;
    };

    return ( 
        <>
            <div className="week__wrapper">
                <div className="week__summary">
                    <div className="week__summary__icon">
                        <img src={calendar} alt="Calendar icon"/>
                    </div>
                    <div className="week__summary__content">
                        <h4>Timedata</h4>

                        <div className="week__summary__content--bulletpoints">
                            <p className="thick"><span>{allAppointments.length}</span> ledige timer</p>
                            <p className="thick"><span>{reservedApps.length }</span> timer bestilt</p>
                            <p><span>{unpublishedAppointments.length }</span> avpubliserte timer</p>
                            <p><span>{cancellations.length}</span> kansellerte timer</p>
                        </div>
                    </div>
                </div>
                <div className="pie">
                    <PieChart width={190} height={125}>
                        <Pie startAngle={90} endAngle={-270} data={percentageData} cx={125} cy={55} innerRadius={40} outerRadius={60} fill="#ebebeb85" paddingAngle={0} dataKey="value">
                            {percentageData.map((entry, index) => {
                                return <Cell style={{outline: 'none'}} key={`cell-${index}`} fill={PERCENT__COLORS[index % PERCENT__COLORS.length]} />
                            })}
                            <Label value={orderPercentage + " %"} style={{ fontSize: "14", fill: "#3b454e", fontWeight: "500"}} position="center" />
                        </Pie>
                        <Tooltip content={<CustomPercentTooltip />}/>
                        {orderPercentage === 0 && 
                            <Pie data={[{label: 'No Data', value: 1}]} fill="white" cx={125} cy={55} innerRadius={40} outerRadius={60} paddingAngle={0} dataKey="value">
                                <Cell style={{outline: 'none'}} />
                                <Label value={"0 tonn"} style={{ fontSize: "14", fill: "#3b454e", fontWeight: "500"}} position="center" />
                            </Pie>
                        }
                    </PieChart>
                </div>
            </div>
            <div className="week__wrapper">
                <div className="week__summary">
                    <div className="week__summary__icon">
                        <img src={scale} alt="Scale icon"/>
                    </div>
                    <div className="week__summary__content">
                        <h4>Leveringsdata</h4>
                        <div className="week__summary__content--bulletpoints">
                            <p className="thick inline--bullet"><span>{uniqueProviders}</span> unike leverandører</p>
                        </div>
                        <div className="week__summary__content--percentages">
                            <ul> 
                                {calculatedAppObj.map((type) => (
                                    <li key={type.label} ><span className={`inline__legend ${type.label}`}></span><span>{type.value} </span>tonn {type.label}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="pie">
                    <PieChart width={190} height={125}>
                        <Pie data={calculatedAppObj} cx={125} cy={55} innerRadius={40} outerRadius={60} fill="#ebebeb85" paddingAngle={4} dataKey="value">
                            {calculatedAppObj.map((entry, index) => {
                                return <Cell style={{outline: 'none'}} key={`cell-${index}`} fill={SLICE__COLORS[index % SLICE__COLORS.length]} />
                            })}
                            <Label value={totalTonnage + " tonn"} style={{ fontSize: "14", fill: "#3b454e", fontWeight: "500"}} position="center" />
                        </Pie>
                        {totalTonnage !== 0 && <Tooltip content={<CustomPieSliceTooltip />}/>}
                        {totalTonnage === 0 && 
                            <Pie data={[{label: 'No Data', value: 1}]} fill="white" cx={125} cy={55} innerRadius={40} outerRadius={60} paddingAngle={0} dataKey="value">
                                <Cell style={{outline: 'none'}} />
                                <Label value={"0 tonn"} style={{ fontSize: "14", fill: "#3b454e", fontWeight: "500"}} position="center" />
                            </Pie>
                        }
                    </PieChart>
                </div>
            </div>
        </>
    );
}

export default WeekSummary;
