import { useState, useRef, useEffect } from "react";
import { apiUrl } from "../data/API";

const useFetch = (url, content) => {
    const isMountedRef = useRef(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        isMountedRef.current = true;

        async function init() { 
            try {
                const response = await fetch(apiUrl + url, content);
                if (response.ok) {
                    const json = await response.json();

                    if (isMountedRef.current) {
                        setData(json);
                    }
                } else {
                    throw response;
                }
            } catch (e) {
                if (isMountedRef.current) {
                    setError(e);
                }
            } finally {
                if (isMountedRef.current) {
                    setLoading(false);
                }
            }
        }
        init();

        return () => {
          isMountedRef.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    return { data, error, loading };
}

export default useFetch;