import React from "react";

const Input = React.forwardRef((props, ref) => {
    return (
        <div className="input">
            <label htmlFor={props.input.id}>{props.label}</label>
            <input ref={ref} {...props.input} />
        </div>
    );
});

export default Input;

export const onFocusShowPicker = (event) => {
    const input = event.target;
    input.showPicker();
}

export const onBlurHidePicker = (event) => {
    const input = event.target;
    input.blur();  
}

