import { dateFormatOptions } from "./DateFormatOptions";
import { padWithZero } from "../components/access/admin/manage/appointments/CreateAppointmentsHelper";

export const groupAppointments = (array, id) => {
    const getUserReservedAppointments = array.filter((d) => d.reservedBy === id);
    
    const groupDays = getUserReservedAppointments.reduce((groupDays, date) => {
        const formatDate = date.scheduledAt.split("T")[0];
        (groupDays[formatDate] = groupDays[formatDate] || []).push(date);

        return groupDays;
    }, {});

    const groupAppointmentsByDay = Object.keys(groupDays).map((day) => { 
        return { day, appointments: groupDays[day] };
    }); 

    const getTodaysDate = new Date().toISOString("en", dateFormatOptions).split("T")[0];

    return groupAppointmentsByDay.filter(d => new Date(d.day) - new Date(getTodaysDate) >= 0);
}

export const groupAllAppointments = (array) => {
    const groupDays = array.reduce((groupDays, date) => {
        const scheduledAtAsDate = new Date(date.scheduledAt);
        const formatDate = `${scheduledAtAsDate.getFullYear()}-${padWithZero(scheduledAtAsDate.getMonth() + 1)}-${padWithZero(scheduledAtAsDate.getDate())}`;

        if (groupDays[formatDate]) {
            groupDays[formatDate].push(date);
        } else {
            groupDays[formatDate] = [ date ];
        }

        return groupDays;
    }, {});

    const groupAppointmentsByDay = Object.keys(groupDays).map((day) => { 
        return { day, appointments: groupDays[day] };
    }); 

    return groupAppointmentsByDay;
}


export const groupForTodayForward = (array) => {
    const groupDays = array.reduce((groupDays, date) => {
        const formatDate = date.scheduledAt.split("T")[0];
        (groupDays[formatDate] = groupDays[formatDate] || []).push(date);

        return groupDays;
    }, {});

    const groupAppointmentsByDay = Object.keys(groupDays).map((day, index) => { 
        return { id: index, day, appointments: groupDays[day] };
    }); 

    const getTodaysDate = new Date().toISOString("en", dateFormatOptions).split("T")[0];

    return groupAppointmentsByDay.filter((d) => d.day >= getTodaysDate);
}