import React, { useState, useEffect } from "react";
import { getUserToken } from "../../../../../data/token";
import { apiUrl } from "../../../../../data/API";
import Button from "../../../../UI/Button";
import { Feedback } from "../../../../../assets/Feedback";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import useInput, { inputNotEmpty } from "../../../../../hooks/useInput";
import { checkForUniqueName } from "../templates/CreateTimespanTemplates";
import { delayPromise } from "../../../../../assets/DelayPromise";

const CustomerEditCategory = (props) => {
    const [category, setCategory] = useState({});
    const [onSave, setOnSave] = useState("");
    const [formError, setFormError] = useState("");
    const [error, setError] = useState("");
    const [validName, setValidName] = useState(true);
    const [renderState, setRenderState] = useState(false);

    const tokenData = getUserToken()
    const categoryToEdit = props.category;
    const userCategories = props.userCategories;

    const { valueChangeHandler: categoryNameChangeHandler, inputBlurHandler: categoryNameBlurHandler} = useInput(inputNotEmpty);
    const { valueChangeHandler: descriptionChangeHandler, inputBlurHandler: descriptionBlurHandler} = useInput(inputNotEmpty);
    const { valueChangeHandler: maxDailyReservationsOverrideChangeHandler, inputBlurHandler: maxDailyReservationsOverrideBlurHandler} = useInput(inputNotEmpty);

    let invalidValue = false; 

    useEffect(() => {
        setCategory(categoryToEdit);
        setRenderState(true);
    }, [categoryToEdit]);

    const handleChange = (event) => {
        event.preventDefault(); 
        const { id, value } = event.target;
        let updateCategory = {...category, [id] : value};
        setCategory(updateCategory);
    }

    if (category.maxDailyReservationsOverride && category.maxDailyReservationsOverride <= 0) {
        invalidValue = true;
    }

    const submitForm = (event) => {
        event.preventDefault(); 

        if (category.name && category.description && category.maxDailyReservationsOverride && validName && !invalidValue) {     
            updateCategory(event);
            setFormError("");
        } else {
            setFormError("Skjemaet har feiler eller felter som ikke er fylt ut.");
        }
    }

    const updateCategory = (event) => {
        event.preventDefault();
        setOnSave("Oppdaterer kategori ...");
        fetch(apiUrl + `customer-categories/${category.id}?color=${"ffffff"}&description=${category.description}&maxDailyReservationsOverride=${category.maxDailyReservationsOverride}&name=${category.name}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${tokenData}`,
                "Content-Type": "application/json"
            }
        }).then(delayPromise(2000))
        .then((response) => {
            if (response.ok) {
                setOnSave("");
                setError("");
                props.updateChangesToState(category); 
                props.onHide();
                return response.text();
            } else {
                setOnSave("");
                setError("Det oppsto en feil ved oppdatering av kategorien, last inn siden på nytt og prøv igjen.");
            }                
        }).catch((error) => {
            setOnSave("");
            setError("Det oppsto en feil ved oppdatering av kategorien, last inn siden på nytt og prøv igjen.");
        });
    };

    return (<>
        <div className="modal__form">
            <div className="modal__form__header">
                <h1>Rediger kategori</h1>
            </div>

            <div className="modal__form__content">
                {renderState && category && <>
                    <h2>Kategori: {categoryToEdit.name}</h2>

                    <div className="modal__form__item modal__form__item--topped">
                        <label htmlFor="name">Navn</label>
                        <div>
                            <input type="text" id="name" name="name" value={category.name} maxLength="26" className={`form__item__step--input ${!category.name && "invalid"}`} autoComplete="off"
                                onBlur={(e) => {categoryNameBlurHandler(); checkForUniqueName(e, userCategories, category.name, setValidName)}} onChange={(e) => {handleChange(e); categoryNameChangeHandler(e)}}/>
                            {category.name && category.name.length === 26 && <span className="form__item__step--error">Velg et beskrivende navn (maks 26 karakterer).</span>}
                            {category.name && (category.name !== categoryToEdit.name) && !validName && <span className="form__item__step--error">Navnet du har valgt eksisterer allerede.</span>}
                            {!category.name && <span className="form__item__step--error">Navn mangler.</span>} 
                        </div> 
                    </div>

                    <div className="modal__form__item">
                        <label htmlFor="description">Beskrivelse</label>
                        <div>
                            <input type="text" id="description" name="description" maxLength="56" value={category.description} onChange={(e) => {handleChange(e); descriptionChangeHandler(e)}}
                                className={`form__item__step--input ${!category.description && "invalid"}`} onBlur={descriptionBlurHandler} autoComplete="off"/>
                            {category.description && category.description.length === 56 && <span className="form__item__step--error">Skriv en kort beskrivelse (max 56 karakterer).</span>}
                            {!category.description && <span className="form__item__step--error">Beskrivelse mangler.</span>}
                        </div> 
                    </div>

                    <div className="modal__form__item">
                        <label htmlFor="maxDailyReservationsOverride">Bestillinger per dag</label>
                        <div>
                            <input type="number" id="maxDailyReservationsOverride" name="maxDailyReservationsOverride" value={category.maxDailyReservationsOverride} onChange={(e) => {handleChange(e); maxDailyReservationsOverrideChangeHandler(e)}}
                                className={`form__item__step--input ${!category.maxDailyReservationsOverride && "invalid"}`} onBlur={maxDailyReservationsOverrideBlurHandler} autoComplete="off"/>
                            {invalidValue && <span className="form__item__step--error">Uglydig verdi oppgitt.</span>} 
                            {category.maxDailyReservationsOverride.length <= 0 && <span className="form__item__step--error">Bestillinger per dag mangler.</span>} 
                        </div> 
                    </div>

                    <div className="modal__form__feedback">
                        <Button onClick={(e) => submitForm(e)} className="confirm button__mr">Lagre endringer</Button>
                        <Button className="plain" type="button" title="Lukk visning" onClick={props.onHide}>Avbryt</Button>

                        {onSave && <Feedback class="feedback--center topped" feedbackClass="feedback--loading" message={onSave}/>}
                        {error && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={error}/>} 
                        {formError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={formError}/>} 
                    </div>
                </>}

                {(renderState && !category) && <div className="topped"><LeafLoader loadingMessage="Laster inn kategori ..."/></div>}
                {!category && <div className="topped"><Feedback class="feedback--center" feedbackClass="feedback--error" message={"Det oppsto et problem ved endring av kategori, vennligst last inn siden på nytt."}/></div>}
            </div>
        </div>
    </>);
};

export default CustomerEditCategory;
