import { Fragment, useState, useEffect, lazy, Suspense } from "react";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import useConfirm from "../../../../../hooks/useConfirm";
import { getConfiguration, getGrainData } from "../../../../booking/BookingService";
import { findGrainTypeName, findVarietyName, isSprayed} from "../../../user/UserService";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import { hideHandler } from "../../../../layout/MainLayout";
import { ActionModal } from "../../../../UI/Modal";
import { ErrorFeedback } from "../../../../../assets/Feedback";
import { formatTime, formatDate } from "../../../../../assets/DateFormatOptions";
import { capitalizeName } from "../../../../../assets/Capitalize";
import { cancelAppointmentHandler } from "./dashboard/AppointmentBoardHelpers";
import { getUserName } from "../../../../../assets/GetUserCredentials";
import searchIcon from "../../../../../assets/icons/search_black_24dp.svg";
import Button from "../../../../UI/Button";
import PrintButton from "../../../../UI/PrintButton";
import readComment from "../../../../../assets/icons/comment_black_24dp.svg";
import editIcon from "../../../../../assets/icons/edit_note_FILL1_wght400_GRAD0_opsz40.svg";
import deleteIcon from "../../../../../assets/icons/delete_forever_FILL1_wght400_GRAD0_opsz40.svg";

const ExportUserAppointments = lazy(() => import("../../../../../assets/ExportUserAppointments"));
const EditAppointment = lazy(() => import("./dashboard/AppointmentBoardEditAppointment"));
const ShowComment = lazy(() => import("./dashboard/AppointmentBoardComment"));

const ReservedAppointments = () => {
    const [reservedAppointments, setReservedAppointments] = useState();
    const [fetchError, setFetchError] = useState("");
    const [actionError, setActionError] = useState([]);
    const [configuration, setConfiguration] = useState({});
    const [grainVarieties, setGrainVarieties] = useState([]);
    const [showUpdateAppointment, setShowUpdateAppointment] = useState(null);
    const [modalId, setModalId] = useState(null); 
    const [modalUser, setModalUser] = useState(""); 
    const [search, setSearch] = useState("");
    const [expiredAppointments, setExpiredAppointments] = useState([]);
    const [showExportModal, setShowExportModal] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [filter, setFilter] = useState("user");
    const [showComment, setShowComment] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const tokenData = getUserToken();
    const {confirm} = useConfirm();

    const { data: reserved, reservedLoading, reservedError} = useFetch("appointments/reserved", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`, }
    });

    const { data: customers, customerLoading, customerError} = useFetch("customers", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}
    });

    const { data: archived, archivedLoading, archivedError} = useFetch("appointments/archived", {
        method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`, }
    });

    useEffect(() => {
        setReservedAppointments(reserved);

        if (isChecked) {
            setReservedAppointments(reserved.concat(archived))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[reserved, isChecked, archived]);

    useEffect(() => {
        getConfiguration(tokenData).then(response => 
            setConfiguration({threshold: response.cancellationThreshold, unpublishOnCancel: response.cancellationCausesUnpublish})
        ).catch((error) => { 
            setFetchError("Det oppsto et problem ved innlasting av grenseverdi til kansellering. Forsøk å laste inn siden på nytt.");}
        );

        getGrainData(tokenData).then(data => setGrainVarieties(data)).catch((error) => { 
            setFetchError("Det har oppstått en feil ved henting av korntyper. Forsøk å laste inn siden på nytt.")});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tokenData]);

    let groupedReserved = [];
    let loadingState = false;
    let renderState = false;
    let errorState = false;

    if ((reserved && reservedAppointments) && archived && (customers && customers.length > 0)) {
        if (filter === "date")  {
            const sortReservedDateByDesc = reservedAppointments.slice(0).sort((a,b)=> b.reservedAt.localeCompare(a.reservedAt))

            const groupByDate = sortReservedDateByDesc.reduce((groupDate, data) => {
                const formatDate = data.reservedAt.split("T")[0];

                (groupDate[formatDate] = groupDate[formatDate] || []).push(data);
        
                return groupDate;
            }, {});
        
            const groupAppointmentsByDay = Object.keys(groupByDate).map((day) => { 
                return { day, appointments: groupByDate[day] };
            }); 

            groupedReserved = groupAppointmentsByDay;

        } else {
            const sortReservedAtByDesc = reservedAppointments.slice(0).sort((a,b)=> b.reservedAt.localeCompare(a.reservedAt));

            const groupByUserName = sortReservedAtByDesc.reduce((groupByUserName, data) => {
                const user = getUserName(customers, data.reservedBy.toString());

                (groupByUserName[user] = groupByUserName[user] || []).push(data);
        
                return groupByUserName;
            }, {});

            const groupAppointmentsByDay = Object.keys(groupByUserName).map((user) => { 
                return { user, appointments: groupByUserName[user] };
            }); 

            groupedReserved = search ? groupAppointmentsByDay.filter((u) => u.user.toLowerCase().includes(search.toLowerCase())) : groupAppointmentsByDay;
        }
    } 

    const stateHandler = (app) => {
        const updatedState = reservedAppointments.map((appointment) => {
            if (appointment.id === app.id) {
                let updateApp = {
                    ...appointment,
                    amount: app.amount,
                    grainTypeId: app.grainTypeId,
                    varietyTypeId: app.varietyTypeId,
                    reservedBy: app.reservedBy,
                    comment: app.comment,
                    warehouseRental: app.warehouseRental
                };
    
                if (app.sprayed !== undefined) {
                    updateApp = {
                        ...updateApp,
                        sprayed: app.sprayed ? true : false
                    };
                }
    
                return updateApp; 
            }
            return appointment; 
        });
    
        setReservedAppointments(updatedState); 
    };

    const sortedReservationsTable = (appointment) => {
        return (
            <Fragment key={appointment.id}>
                <tr className={`table__row ${showUpdateAppointment === appointment.id ? "table__row--open" : ""}`}>
                    {filter === "user" ? 
                        <td><span style={{fontWeight: "600"}}>{formatDate(appointment.reservedAt)}</span> {formatTime(appointment.reservedAt, {hour: '2-digit', minute: '2-digit'})}</td>
                        : 
                        <td title="Brukernavn til kunde"> {getUserName(customers, appointment.reservedBy.toString())}</td>
                    }
                        <td><span style={{fontWeight: "600"}}>{formatDate(appointment.scheduledAt)}</span> {formatTime(appointment.scheduledAt, {hour: '2-digit', minute: '2-digit'})}</td>
                        {(!expiredAppointments?.find((a) => a === appointment.id)) && (!actionError?.find((a) => a.id === appointment.id)) && <>
                            <td>{findGrainTypeName(grainVarieties, appointment.grainTypeId)} {isSprayed(grainVarieties, appointment, "serverside")}</td>
                            <td>{appointment.varietyTypeId ? findVarietyName(grainVarieties, appointment.grainTypeId, appointment.varietyTypeId) : "-"}</td>
                            <td>{appointment.amount}</td>
                            <td>{appointment.warehouseRental ? "Ja" : ""}</td>
                            <td className="td__center">
                                {(appointment.comment && new Date(appointment.scheduledAt).getTime() > new Date().getTime()) &&
                                    <Button className="action mass__actions--btn" title="Les kommentar" onClick={() => {setShowComment(true); setModalId(appointment.id)}}>
                                        <img src={readComment} alt="Comment icon"/>
                                    </Button>
                                }
                                {(showComment && modalId === appointment.id) &&
                                    <ActionModal onHide={() => hideHandler(setShowComment)}>
                                        <Suspense>
                                            <ShowComment onHide={() => hideHandler(setShowComment)} app={appointment} customers={customers}/>
                                        </Suspense>
                                    </ActionModal>
                                }
                            </td>
                            <td className="td__center">
                                {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? 
                                    <span title="Timen er utgått og kan ikke endres på lengere">Utgått</span> 
                                    : 
                                    <Button onClick={() => {setShowUpdateAppointment(showUpdateAppointment === appointment.id ? null : appointment.id); setModalId(appointment.id) }} 
                                        className="action mass__actions--btn" disabled={showUpdateAppointment} title="Endre verdier for time">
                                        <img src={editIcon} alt="Edit icon"/>
                                    </Button>
                                } 
                            </td>
                            <td className="td__center">
                                {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? 
                                    <span title="Timen er utgått og kan ikke kanselleres lengere">Utgått</span> 
                                    : 
                                    <Button title="Kanseller time for bruker" className="action mass__actions--btn" disabled={showUpdateAppointment}
                                        onClick={(e) => {new Date(appointment.scheduledAt).getTime() < new Date().getTime() ? setExpiredAppointments((items) => { return [...items, appointment.id]}) 
                                            : cancelAppointmentHandler(e, confirm, configuration, appointment, reservedAppointments, setActionError, setReservedAppointments, tokenData, appointment.reservedBy)}}>
                                        <img src={deleteIcon} alt="Delete icon"/>
                                    </Button>
                                }
                            </td>
                        </>}
                    {expiredAppointments?.find((a) => a === appointment.id) && <>                                                        
                        <td colSpan={5}>
                            <span className="feedback sm">Denne timen er utløpt.</span>
                        </td>
                        <td colSpan={2}></td>
                    </>}
                    {actionError?.find((a) => a.id === appointment.id) && <>                                                        
                        <td colSpan={5}>
                            <span className="feedback sm">{actionError?.find((a) => a.id === appointment.id).message}</span>
                        </td>
                        <td colSpan={2}></td>
                    </>}
                </tr>
                {(showUpdateAppointment && modalId === appointment.id) &&
                    <ActionModal onHide={() => hideHandler(setShowUpdateAppointment)}>
                        <Suspense>
                            <EditAppointment onHide={() => hideHandler(setShowUpdateAppointment)} app={appointment} customers={customers} updateChangesToState={stateHandler} />
                        </Suspense>
                    </ActionModal>
                }
            </Fragment>
        )
    };

    if ((reservedLoading && customerLoading && archivedLoading) || customerLoading || reservedLoading || archivedLoading) loadingState = true;
    
    if ((reservedError && customerError && fetchError && archivedError) || reservedError || customerError || fetchError || archivedError) errorState = true;

    if (reservedAppointments && archived && (customers && customers.length > 0) && (grainVarieties && grainVarieties.length > 0) && configuration) renderState = true;

    return (
        <section className="contents__container">
            <h1 className="no-mt">Bestilte timer</h1>

            {(loadingState || !renderState) && <LeafLoader loadingMessage="Laster inn kunder og timer ..."/>}
            {(errorState && !renderState) && <ErrorFeedback class="error__container--important error__container--inline" feedbackClass="" message={"Kunne ikke hente data for reservasjoner, vennligst last inn siden på nytt."}/>} 
            {renderState && <>
                <p>Denne oversikten viser alle bestillinger og kan sorteres på kunder eller etter dato - sistnevnte viser når kommende timer ble reservert. 
                    {archived?.length >= 1 && " For å se en bestillingshistorikk på kunder så kan du velge å inkludere utgåtte timer, da vil du se alle timer som er bestilt på den kunden. "}
                </p>

                {(filter && reserved && reservedAppointments && grainVarieties.length > 0 && customers) && 
                    <div className="list topped">
                        <section className="list__form">
                            <section className="list__form__header">
                                <h2>{filter === "date" ? "Gruppert på dato" : "Gruppert på kunder"}</h2>
                                <div className="list__form__header--search">
                                    <section>
                                        {archived?.length >= 1 && <>
                                            <input type="checkbox" name="checkForArchive" className="checkbox inline-checkbox" id="checkForArchive" checked={isChecked} onChange={() => setIsChecked((prev) => !prev)}/> 
                                            <Button type="button" className="text__btn reset--search list__button" onClick={() => setIsChecked((prev) => !prev)}> Inkluder utgåtte timer</Button> 
                                        </>}
                                        <select id="categories" disabled={reservedAppointments.length <= 0} name="categories" value={filter} onChange={(event) => {setFilter(event.target.value)}}>
                                            <option value="user">Grupper på kunder</option>
                                            <option value="date">Grupper på dato</option>
                                        </select>
                                        {filter === "user" && <>
                                            <div className="searchbar">
                                                <input placeholder="Søk etter kunder ..." disabled={reservedAppointments.length <= 0} autoComplete="off" type="text" id="searchCustomer" name="searchCustomer" value={search ? search : ""} onChange={(event) => setSearch(event.target.value)}/>
                                                <img src={searchIcon} alt="Search icon"/>
                                            </div>
                                        </>}
                                        {reservedAppointments.length > 0 && <Button type="button" className="text__btn emptyFilter" title="Tøm søk" onClick={() => {setSearch(""); setIsChecked(false); setFilter("user")}}>&#10006;</Button>}
                                    </section>
                                </div>
                            </section>
                            {groupedReserved.length >= 1 && 
                                <section className="list__content">
                            
                                    {filter === "date" ?
                                        <p>Viser nå bestilte timer basert på den datoen de ble bestilt av en kunde - førstkommende bestillinger øverst.</p>
                                        :
                                        <p>Viser alle kunder som har bestilte timer. Bruk feltet til høyre for å søke etter kunder.</p>
                                    }

                                    {groupedReserved.length >= 1 && groupedReserved.map((reserved, index) => (
                                        <div key={index} className="booking__section--table">
                                            <h3 className="export__heading ">
                                                {filter === "date" ? formatDate(reserved.day) : capitalizeName(reserved.user)} <span>{reserved.appointments.length} timer {filter === "date" && "bestilt"}</span> 
                                            </h3>
                                            {filter !== "date" &&
                                                <PrintButton title="Skriv ut timeliste" onClick={() => {setSelectedUser(reserved.user); setExportData(reserved.appointments); setModalUser(reserved.user); setShowExportModal(true)}} className="export__button--padded"/>
                                            }
                                            <table style={{width: "100%"}} className="table table--lg--plus">
                                                <thead>
                                                    <tr className="table__head">
                                                        {filter === "user" ? <th style={{width:"12%"}}>Reservert</th> : <th className="table__head--main" style={{width:"20%"}} title="Brukernavn til kunde">Kunde</th>}
                                                        <th style={{width:"12%"}}>Timeavtale</th>
                                                        <th style={{width:"10%"}}>Kornart</th>
                                                        <th style={{width:"10%"}}>Kornsort</th>
                                                        <th style={{width:"7%"}}>Tonn</th>
                                                        <th style={{width:"7%"}}>Lagerleie</th>
                                                        <th className="table__head--action td__center" style={{width:"8%"}}>Kommentar</th>
                                                        <th className="table__head--action td__center" style={{width:"4%"}}>Endre</th>
                                                        <th className="table__head--action td__center" style={{width:"8%"}}>Kanseller</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {reserved.appointments.slice(0).sort((a, b) => new Date(a.scheduledAt).getTime() > new Date().getTime() && a.scheduledAt.localeCompare(b.scheduledAt)).map((appointment) => { return ( 
                                                    sortedReservationsTable(appointment)
                                                );})}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}

                                    {(showExportModal && modalUser === selectedUser) &&
                                        <ActionModal onHide={() => hideHandler(setShowExportModal)}>  
                                            <Suspense>
                                                <ExportUserAppointments onHide={() => hideHandler(setShowExportModal)} data={exportData} user={selectedUser} grains={grainVarieties}/>
                                            </Suspense>                  
                                        </ActionModal>
                                    }
                                </section>
                            }
                            
                            {groupedReserved.length <= 0 && (filter && !search) && <p className="empty-result empty-result--lg empty-result--positive">Det finnes ingen bestilte timer.</p>}
                            {groupedReserved.length <= 0 && search && <p className="empty-result empty-result--lg empty-result--positive">Fant ingen kunder med det navnet.</p>} 
                        </section>
                    </div>
                }
            </>}
        </section>
    );
};

export default ReservedAppointments;