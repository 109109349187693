
import { useState, useEffect } from "react";
import { apiUrl } from "../../../../../data/API";
import { getUserToken } from "../../../../../data/token";
import useFetch from "../../../../../hooks/useFetch";
import LeafLoader from "../../../../../assets/LoadingSpinner";
import useInput, { inputNotEmpty } from "../../../../../hooks/useInput";
import { Feedback, TimeoutFeedback } from "../../../../../assets/Feedback";
import useConfirm from "../../../../../hooks/useConfirm";
import { padWithZero } from "../appointments/CreateAppointmentsHelper";
import { addWhiteSpace } from "./CreateTimespanTemplates";
import Pagination from "../../../../UI/Pagination";
import Button from "../../../../UI/Button";
import { formatTimeString } from "./TimespanTemplatesPage";

export const sortTimespans = (timespans) => {
    return timespans.sort((a, b) => {
        const startTimeA = a.startHour * 60 + a.startMinute;
        const startTimeB = b.startHour * 60 + b.startMinute;
        const endTimeA = a.endHour * 60 + a.endMinute;
        const endTimeB = b.endHour * 60 + b.endMinute;

        if (startTimeA !== startTimeB) {
            return startTimeA - startTimeB;
        } else {
            return endTimeA - endTimeB;
        }
    });
};

export const itemsPerPage = 6;

const DayTemplatesPage = (props) => {
    const [templateData, setTemplateData] = useState([]);
    const [actionError, setActionError] = useState("");
    const [isSuccess, setIsSuccess] = useState("");
    const [timespanIds, setTimespanIds] = useState([]);
    const [timespan, setTimespan] = useState([]);
    const [deleteError, setDeleteError] = useState("");
    const [formError, setFormError] = useState("");
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    
    const {confirm} = useConfirm();

    const tokenData = getUserToken();
    const allTimespans = props.timespans;
    
    const { data: days, loading, error } = useFetch("days", { method: 'GET', headers: {'Authorization': `Bearer ${tokenData}`}});
    const { value: templateNameValue, isValid: templateNameIsValid, hasError: templateNameHasError, valueChangeHandler: templateNameChangeHandler, inputBlurHandler: templateNameBlurHandler, reset: resetTemplateName } = useInput(inputNotEmpty);

    let setIsValid = false;
    let paginationData;
    let numberOfPages;

    useEffect(() => {
        setTemplateData(days);
    },[days])

    const addTimespansToDay = async (event, id) => {
        event.preventDefault();

        setTimespanIds((ids) => {
            const idExists = ids.find((a) => a === id);
    
            if (idExists) {
                return ids.filter((a) => a !== id);
            } else {
                return [...ids, id];
            }
        });
    };

    const addTimespan = async (event, timespan) => {
        event.preventDefault();

        setTimespan((timespans) => {
            const idExists = timespans.find((a) => a.id === timespan.id);

            if (idExists) {
                return timespans.filter((a) => a.id !== timespan.id);
            } else {
                return [...timespans, timespan];
            }
        });
    };

    const saveDayTemplate = (event) => {
        event.preventDefault();

        const dayspans = timespan.map((t) => ({id: t.id, name: t.name}));
        let newDayspan = {name : templateNameValue, timespans : dayspans};

        if (setIsValid) {
            setFormError("");
            setActionError("");
            fetch(apiUrl + `days?name=${templateNameValue}&timespanIds=${timespanIds}`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${tokenData}`,
                    "Content-Type": "application/json",
                },
            }).then((response)=> {
                if (response.ok) {
                    setFormError("");
                    setActionError("");
                    setIsSuccess("Dagsmal ble opprettet");
                    setTimespanIds([]);
                    setTimespan([]);
                    resetTemplateName();
                    return response.json().then((data) => {
                        setTemplateData((templateData) => {
                            const pickedDay = templateData.find((template) => template.name === newDayspan.name);
                            
                            if (pickedDay) { return } 
                            
                            else { return [...templateData, newDayspan = { ...newDayspan, id: data}]}
                        });
                    })
                } else {
                    setFormError("");
                    setIsSuccess("");
                    return response.json().then((data) => {
                        setActionError("Det har oppstått en feil ved oppretting av dagsmal, se over skjemaet og prøv igjen.");
                    });
                }
            }).catch((error) => {
                setIsSuccess("");
                setActionError("Det har oppstått en feil ved oppretting av dagsmal, se over skjemaet og prøv igjen. ");
            });
        } else {
            setFormError("Skjemaet har feiler eller felter som ikke er fylt ut.");
        }
    };

    const deleteDayspan = async (event, id) => {
        event.preventDefault();
        const isConfirmed = await confirm("Er du sikker på at du vil slette denne dagsmalen?");

        setFormError("");
        setActionError("");
        setIsSuccess("");
        if (isConfirmed) {
            return fetch(apiUrl + "days/" + id, {
                method: "DELETE",
                headers: {"Authorization": `Bearer ${tokenData}`, "Content-Type": "application/json",}
            }).then((response)=> {
                if (response.ok) {
                    setDeleteError("");
                    const updateTemplateData = templateData.filter((temp) => temp.id !== id);
                    setTemplateData(updateTemplateData);
                } else {
                    return response.json().then(() => {
                        setDeleteError("Det har oppstått en feil ved sletting av denne dagsmalen. Last inn siden på nytt eller prøv igjen senere.");
                    });
                }
            }).catch(() => {
                setDeleteError("Det har oppstått en feil ved sletting av denne dagsmalen. Last inn siden på nytt eller prøv igjen senere.");
            });
        } 
    };

    const clearSuccessState = () => {
        setIsSuccess("");
        setFormError("");
    }

    if (!loading && days && days.length > 0 && templateData && templateData.length > 0) {
        const indexOfLastRecord = currentPaginationPage * itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
        const sortTemplates = templateData.sort((a,b) => b.id - a.id);
        paginationData = sortTemplates.slice(indexOfFirstRecord, indexOfLastRecord);
        numberOfPages = Math.ceil(templateData.length / itemsPerPage)
    }

    if (timespanIds.length > 0 && templateNameIsValid) setIsValid = true;

    return (
        <section className="equal templates">
            <section className="equal__part">
                <h2>Opprett ny dagsmal</h2>
                <div className="templates__form"> 
                    <p>Velg et beskrivende navn for malen, og minst én timemal for å opprette en dagsmal. </p>

                    {templateData &&
                        <form onSubmit={saveDayTemplate} noValidate>
                            <div>
                                <label htmlFor="templateName">Malnavn <span className="required" title="Påkrevd felt">*</span></label>                           
                                <input type="text" id="name" value={templateNameValue} maxLength="16" onChange={(e) => templateNameChangeHandler(e)} 
                                    className={`${(templateNameHasError ? "input--error" : null)}`} onBlur={templateNameBlurHandler} autoComplete="off"
                                />
                            </div>

                            <div className="templates__form__container">
                                <label htmlFor="templateList">Velg timemaler <span className="required" title="Påkrevd felt">*</span></label> 

                                {templateData ? 
                                    <div className="templates__form__container--inline">
                                        {timespanIds.length > 0 ? <p>{timespanIds.length} {timespanIds.length > 1 ? "timemaler" : "timemal"} valgt</p> : <p>Ingen valgt enda</p>}
                                    </div>
                                    : 
                                    <div className="templates__form__container--inline">
                                        <p>Fant ingen lagrede timemaler.</p>
                                    </div>
                                }

                                <div name="templateList" className="templates__form__container__list">
                                    {allTimespans && sortTimespans(allTimespans).map((timespan) => (
                                        <div title={timespanIds.find((i) => i === timespan.id) ? "Fjern timemal" : "Velg timemal"} onClick={(e) => {addTimespansToDay(e, timespan.id); addTimespan(e, timespan)}}
                                            className={`templates__form__container__list--item ${(timespanIds.find((i) => i === timespan.id) && "selected")}`} key={timespan.id}
                                        >
                                            <p>{addWhiteSpace(timespan.name)}</p>
                                            <p>
                                                {timespan.startHour ? padWithZero(timespan.startHour) + ":" + padWithZero(timespan.startMinute) : formatTimeString(timespan.start)}
                                                -
                                                {timespan.endHour ?  padWithZero(timespan.endHour) + ":" +  padWithZero(timespan.endMinute) : formatTimeString(timespan.end)}
                                            </p>
                                            <p> ({timespan.minutesBetween} min.) </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Button type="submit" className="confirm topped" disabled={!templateData} title={!templateData ? "Opprett timemaler først" : "Lagre dagsmal"}>Lagre dagsmal</Button>
                        </form>
                    }
                    {isSuccess && <TimeoutFeedback class="topped" feedbackClass="feedback--success" clearMessage={clearSuccessState} message={isSuccess}/>}
                    {formError && <TimeoutFeedback class="topped" feedbackClass="feedback--error" clearMessage={clearSuccessState} message={formError}/>}
                    {actionError && <Feedback class="feedback--center topped" feedbackClass="feedback--error" message={actionError}/>}
                </div>
            </section>
            <section className="equal__part">
                <h2>Eksisterende dagsmaler ({templateData?.length})</h2>

                {!loading && templateData ?
                    <>
                        <div className="templates__table">
                            {(!error && templateData?.length > 0) &&
                                <table style={{width: "100%"}} className="templates__list table">
                                    <thead>
                                        <tr className="table__head">
                                            <th style={{width:"30%"}}>Malnavn</th>
                                            <th style={{width:"60%"}}>Timemaler</th>
                                            <th style={{width:"10%"}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paginationData.length > 0 && paginationData.map((day) => (
                                            <tr className="table__row" key={day.name}>
                                                <td>{day.name}</td>
                                                <td>{day.timespans.map((timespan) => (
                                                        <span key={timespan.id}>
                                                            {addWhiteSpace(timespan.name)}
                                                        </span> 
                                                    ))}
                                                </td>
                                                <td>
                                                    <button className="text__btn" onClick={(e) => deleteDayspan(e, day.id)} title="Slett denne dagsmalen">Slett</button>
                                                </td>
                                            </tr>
                                        ))} 
                                    </tbody>
                                </table>
                            }
                            {(!error && templateData && templateData.length === 0) && <p className="templates__list--feedback">Ingen dagsmaler er opprettet enda.</p>}
                            {itemsPerPage >= templateData.length ? "" :
                                <Pagination numberOfPages={numberOfPages} currentPaginationPage={currentPaginationPage} setCurrentPaginationPage={setCurrentPaginationPage}/>
                            }
                        </div> 
                        {deleteError && <Feedback class="feedback--center" feedbackClass="feedback--error" message={deleteError}/>}
                        {error && <Feedback class="feedback--center templates__list--feedback" feedbackClass="feedback--error" message={"Det oppsto en feil ved henting av maler. Forsøk å last inn siden på nytt."}/>} 
                    </> 
                    : 
                    <div className="loading__container topped">
                        <LeafLoader loadingMessage="Laster inn maler ..."/>
                    </div>
                }
            </section>
        </section>
    );
};

export default DayTemplatesPage;