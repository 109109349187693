const LeafLoader = (props) => {
    return (
        <div className="loader">
            <div className="loader__wrapper">
                <div className="leaf leaf--left"></div>
                <div className="leaf leaf--right"></div>
                <div className="leaf leaf--left"></div>
                <div className="leaf leaf--right"></div>
            </div>
            <span>{props.loadingMessage}</span>
        </div>
    );
}

export default LeafLoader;

export const CenterLeafLoader = (props) => {
    return (
        <div className="loader">
            <div className="loader__container">
                <div className="loader__wrapper">
                    <div className="leaf leaf--left"></div>
                    <div className="leaf leaf--right"></div>
                    <div className="leaf leaf--left"></div>
                    <div className="leaf leaf--right"></div>
                </div>
                <span>{props.loadingMessage}</span>
            </div>
        </div>
    );
}